import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Form, Select } from 'semantic-ui-react';

import BtnSaveAntenne from '../../../../../Btn/SaveAntenne';
import BtnCancelSaveAntenne from '../../../../../Btn/CancelSaveAntenne';
import BtnDisabled from '../../../../../Btn/Disabled';

import InfoEdit from './Info/Edit';
import Label from './Info/Label';
import Detail from './Info/Detail';
import Actions from './Info/Actions';
import Value from './Info/Value';

const Info = styled.div`
  display: flex;
  margin: 0 0 10px;
  width: 100%;
  padding: 0 0 0 15px;
`;

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

class Antenne extends Component {
  constructor(props) {
    super(props);

    const { iFKAntenne, tAntenne } = this.props;

    this.tAntenneOptions = [];
    tAntenne.forEach(antenne => {
      this.tAntenneOptions.push({
        key: antenne.iFKAnnuaire,
        value: antenne.iFKAnnuaire,
        text: antenne.sNom
      });
    });

    this.state = {
      iFKAntenne,
      iFKAntenneInitial: iFKAntenne,
      edited: false
    };
  }

  componentDidUpdate(prevProps) {
    const { iFKAntenne } = this.props;

    if (prevProps.iFKAntenne !== iFKAntenne) {
      this.setState({ iFKAntenne, iFKAntenneInitial: iFKAntenne });
    }
  }

  onAntenneEditBtnClick = evt => {
    evt.preventDefault();
    this.setState({ edited: true });
  };

  onAntenneFieldChange = (evt, data) => {
    evt.preventDefault();

    const { value } = data;

    const { iFKAntenneInitial } = this.state;

    this.setState({ iFKAntenne: value, edited: iFKAntenneInitial !== value });
  };

  onBtnCancelSaveAntenneClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ iFKAntenne: prevState.iFKAntenneInitial, edited: false }));
  };

  onBtnSaveAntenneClick = () => {
    this.setState(prevState => ({ edited: false, iFKAntenne: prevState.iFKAntenneInitial }));
  };

  render() {
    const { edited, iFKAntenne, iFKAntenneInitial } = this.state;
    const { iPKDemande, label } = this.props;

    return edited ? (
      <InfoEdit>
        <Form onSubmit={this.onAntenneFieldChange}>
          <Form.Field
            id="iFKAntenne"
            label={
              <FormLabel htmlFor="iFKAntenne">
                Nouvelle antenne :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            control={Select}
            options={this.tAntenneOptions}
            onChange={this.onAntenneFieldChange}
            value={iFKAntenne}
            placeholder="Toutes lse antennes"
          />
        </Form>

        <Actions>
          <BtnCancelSaveAntenne onClick={this.onBtnCancelSaveAntenneClick} />
          {iFKAntenne !== iFKAntenneInitial ? (
            <BtnSaveAntenne
              onClick={this.onBtnSaveAntenneClick}
              iPKDemande={iPKDemande}
              iFKAntenne={iFKAntenne}
            />
          ) : (
            <BtnDisabled label="Modifier l'Antenne" />
          )}
        </Actions>
      </InfoEdit>
    ) : (
      <Info>
        <Label wide>{label}&nbsp;:</Label>
        <Detail wide onClick={this.onAntenneEditBtnClick}>
          <Value value={this.tAntenneOptions.filter(opt => opt.value === iFKAntenne)[0].text} />
        </Detail>
      </Info>
    );
  }
}

Antenne.propTypes = {
  iPKDemande: PropTypes.number.isRequired,
  iFKAntenne: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  tAntenne: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  tAntenne: state.config.tAntenne
});

export default connect(mapStateToProps)(Antenne);
