import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history/';

import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import offlineModeMiddleware from './middleware/offlineMode';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

import App from './App';
import * as serviceWorker from './serviceWorker';

const sagaMiddleware = createSagaMiddleware();
const middleware = process.env.REACT_APP_DEVTOOLS
  ? composeWithDevTools(applyMiddleware(sagaMiddleware, offlineModeMiddleware))
  : applyMiddleware(sagaMiddleware, offlineModeMiddleware);

const store = createStore(rootReducer, middleware);
sagaMiddleware.run(rootSaga);

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
