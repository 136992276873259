import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Loader, Icon } from 'semantic-ui-react';

import { loadRequests, setViewed } from '../../../../../actions/request';
import { listViews } from '../../../../../actions/view';

import Menu from './Menu';
import RequestDetailInfo from './Infos';
import ModalAddRappel from '../../Rappel/Ajout';

const Navigation = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  min-height: 100%;
`;

const Main = styled.div`
  margin-left: 40px;
  padding: 0 20px 20px;
  min-height: ${props => props.height}px;
  background: #fff;
`;

const RequestDetailView = styled.div`
  min-height: 100%;
  background: ${props => props.theme.colors.backgroundLight};
`;

const NoRequestInfo = styled.div`
  padding: 20px 0;
`;

const LockedRequestInfo = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const LockedRequestInfoIcon = styled(Icon)`
  display: block !important;
  margin: 20px auto !important;
  font-size: 50px !important;
  line-height: 40px !important;
`;

class RequestDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addRappel: false
    };

    this.menuItemsRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef()
    ];
  }

  onBtnToggleAddRappelClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ addRappel: !prevState.addRappel }));
  };

  onBtnSaveRappelClick = evt => {
    evt.preventDefault();

    this.setState({ addRappel: false });
  };

  onBtnToggleViewedClick = (evt, request) => {
    evt.preventDefault();

    const { dispatch, iFKDemande, iPKUtilisateur, oFilters, oOrder } = this.props;

    dispatch(setViewed(iFKDemande, !request.bVue, oFilters, oOrder));
    dispatch(listViews(iPKUtilisateur));
    dispatch(loadRequests(oFilters, oOrder));
  };

  getContent = (request, locked) => {
    if (locked.iPKVerrouillage === -1) {
      return (
        <LockedRequestInfo>
          <LockedRequestInfoIcon name="lock" />
          {locked.sUtilisateur} travaille sur cette demande
        </LockedRequestInfo>
      );
    }

    if (request) {
      return (
        <RequestDetailInfo
          refs={this.menuItemsRef}
          request={request}
          onBtnToggleAddRappelClick={this.onBtnToggleAddRappelClick}
          onBtnToggleViewedClick={evt => this.onBtnToggleViewedClick(evt, request)}
        />
      );
    }

    return <NoRequestInfo>Sélectionnez une demande</NoRequestInfo>;
  };

  render() {
    const { height, requests, iFKDemande, locked, loading } = this.props;
    const { addRappel } = this.state;
    const request = requests.filter(r => r.iPKDemande === iFKDemande)[0];

    return loading.lock ? (
      <Loader active>Chargement</Loader>
    ) : (
      <RequestDetailView>
        {locked.iPKVerrouillage > 0 && (
          <Navigation>
            {typeof request !== 'undefined' && <Menu refs={this.menuItemsRef} />}
          </Navigation>
        )}

        <Main height={height}>{this.getContent(request, locked)}</Main>

        <ModalAddRappel
          open={addRappel}
          onBtnToggleAddRappelClick={this.onBtnToggleAddRappelClick}
          request={request}
        />
      </RequestDetailView>
    );
  }
}

RequestDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  locked: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  iFKDemande: PropTypes.number.isRequired,
  requests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  loading: PropTypes.objectOf(PropTypes.bool).isRequired
};

const mapStateToProps = state => ({
  iFKDemande: state.request.locked.iFKDemande,
  locked: state.request.locked,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  requests: state.request.list,
  oFilters: state.request.filters,
  oOrder: state.request.order,
  loading: state.loadings.loaders
});

export default connect(mapStateToProps)(RequestDetail);
