import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BtnCancelSaveAntenne = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.textBlack};
  background: ${props => props.theme.colors.grey};
  border: 0;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.greyADOM};
  }
`;

const CancelSaveAntenne = ({ onClick }) => {
  return <BtnCancelSaveAntenne onClick={onClick}>Annuler</BtnCancelSaveAntenne>;
};

CancelSaveAntenne.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CancelSaveAntenne;
