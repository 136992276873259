import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PatientNom = styled.div`
  white-space: nowrap;
`;

export default function Nom({ sCodeCivilite, sNom }) {
  return <PatientNom>{sCodeCivilite ? `${sCodeCivilite} ${sNom}` : sNom}</PatientNom>;
}

Nom.propTypes = {
  sCodeCivilite: PropTypes.string,
  sNom: PropTypes.string.isRequired
};

Nom.defaultProps = {
  sCodeCivilite: null
};
