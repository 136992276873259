import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Address = styled.div`
  padding-left: 20px;
`;

export default function Principale({ sVille, sCodePostal }) {
  return (
    <Address>
      {sVille}
      <br />
      {sCodePostal}
    </Address>
  );
}

Principale.propTypes = {
  sVille: PropTypes.string.isRequired,
  sCodePostal: PropTypes.string.isRequired
};
