import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setCommentaire } from '../../../../actions/request';

const StyledBtnSaveComment = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnSaveComment extends Component {
  onClick = evt => {
    const { onClick, dispatch, iPKDemande, iPKUtilisateur, requests, sCommentaire } = this.props;

    const { iFKAnnuaire } = requests.filter(r => r.iPKDemande === iPKDemande)[0];

    evt.preventDefault();

    onClick();

    dispatch(setCommentaire(iPKDemande, iPKUtilisateur, iFKAnnuaire, sCommentaire));
  };

  render() {
    return <StyledBtnSaveComment onClick={this.onClick}>Enregistrer</StyledBtnSaveComment>;
  }
}

BtnSaveComment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.string.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  requests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  sCommentaire: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  requests: state.request.list
});

export default connect(mapStateToProps)(BtnSaveComment);
