import React from 'react';
import PropTypes from 'prop-types';

import Principale from './Principale';
import Etablissement from './Etablissement';

export default function Address({ bEtablissement, sEtablissement, sAdresse, sVille, sCodePostal }) {
  return bEtablissement ? (
    <Etablissement
      sEtablissement={sEtablissement}
      sAdresse={sAdresse}
      sVille={sVille}
      sCodePostal={sCodePostal}
    />
  ) : (
    <Principale sVille={sVille} sCodePostal={sCodePostal} />
  );
}

Address.propTypes = {
  bEtablissement: PropTypes.bool.isRequired,
  sEtablissement: PropTypes.string,
  sAdresse: PropTypes.string.isRequired,
  sVille: PropTypes.string.isRequired,
  sCodePostal: PropTypes.string.isRequired
};

Address.defaultProps = {
  sEtablissement: ''
};
