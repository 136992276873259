import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';

import { Modal, Header, Form, Select } from 'semantic-ui-react';

import { updateUser } from '../../../../../../actions/user';

const BtnCancel = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.textBlack};
  background: ${props => props.theme.colors.grey};
  border: 0;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.greyADOM};
  }
`;

const BtnSave = styled.button`
  margin: 0 10px 0 20px;
  padding: 0 10px;
  width: auto;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  text-align: center;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }

  & i {
    margin: 0 !important;
  }
`;

class Profile extends Component {
  constructor(props) {
    super(props);

    const { tAntenne, user, config } = this.props;

    this.tAntenneOptions = [];
    tAntenne.forEach(antenne => {
      this.tAntenneOptions.push({
        key: antenne.iFKAnnuaire,
        value: antenne.iFKAnnuaire,
        text: antenne.sNom
      });
    });

    this.tNatureDemandeOptions = [];
    config.tNature.forEach(nature => {
      this.tNatureDemandeOptions.push({
        key: nature.sNature,
        value: nature.sNature,
        text: nature.sNature
      });
    });

    this.tPrestationDemandeOptions = [];
    config.tPrestation.forEach(prestation => {
      this.tPrestationDemandeOptions.push({
        key: prestation.sPrestation,
        value: prestation.sPrestation,
        text: prestation.sPrestation
      });
    });

    this.state = {
      tAntenne: user.tAntenne,
      tNature: user.tNature,
      tPrestation: user.tPrestation
    };
  }

  onSelectChange = (evt, data) => {
    evt.preventDefault();

    this.setState({ [data.id]: data.value });
  };

  onBtnSaveClick = evt => {
    const { dispatch, user, onClose } = this.props;
    const { tAntenne, tNature, tPrestation } = this.state;

    dispatch(updateUser(user.iPKUtilisateur, tAntenne, tNature, tPrestation));

    onClose(evt);
  };

  render() {
    const { onClose } = this.props;
    const { tAntenne, tNature, tPrestation } = this.state;

    return (
      <Modal open size="small" onClose={onClose} dimmer="inverted">
        <Header icon="user" content="Préférences utilisateur" />
        <Modal.Content>
          <Form>
            <Form.Field
              control={Select}
              multiple
              id="tAntenne"
              label="Sélectionnez vos Antennes de rattachement :"
              options={this.tAntenneOptions}
              value={tAntenne}
              onChange={this.onSelectChange}
            />
            <Form.Field
              control={Select}
              multiple
              id="tNature"
              label="Sélectionnez les natures de vos demandes :"
              options={this.tNatureDemandeOptions}
              value={tNature}
              onChange={this.onSelectChange}
            />
            <Form.Field
              control={Select}
              multiple
              id="tPrestation"
              label="Sélectionnez les prestations de vos demandes :"
              options={this.tPrestationDemandeOptions}
              value={tPrestation}
              onChange={this.onSelectChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <BtnCancel onClick={onClose}>Annuler</BtnCancel>
          <BtnSave onClick={this.onBtnSaveClick}>Enregistrer</BtnSave>
        </Modal.Actions>
      </Modal>
    );
  }
}

Profile.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tAntenne: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  config: state.config,
  tAntenne: state.config.tAntenne,
  user: state.user.data
});

export default connect(mapStateToProps)(Profile);
