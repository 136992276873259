import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

import MenuItem from './Item';
import MenuItemLink from './Item/Link';

const StyledMenu = styled.ul`
  position: fixed;
  margin: 0;
  padding: 0;
  width: 40px;
`;

class Menu extends Component {
  onClick = (evt, ref) => {
    evt.preventDefault();

    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const { refs } = this.props;

    return (
      <StyledMenu>
        <MenuItem>
          <MenuItemLink href="#detail-actions" onClick={evt => this.onClick(evt, refs[0])}>
            <Icon name="hand pointer outline" />
          </MenuItemLink>
        </MenuItem>
        <MenuItem>
          <MenuItemLink href="#detail-request" onClick={evt => this.onClick(evt, refs[1])}>
            <Icon name="file alternate outline" />
          </MenuItemLink>
        </MenuItem>
        <MenuItem>
          <MenuItemLink href="#detail-isadom-comment" onClick={evt => this.onClick(evt, refs[2])}>
            <Icon name="comment" />
          </MenuItemLink>
        </MenuItem>
        <MenuItem>
          <MenuItemLink href="#detail-comments" onClick={evt => this.onClick(evt, refs[3])}>
            <Icon name="comment outline" />
          </MenuItemLink>
        </MenuItem>
        <MenuItem>
          <MenuItemLink href="#detail-address" onClick={evt => this.onClick(evt, refs[4])}>
            <Icon name="tty" />
          </MenuItemLink>
        </MenuItem>
        <MenuItem>
          <MenuItemLink href="#detail-planning" onClick={evt => this.onClick(evt, refs[5])}>
            <Icon name="calendar alternate outline" />
          </MenuItemLink>
        </MenuItem>
        <MenuItem>
          <MenuItemLink href="#detail-patient" onClick={evt => this.onClick(evt, refs[6])}>
            <Icon name="user outline" />
          </MenuItemLink>
        </MenuItem>
      </StyledMenu>
    );
  }
}

Menu.propTypes = {
  refs: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default Menu;
