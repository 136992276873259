import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form, TextArea } from 'semantic-ui-react';

import InfoEdit from './Content/Info/Edit';
import Actions from './Content/Info/Actions';
import BtnDeleteRequest from '../../../../Btn/DeleteRequest';
import BtnCancelDeleteRequest from '../../../../Btn/CancelDeleteRequest';
import BtnOpenDeleteRequest from '../../../../Btn/Submit';
import BtnDisabled from '../../../../Btn/Disabled';

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

class Annulation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancel: false,
      motif: ''
    };
  }

  onBtnOpenDeleteRequestClick = evt => {
    evt.preventDefault();

    this.setState({ cancel: true });
  };

  onBtnCancelDeleteRequestClick = evt => {
    evt.preventDefault();

    this.setState({ cancel: false, motif: '' });
  };

  onMotifChange = (evt, data) => {
    const { value } = data;

    this.setState({ motif: value });
  };

  onBtnDeleteRequestClick = evt => {
    evt.preventDefault();

    this.setState({ cancel: false });
  };

  render() {
    const { request } = this.props;
    const { cancel, motif } = this.state;

    return (
      request.bInactif === 0 &&
      (cancel ? (
        <div>
          <InfoEdit>
            <Form>
              <Form.Field
                id="motif"
                control={TextArea}
                label={
                  <FormLabel htmlFor="motif">
                    Motif :<Mandatory>*</Mandatory>
                  </FormLabel>
                }
                placeholder="Motif de l'annulation"
                value={motif}
                onChange={this.onMotifChange}
              />
            </Form>

            <Actions>
              <BtnCancelDeleteRequest onClick={this.onBtnCancelDeleteRequestClick} />
              {motif.length > 0 ? (
                <BtnDeleteRequest onClick={this.onBtnDeleteRequestClick} sCommentaire={motif} />
              ) : (
                <BtnDisabled label="Annuler la demande" />
              )}
            </Actions>
          </InfoEdit>
        </div>
      ) : (
        <BtnOpenDeleteRequest
          modified
          label="Annuler la demande"
          onClick={this.onBtnOpenDeleteRequestClick}
        />
      ))
    );
  }
}

/*
 */

Annulation.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Annulation;
