import React, { Component } from 'react';
import styled from 'styled-components';

import { ReflexContainer, ReflexElement } from 'react-reflex';

import NotificationsContainer from '../components/Core/Layout/Notifications/Container';

import Pane from '../components/Core/Layout/Pane';

import TopBar from '../components/Core/Layout/TopBar';
import TopPane from '../components/Core/Layout/Pane/TopPane';
import MainTitle from '../components/Core/Layout/Title/H1';

const Screen = styled.div`
  background: #ffffff;
`;

export default class Stats extends Component {
  constructor() {
    super();

    this.mounted = false;

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,

      topBarPane: {
        size: 40
      },

      topPane: {
        size: 120
      },

      mainPane: {
        size: window.innerHeight - 160
      }
    };

    this.resizeProps = {
      onStopResize: this.onStopResize.bind(this),
      onResize: this.onResize.bind(this)
    };

    this.notificationsContainerRef = React.createRef();
  }

  componentDidMount = () => {
    this.mounted = true;

    window.addEventListener('resize', this.updateDimensions.bind(this));

    this.updateDimensions();
  };

  componentWillUnmount() {
    this.mounted = false;

    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  onResize = () => {};

  onStopResize = () => {};

  updateDimensions = () => {
    if (this.mounted) {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  render() {
    const { width, height, topBarPane, topPane, mainPane, notifications } = this.state;

    return (
      <Screen>
        <ReflexContainer
          windowResizeAware
          orientation="horizontal"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <ReflexElement {...topBarPane}>
            <TopBar notificationsContainer={this.notificationsContainerRef.current} />
          </ReflexElement>

          <ReflexElement>
            <ReflexContainer
              windowResizeAware
              orientation="horizontal"
              style={{ width: `${width}px`, height: `${height - topBarPane.size}px` }}
            >
              <ReflexElement {...topPane}>
                <TopPane>
                  <MainTitle>Statistiques</MainTitle>
                </TopPane>
              </ReflexElement>

              <ReflexElement>
                <ReflexContainer
                  windowResizeAware
                  orientation="horizontal"
                  style={{
                    width: `${width}px`,
                    height: `${height - (topBarPane.size + topPane.size)}px`
                  }}
                >
                  <ReflexElement flex={1} {...mainPane} {...this.resizeProps}>
                    <Pane>Composition de la liste des demandes</Pane>
                  </ReflexElement>
                </ReflexContainer>
              </ReflexElement>
            </ReflexContainer>
          </ReflexElement>
        </ReflexContainer>

        <NotificationsContainer
          style={{ right: '-300px' }}
          forwardRef={this.notificationsContainerRef}
          notifications={notifications}
        />
      </Screen>
    );
  }
}
