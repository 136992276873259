import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  getDelaiBackground,
  getDelaiBorderColor,
  getDelaiColor
} from '../../../../../../../../../utils';

const StyledDelai = styled.div`
  position: relative;
  top: 7px;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 0.95em;
  font-weight: bold;
  text-align: center;
  color: ${props => getDelaiColor(props.value, props.theme.colors)};
  background: ${props => getDelaiBackground(props.value, props.theme.colors)};
  border: 2px solid ${props => getDelaiBorderColor(props.value, props.theme.colors)};
  border-radius: 50%;
  overflow: hidden;
`;

const Delai = ({ iDelai }) => {
  return <StyledDelai value={iDelai}>{iDelai}</StyledDelai>;
};

Delai.propTypes = {
  iDelai: PropTypes.number.isRequired
};

export default Delai;
