import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setDelai } from '../../../../actions/request';

const StyledBtnSaveDelai = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnSaveDelai extends Component {
  onClick = evt => {
    const {
      onClick,
      dispatch,
      iPKDemande,
      dDispoPatient,
      dLimite,
      sCommentaire,
      user,
      oFilters,
      oOrder
    } = this.props;

    evt.preventDefault();

    onClick();

    if (
      dDispoPatient.isValid &&
      dLimite.isValid &&
      (sCommentaire && sCommentaire.length > 0) &&
      (iPKDemande && iPKDemande > 0)
    ) {
      dispatch(
        setDelai(
          user.iPKUtilisateur,
          iPKDemande,
          dDispoPatient,
          dLimite,
          sCommentaire,
          user.iFKAnnuaire,
          `${user.sCodeCivilite} ${user.sPrenom[0]}. ${user.sNom}`,
          oFilters,
          oOrder
        )
      );
    }
  };

  render() {
    return <StyledBtnSaveDelai onClick={this.onClick}>Modifier le délai</StyledBtnSaveDelai>;
  }
}

BtnSaveDelai.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.string.isRequired,
  dDispoPatient: PropTypes.string.isRequired,
  dLimite: PropTypes.string.isRequired,
  sCommentaire: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  oFilters: state.request.filters,
  oOrder: state.request.order
});

export default connect(mapStateToProps)(BtnSaveDelai);
