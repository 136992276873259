import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatPhone } from '../../../../../../../../utils';

import Label from './Info/Label';
import Detail from './Info/Detail';

const Info = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 0 15px;
  background: ${props => props.theme.colors.backgroundLight};
`;

const PhoneLink = styled.a`
  font-size: 16px;
  color: ${props => props.theme.colors.textBlack};
  transition: color 0.25s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`;

const Phone = ({ label, number }) => {
  return (
    <Info>
      <Label wide>{label}&nbsp;:</Label>
      <Detail wide>
        <PhoneLink href={`tel:${number}`} title="">
          {formatPhone(number)}
        </PhoneLink>
      </Detail>
    </Info>
  );
};

Phone.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.string
};

Phone.defaultProps = {
  number: ''
};

export default Phone;
