import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuItemLink = styled.button`
  margin: 0;
  padding: 10px;
  text-align: center;
  color: ${props => props.theme.colors.textBlack};
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  background: transparent;
  border: 0;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.blue};
    background: ${props => props.theme.colors.grey};
  }
`;

export default function Link({ children, onClick }) {
  return <MenuItemLink onClick={onClick}>{children}</MenuItemLink>;
}

Link.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
