import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import gsap from 'gsap';

import Fade from 'react-reveal/Fade';

import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import { listViews } from '../actions/view';
import { unlockRequest } from '../actions/request';
import { deleteRappel } from '../actions/rappel';

import NotificationsContainer from '../components/Core/Layout/Notifications/Container';

import Pane from '../components/Core/Layout/Pane';

import TopBar from '../components/Core/Layout/TopBar';
import TopPane from '../components/Core/Layout/Pane/TopPane';
import ViewsList from '../components/Core/Layout/View/List';
import FilterPane from '../components/Core/Layout/Pane/FilterPane';
import MainTitle from '../components/Core/Layout/Title/H1';
import BtnRefresh from '../components/Core/Layout/Btn/Refresh';
import RequestsTable from '../components/Core/Layout/View/RequestsTable';
import RequestDetail from '../components/Core/Layout/View/RequestDetail';
import Stats from '../components/Core/Layout/Stats';
import Alert from '../components/Core/Layout/Alert';

const Screen = styled.div`
  background: ${props => props.theme.colors.white};
`;

class Planification extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.detailWidth = window.innerWidth > 1366 ? 444 : 344;

    this.state = {
      topBarPane: {
        size: 40
      },

      filterPane: {
        minSize: 40,
        maxSize: 300,
        size: 40,
        opened: false,
        onStopResize: this.onFilterPaneStopResize
      },

      topPane: {
        minSize: 120,
        maxSize: 120,
        size: 120
      },

      mainPane: {
        minSize: window.innerWidth / 2,
        maxSize: window.innerWidth - this.detailWidth,
        size: window.innerWidth - this.detailWidth
      },

      detailsPane: {
        size: 0,
        minSize: this.detailWidth - 44,
        maxSize: (window.innerWidth * 2) / 5
      }
    };

    this.resizeProps = {
      onStopResize: this.onStopResize.bind(this),
      onResize: this.onResize.bind(this)
    };

    this.notificationsContainerRef = React.createRef();
  }

  componentDidMount = () => {
    this.mounted = true;

    const { dispatch, iPKUtilisateur } = this.props;

    dispatch(listViews(iPKUtilisateur));

    window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('beforeunload', evt => this.onWindowClose(evt));

    this.updateDimensions();
  };

  componentWillUnmount() {
    this.mounted = false;

    const { dispatch, iPKUtilisateur } = this.props;

    dispatch(unlockRequest(iPKUtilisateur));

    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  onWindowClose = evt => {
    evt.preventDefault();

    const { dispatch, iPKUtilisateur } = this.props;

    dispatch(unlockRequest(iPKUtilisateur));

    return true;
  };

  onResize = () => {};

  onStopResize = () => {};

  onFilterPaneStopResize = event => {
    const { filterPane } = this.state;
    const { minSize, maxSize } = filterPane;
    const size = event.domElement.clientWidth;

    if (size <= minSize) {
      this.setState(prevState => ({
        filterPane: Object.assign({}, prevState.filterPane, { size: minSize, opened: false })
      }));
    } else if (size > minSize) {
      this.setState(prevState => ({
        filterPane: Object.assign({}, prevState.filterPane, { size: maxSize, opened: true })
      }));
    }
  };

  onBtnToggleFilterPaneClick = event => {
    event.preventDefault();

    const { filterPane } = this.state;

    if (filterPane.opened) {
      this.closePane(filterPane);
    } else {
      this.openPane(filterPane);
    }
  };

  onBtnNotificationCloseClick = iPKRappel => {
    const { dispatch } = this.props;

    dispatch(deleteRappel(iPKRappel));
  };

  updateDimensions = () => {
    if (this.mounted) {
      this.detailWidth = window.innerWidth > 1366 ? 444 : 344;

      this.setState(prevState => ({
        detailsPane: {
          ...prevState.detailsPane,
          size: (window.innerWidth * 2) / 5,
          maxSize: (window.innerWidth * 2) / 5,
          minSize: this.detailWidth - 44
        }
      }));
    }
  };

  openPane = pane => {
    const { size, maxSize } = pane;
    const counter = { var: size };

    gsap.to(counter, {
      duration: 0.5,
      ease: 'power2.inOut',
      var: maxSize,
      onUpdate: () =>
        this.setState(prevState => ({
          filterPane: Object.assign({}, prevState.filterPane, {
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) >= (maxSize * 2) / 3
          })
        }))
    });
  };

  closePane = pane => {
    const { size, minSize, maxSize } = pane;
    const counter = { var: size };

    gsap.to(counter, {
      duration: 0.5,
      ease: 'power2.inOut',
      var: minSize,
      onUpdate: () =>
        this.setState(prevState => ({
          filterPane: Object.assign({}, prevState.filterPane, {
            size: Math.ceil(counter.var),
            opened: Math.ceil(counter.var) === maxSize
          })
        }))
    });
  };

  render() {
    const { tRappel } = this.props;
    const { topBarPane, filterPane, topPane, mainPane, detailsPane } = this.state;

    return (
      <Screen>
        <TopBar notificationsContainer={this.notificationsContainerRef.current} />

        <ReflexContainer
          windowResizeAware
          orientation="horizontal"
          style={{
            marginTop: '40px',
            width: `${window.innerWidth}px`,
            height: `${window.innerHeight - 40}px`
          }}
        >
          <ReflexElement
            propagateDimensionsRate={100}
            propagateDimensions
            size={window.innerHeight - 40}
          >
            <ReflexContainer
              windowResizeAware
              orientation="vertical"
              style={{
                width: `${window.innerWidth}px`,
                height: `${window.innerHeight - (topBarPane.size + 2)}px`
              }}
            >
              <ReflexElement
                {...filterPane}
                propagateDimensionsRate={100}
                propagateDimensions
                style={{
                  background: 'rgb(241, 242, 246)'
                }}
              >
                <FilterPane
                  opened={filterPane.opened}
                  onBtnToggleFilterPaneClick={this.onBtnToggleFilterPaneClick}
                />
              </ReflexElement>

              <ReflexSplitter propagate {...this.resizeProps} />

              <ReflexElement propagateDimensionsRate={100} propagateDimensions>
                <ReflexContainer
                  windowResizeAware
                  orientation="horizontal"
                  style={{
                    width: `${window.innerWidth - (filterPane.size + 2)}px`,
                    height: `${window.innerHeight - (topBarPane.size + 2)}px`
                  }}
                >
                  <ReflexElement {...topPane} propagateDimensionsRate={100} propagateDimensions>
                    <TopPane>
                      <Alert />

                      <Stats />

                      <MainTitle>
                        Planification des demandes
                        <BtnRefresh />
                      </MainTitle>

                      <ViewsList />
                    </TopPane>
                  </ReflexElement>

                  <ReflexElement propagateDimensionsRate={100} propagateDimensions>
                    <ReflexContainer
                      windowResizeAware
                      orientation="vertical"
                      style={{
                        width: `${window.innerWidth - (filterPane.size + 2)}px`,
                        height: `${window.innerHeight - (topBarPane.size + topPane.size + 2)}px`
                      }}
                    >
                      <ReflexElement
                        flex={1}
                        {...mainPane}
                        {...this.resizeProps}
                        propagateDimensionsRate={100}
                        propagateDimensions
                        className="requests-table-frame"
                      >
                        <Pane>
                          <RequestsTable />
                        </Pane>
                      </ReflexElement>

                      <ReflexSplitter propagate {...this.resizeProps} />

                      <ReflexElement
                        {...detailsPane}
                        {...this.resizeProps}
                        propagateDimensionsRate={100}
                        propagateDimensions
                      >
                        <RequestDetail
                          height={window.innerHeight - (topBarPane.size + topPane.size + 2)}
                        />
                      </ReflexElement>
                    </ReflexContainer>
                  </ReflexElement>
                </ReflexContainer>
              </ReflexElement>
            </ReflexContainer>
          </ReflexElement>
        </ReflexContainer>

        <NotificationsContainer
          forwardRef={this.notificationsContainerRef}
          tRappel={tRappel}
          onBtnNotificationCloseClick={this.onBtnNotificationCloseClick}
        />
      </Screen>
    );
  }
}

Planification.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.string).isRequired,
  tRappel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  oFilters: state.request.filters,
  oOrder: state.request.order,
  tRappel: state.rappel.list
});

export default connect(mapStateToProps)(Planification);
