import { encrypt, decrypt } from '../utils';

const saveMeetingsOnDateChanged = (store, planning) => {
  console.log(`Saving received meetings, ${planning.meetings.length} meetings`);
  const request = indexedDB.open('AIDE', 1);

  request.onerror = event => {
    console.log(`Couldn't connect to AIDE to save meetings: ${event.target.errorCode}`);
  };

  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction('planning', 'readwrite');
    const planningStore = transaction.objectStore('planning');
    const selectRDVRequest = planningStore.get(planning.date);

    selectRDVRequest.onsuccess = () => {
      let nextMeetings = planning.meetings;

      if (selectRDVRequest.result) {
        const meetings = JSON.parse(decrypt(selectRDVRequest.result.meetings));

        meetings.forEach(m => {
          nextMeetings = nextMeetings.map(mm =>
            mm.iPKRdv === m.iPKRdv && m.sIDVisite !== '' ? m : mm
          );
        });

        nextMeetings = encrypt(JSON.stringify(nextMeetings));

        planningStore.put({ date: store.planning.date, meetings: nextMeetings });
      } else {
        nextMeetings = encrypt(JSON.stringify(nextMeetings));

        planningStore.add({ date: planning.date, meetings: nextMeetings });
      }

      transaction.oncomplete = () => {
        db.close();
      };
    };
  };
};

const saveMeetingOnStop = (store, meetingId) => {
  const request = indexedDB.open('AIDE', 1);

  request.onerror = event => {
    console.log(`Couldn't connect to AIDE to save meeting: ${event.target.errorCode}`);
  };

  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction('planning', 'readwrite');
    const planningStore = transaction.objectStore('planning');

    const meeting = store.planning.meetings.filter(m => m.iPKRdv === meetingId)[0];

    const selectRDVRequest = planningStore.get(store.planning.date);

    selectRDVRequest.onsuccess = () => {
      let nextMeetings = [];

      if (selectRDVRequest.result) {
        const meetings = JSON.parse(decrypt(selectRDVRequest.result.meetings));

        if (meetings.filter(m => m.iPKRdv === meetingId).length > 0) {
          nextMeetings = meetings.map(m => (m.iPKRdv === meetingId ? meeting : m));
        } else {
          nextMeetings = [...meetings, meeting];
        }

        nextMeetings = encrypt(JSON.stringify(nextMeetings));

        planningStore.put({ date: store.planning.date, meetings: nextMeetings });
      } else {
        nextMeetings = encrypt(JSON.stringify([meeting]));

        planningStore.add({ date: store.planning.date, meetings: nextMeetings });
      }

      transaction.oncomplete = () => {
        db.close();
      };
    };
  };
};

const saveStoreIntoDB = store => {
  console.log(`Saving store into DB, ${store.planning.meetings.length} meeting(s)`);
  const request = indexedDB.open('AIDE', 1);

  request.onerror = event => {
    console.log(`Couldn't connect to AIDE to save meetings: ${event.target.errorCode}`);
  };

  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction('planning', 'readwrite');
    const planningStore = transaction.objectStore('planning');

    const nextMeetings = encrypt(JSON.stringify(store.planning.meetings));

    planningStore.put({ date: store.planning.date, meetings: nextMeetings });

    transaction.oncomplete = () => {
      db.close();
    };
  };
};

const saveMessages = (userId, messages) => {
  console.log(`Saving messages into DB, ${messages.length} message(s)`);
  const request = indexedDB.open('AIDE', 1);

  request.onerror = event => {
    console.log(`Couldn't connect to AIDE to save messages: ${event.target.errorCode}`);
  };

  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction('message', 'readwrite');
    const messageStore = transaction.objectStore('message');

    const nextMessages = encrypt(JSON.stringify(messages));

    messageStore.put({ id: parseInt(userId, 10), messages: nextMessages });

    transaction.oncomplete = () => {
      db.close();
    };
  };
};

export default store => next => action => {
  // Connexion à la BDD AIDE
  const request = indexedDB.open('AIDE', 1);

  request.onerror = event => {
    // On n'a pas réussi à accéder à la BDD (l'utilisateur a refusé l'accès)
    // On affiche un message et le code erreur dans la console
    console.log('AIDE erreur', event.target.errorCode);
  };

  request.onupgradeneeded = event => {
    // On a accès la ba BDD mais il faut la recréer
    const db = event.target.result;

    // On crée l'emplacement de sauvegarde pour les intervenants
    const userObjectStore = db.createObjectStore('user', { keyPath: 'id' });

    // On ajoute un index pour les recherches
    userObjectStore.createIndex('id', 'id', { unique: true });

    // On crée l'emplacement de sauvegarde pour les messages
    const messageObjectStore = db.createObjectStore('message', { keyPath: 'id' });

    // On ajoute un index pour les recherches
    messageObjectStore.createIndex('id', 'id', { unique: true });

    // On crée l'emplacement de sauvegarde pour les RDV
    const planningObjectStore = db.createObjectStore('planning', { keyPath: 'date' });

    // On ajoute un index pour les recherches
    planningObjectStore.createIndex('date', 'date', { unique: true });

    planningObjectStore.transaction.oncomplete = () => {
      // La création de l'emplacement s'est bien passée
      console.log('AIDE ready!');

      switch (action.type) {
        default:
          break;
      }
    };
  };

  request.onsuccess = () => {
    switch (action.type) {
      default:
        break;
    }
  };

  next(action);
};
