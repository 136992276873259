import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setVisite } from '../../../../actions/request';

const StyledBtnSaveVisite = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnSaveVisite extends Component {
  onClick = evt => {
    const { onClick, dispatch, iPKDemande, bVisiteAssociation } = this.props;

    evt.preventDefault();

    onClick();

    dispatch(setVisite(iPKDemande, bVisiteAssociation));
  };

  render() {
    return <StyledBtnSaveVisite onClick={this.onClick}>Modifier la visite</StyledBtnSaveVisite>;
  }
}

BtnSaveVisite.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.string.isRequired,
  bVisiteAssociation: PropTypes.bool.isRequired
};

export default connect(null)(BtnSaveVisite);
