import {
  RAPPEL_LIST_REQUEST,
  RAPPEL_LIST_USER_REQUEST,
  RAPPEL_ADD_REQUEST,
  RAPPEL_EDIT_REQUEST,
  RAPPEL_DELETE_REQUEST
} from '../constants/ActionTypes';

export const listRappels = iPKUtilisateur => ({
  type: RAPPEL_LIST_REQUEST,
  payload: { iPKUtilisateur }
});

export const listUserRappels = iPKUtilisateur => ({
  type: RAPPEL_LIST_USER_REQUEST,
  payload: { iPKUtilisateur }
});

export const addRappel = (iPKUtilisateur, iFKDemande, dDate, sCommentaire) => {
  return {
    type: RAPPEL_ADD_REQUEST,
    payload: { iPKUtilisateur, iFKDemande, dDate, sCommentaire }
  };
};
export const editRappel = (iPKRappel, dDate, sCommentaire) => ({
  type: RAPPEL_EDIT_REQUEST,
  payload: { iPKRappel, dDate, sCommentaire }
});

export const deleteRappel = iPKRappel => ({
  type: RAPPEL_DELETE_REQUEST,
  payload: { iPKRappel }
});
