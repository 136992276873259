import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form, Select } from 'semantic-ui-react';

import BtnSaveVisite from '../../../../../Btn/SaveVisite';
import BtnCancelSaveVisite from '../../../../../Btn/CancelSaveVisite';
import BtnDisabled from '../../../../../Btn/Disabled';

import InfoEdit from './Info/Edit';
import Label from './Info/Label';
import Detail from './Info/Detail';
import Actions from './Info/Actions';
import Value from './Info/Value';

const Info = styled.div`
  display: flex;
  margin: 0 0 10px;
  width: 100%;
  padding: 0 0 0 15px;
`;

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

class VisiteAssociation extends Component {
  constructor(props) {
    super(props);

    const { bVisiteAssociation } = this.props;

    this.state = {
      bVisiteAssociation,
      bVisiteAssociationInitial: bVisiteAssociation,
      edited: false
    };
  }

  componentDidUpdate(prevProps) {
    const { bVisiteAssociation } = this.props;

    if (prevProps.bVisiteAssociation !== bVisiteAssociation) {
      this.setState({ bVisiteAssociation, bVisiteAssociationInitial: bVisiteAssociation });
    }
  }

  onVisiteEditBtnClick = evt => {
    evt.preventDefault();
    this.setState({ edited: true });
  };

  onVisiteFieldChange = (evt, data) => {
    evt.preventDefault();

    const { value } = data;

    const { bVisiteAssociation } = this.state;

    this.setState({ bVisiteAssociation: value, edited: bVisiteAssociation !== value });
  };

  onBtnCancelSaveVisiteClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({
      bVisiteAssociation: prevState.bVisiteAssociationInitial,
      edited: false
    }));
  };

  onBtnSaveVisiteClick = () => {
    this.setState(prevState => ({
      edited: false,
      bVisiteAssociation: prevState.bVisiteAssociation
    }));
  };

  render() {
    const { edited, bVisiteAssociation, bVisiteAssociationInitial } = this.state;
    const { iPKDemande, label } = this.props;

    return edited ? (
      <InfoEdit>
        <Form onSubmit={this.onVisiteFieldChange}>
          <Form.Field
            id="bVisiteAssociation"
            label={
              <FormLabel htmlFor="bVisiteAssociation">
                Visite à l&apos;association :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            control={Select}
            options={[{ key: 0, value: false, text: 'non' }, { key: 1, value: true, text: 'oui' }]}
            onChange={this.onVisiteFieldChange}
            value={bVisiteAssociation}
            placeholder=""
          />
        </Form>

        <Actions>
          <BtnCancelSaveVisite onClick={this.onBtnCancelSaveVisiteClick} />
          {bVisiteAssociation !== bVisiteAssociationInitial ? (
            <BtnSaveVisite
              onClick={this.onBtnSaveVisiteClick}
              iPKDemande={iPKDemande}
              bVisiteAssociation={bVisiteAssociation}
            />
          ) : (
            <BtnDisabled label="Modifier la visite" />
          )}
        </Actions>
      </InfoEdit>
    ) : (
      <Info>
        <Label wide>{label}&nbsp;:</Label>
        <Detail wide onClick={this.onVisiteEditBtnClick}>
          <Value value={this.props.bVisiteAssociation ? 'oui' : 'non'} />
        </Detail>
      </Info>
    );
  }
}

VisiteAssociation.propTypes = {
  iPKDemande: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  bVisiteAssociation: PropTypes.bool.isRequired
};

export default VisiteAssociation;
