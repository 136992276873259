import gsap from 'gsap';

export const handleScreenEnterAnimation = node => {
  if (!node) return;

  gsap.killTweensOf(node);

  gsap.set(node, {
    opacity: 0,
    autoAlpha: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    maxWidth: '100%',
    maxHeigh: '100%',
    'z-index': 100
  });

  gsap.to(node, 0.5, {
    force3D: true,
    opacity: 1,
    autoAlpha: 1,
    // onComplete: handleComplete,
    onCompleteParams: [node]
  });
};

export const handleScreenExitAnimation = node => {
  if (!node) return;

  gsap.killTweensOf(node);

  gsap.set(node, {
    opacity: 1,
    autoAlpha: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    maxWidth: '100%',
    maxHeigh: '100%',
    overflow: 'hidden',
    'z-index': 0
  });

  gsap.to(node, 0.5, {
    force3D: true,
    opacity: 0,
    autoAlpha: 0
  });
};

export const handleNotificationsContainerCloseAnimation = node => {
  if (!node) return;

  gsap.killTweensOf(node);

  gsap.set(node, {
    right: 0
  });

  gsap.to(node, 0.35, {
    force3D: true,
    right: '-300px',
    onComplete: node =>
      gsap.set(node, {
        clearProps: 'transform'
      }),
    onCompleteParams: [node]
  });
};

export const handleNotificationsContainerOpenAnimation = node => {
  if (!node) return;

  gsap.killTweensOf(node);

  gsap.set(node, {
    right: '-300px'
  });

  gsap.to(node, 0.35, {
    force3D: true,
    right: 0,
    onComplete: node =>
      gsap.set(node, {
        clearProps: 'transform'
      }),
    onCompleteParams: [node]
  });
};

export const handleFiltersContainerCloseAnimation = node => {
  if (!node) return;

  gsap.killTweensOf(node);

  gsap.set(node, {
    left: 0
  });

  gsap.to(node, 0.35, {
    force3D: true,
    left: '-260px',
    onComplete: node =>
      gsap.set(node, {
        clearProps: 'transform'
      }),
    onCompleteParams: [node]
  });
};

export const handleFiltersContainerOpenAnimation = node => {
  if (!node) return;

  gsap.killTweensOf(node);

  gsap.set(node, {
    left: '-260px'
  });

  gsap.to(node, 0.35, {
    force3D: true,
    left: 0,
    onComplete: node =>
      gsap.set(node, {
        clearProps: 'transform'
      }),
    onCompleteParams: [node]
  });
};
