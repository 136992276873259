import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  border-bottom: 2px solid #ccc;
`;

const TopPane = ({ children }) => <Wrapper>{children}</Wrapper>;

TopPane.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default TopPane;
