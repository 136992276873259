import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  margin: 10px 0 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: space-between;

  & > button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export default Actions;
