import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { orderRequests } from '../../../../../../actions/request';

const Header = styled.button`
  position: relative;
  padding: 10px 30px 10px 10px;
  height: 52px;
  width: 100%;
  font-family: 'Futura';
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  color: ${props => props.theme.colors.textBlack};
  background: ${props => (props.active ? props.theme.colors.grey : 'transparent')};
  border: 0;
  outline: none;
  cursor: pointer;

  @media (max-width: 1366px) {
    & {
      padding: 10px;
    }
  }
`;

const SortIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 15px;
`;

class RequestsTableColHeader extends Component {
  onHeaderClick = evt => {
    evt.preventDefault();

    const { dispatch, name, filters, iLocked, iPKUtilisateur } = this.props;

    dispatch(
      orderRequests(filters, { col: name, dir: this.getSortDir() }, iLocked, iPKUtilisateur)
    );
  };

  getSortDir = () => {
    const { sortCol, sortDir, name } = this.props;
    let dir = 'ASC';

    if (sortCol === name) {
      if (sortDir === 'ASC') {
        dir = 'DESC';
      }
    }

    return dir;
  };

  render() {
    const { children, name, sortCol, sortDir } = this.props;

    return (
      <Header active={sortCol === name} onClick={this.onHeaderClick}>
        {children}
        {sortCol === name ? (
          <SortIcon name={sortDir === 'ASC' ? 'triangle up' : 'triangle down'} size="large" />
        ) : null}
      </Header>
    );
  }
}

RequestsTableColHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  sortCol: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  iLocked: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  filters: state.request.filters,
  sortCol: state.request.order.col,
  sortDir: state.request.order.dir,
  iLocked: state.request.locked.iFKDemande,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(RequestsTableColHeader);
