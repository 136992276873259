import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Icon } from 'semantic-ui-react';

import { lockRequest } from '../../../../actions/request';
import { handleNotificationsContainerCloseAnimation } from '../../../../animations';

const BtnCloseNotification = styled.button`
  position: absolute;
  top: 10px;
  right: 0;
  color: ${props => props.theme.colors.white};
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.purple};
  }
`;

const NotificationWrapper = styled.div`
  position: relative;
  margin: 0 0 20px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid ${props => props.theme.colors.white};
  transition: all ease-in-out 0.25s;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.textBlack};
  }
`;

const NotificationTitle = styled.div`
  color: ${props => props.theme.colors.white};
`;
const NotificationDate = styled.div`
  float: right;
  margin: 0 25px 0 0;
  color: ${props => props.theme.colors.white};
  font-size: 12px;
`;
const NotificationMessage = styled.div`
  padding: 5px 0 0;
  color: ${props => props.theme.colors.white};
`;

class Message extends Component {
  onBtnMessageCloseClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const { onBtnNotificationCloseClick, rappel } = this.props;

    onBtnNotificationCloseClick(rappel.iPKRappel);
  };

  onBtnNotificationLinkClick = (evt, rappel) => {
    evt.preventDefault();

    const { dispatch, oFilters, oOrder, oParametres, notificationsContainer } = this.props;

    window.clearTimeout(this.unlockTimer);
    this.unlockTimer = null;

    dispatch(lockRequest(rappel.iFKDemande, rappel.iFKUtilisateur, oFilters, oOrder));

    this.unlockTimer = window.setTimeout(this.unlockRequests, oParametres.unlockDelay * 60 * 1000);

    handleNotificationsContainerCloseAnimation(notificationsContainer);
  };

  render() {
    const { rappel } = this.props;

    return (
      <NotificationWrapper onClick={evt => this.onBtnNotificationLinkClick(evt, rappel)}>
        <BtnCloseNotification onClick={this.onBtnMessageCloseClick}>
          <Icon name="close" color="grey" />
        </BtnCloseNotification>
        <NotificationDate>
          {`Le ${DateTime.fromMillis(parseInt(rappel.dDate, 10)).toFormat('dd/LL/yyyy à HH:mm')}`}
        </NotificationDate>
        <NotificationTitle>{rappel.Demande.sNature}</NotificationTitle>
        <NotificationMessage>{rappel.sCommentaire}</NotificationMessage>
      </NotificationWrapper>
    );
  }
}

Message.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onBtnNotificationCloseClick: PropTypes.func.isRequired,
  rappel: PropTypes.objectOf(PropTypes.any).isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  oParametres: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  oFilters: state.request.filters,
  oOrder: state.request.order,
  oParametres: state.config.oParametres
});

export default connect(mapStateToProps)(Message);
