import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInfoZoneTitle = styled.div`
  margin: 0 0 10px;
  border-bottom: 1px solid ${props => props.theme.colors.inputBorderDark};
`;

const InfoZoneTitle = ({ children }) => {
  return <StyledInfoZoneTitle>{children}</StyledInfoZoneTitle>;
};

InfoZoneTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default InfoZoneTitle;
