import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

import { unlockRequest } from '../../../../actions/request';

const StyledBtnCloseRequest = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnCloseRequest extends Component {
  onClick = evt => {
    const { dispatch, iPKUtilisateur } = this.props;

    evt.preventDefault();

    dispatch(unlockRequest(iPKUtilisateur));
  };

  render() {
    return (
      <StyledBtnCloseRequest onClick={this.onClick}>
        <Icon name="lock open" />
      </StyledBtnCloseRequest>
    );
  }
}

BtnCloseRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(BtnCloseRequest);
