import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

const StyledBtnAddRappel = styled.button`
  position: absolute;
  top: 20px;
  right: 70px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const BtnAddRappel = ({ onClick }) => (
  <StyledBtnAddRappel onClick={onClick}>
    <Icon name="bell outline" />
  </StyledBtnAddRappel>
);

BtnAddRappel.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BtnAddRappel;
