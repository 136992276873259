import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { lockRequest, unlockRequest } from '../../../../../actions/request';

import RequestTableHeaders from './Headers';
import RequestTableRow from './Row/Body';
import RequestTableCol from './Col';
import CodeAnnuaire from './Col/CodeAnnuaire';
import Delai from './Col/Delai';
import Adresse from './Col/Adresse';
import Patient from './Col/Patient';
import Appointment from './Col/Appointment';
import Lock from './Col/Lock';

const RequestTable = styled.table`
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colors.grey};
`;

class RequestsTable extends Component {
  unlockTimer = null;

  fullyLoaded = false;

  constructor(props) {
    super(props);

    this.state = {
      tRequest: [],
      currentPage: 4,
      itemsPerPage: 20
    };
  }

  componentDidMount() {
    localStorage.setItem('scroll', 0);
    const frame = document.querySelector('div.requests-table-frame');

    frame.addEventListener('scroll', () => {
      const { scrollTop, offsetHeight } = frame;

      if (scrollTop > 0) {
        localStorage.setItem('scroll', scrollTop);
      }

      const { requests } = this.props;
      const { tRequest } = this.state;

      if (scrollTop > (1.5 * offsetHeight) / 2) {
        if (tRequest.length < requests.length) {
          setTimeout(() => {
            this.setState(prevState => ({
              tRequest: requests.slice(0, prevState.currentPage * this.itemsPerPage),
              currentPage: prevState.currentPage + 1
            }));
          }, 0);
        }
      }
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { requests } = nextProps;
    const { currentPage, itemsPerPage } = prevState;
    return {
      tRequest: requests.slice(0, itemsPerPage * currentPage)
    };
  }

  componentDidUpdate() {
    document.querySelector('div.requests-table-frame').scrollTop = parseInt(
      localStorage.getItem('scroll'),
      10
    );
  }

  componentWillUnmount() {
    this.unlockTimer = window.clearTimeout(this.unlockTimer);
    this.unlockTimer = null;
  }

  onRequestClick = (evt, request) => {
    evt.preventDefault();

    const { iPKUtilisateur, iFKDemande, dispatch, oFilters, oOrder, oParametres } = this.props;

    if (request.iPKDemande === iFKDemande) {
      dispatch(unlockRequest(iPKUtilisateur));
      window.clearTimeout(this.unlockTimer);
      this.unlockTimer = null;
    } else {
      dispatch(lockRequest(request.iPKDemande, iPKUtilisateur, oFilters, oOrder));

      this.unlockTimer = window.setTimeout(
        this.unlockRequests,
        oParametres.unlockDelay * 60 * 1000
      );
    }
  };

  unlockRequests = () => {
    const { dispatch, iPKUtilisateur } = this.props;

    dispatch(unlockRequest(iPKUtilisateur));
    window.clearTimeout(this.unlockTimer);
    this.unlockTimer = null;
  };

  render() {
    const { iFKDemande, locked, sortCol, view, loading } = this.props;
    const { tRequest } = this.state;

    return loading.refresh ? (
      <Loader active>Chargement</Loader>
    ) : (
      <RequestTable>
        <thead>
          <RequestTableHeaders view={view} />
        </thead>
        <tbody>
          {tRequest.map((request, index) => (
            <RequestTableRow
              index={index}
              selected={iFKDemande === request.iPKDemande}
              bVue={request.bVue}
              bTraitee={request.bTraitee}
              echeance={request.iDelai}
              bAttente={!!request.bAttente}
              sCouleur={request.sCouleur}
              key={`req${request.iPKDemande}`}
              onClick={evt => {
                this.onRequestClick(evt, request);
              }}
            >
              <RequestTableCol selected={iFKDemande === request.iPKDemande}>
                <Lock
                  locked={locked > 0 && iFKDemande === request.iPKDemande}
                  busy={locked === -1}
                />
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'iFKAnnuaire'}
                selected={iFKDemande === request.iPKDemande}
              >
                {request.sCodeAnnuaire}
                <CodeAnnuaire iPKAnnuaire={request.iFKAnnuaire} bVue={request.bVue} />
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'sNom'}
                selected={iFKDemande === request.iPKDemande}
              >
                <Patient
                  dHospitalisation={request.dHospitalisation}
                  sCodeCivilite={request.sCodeCivilite}
                  sNom={request.sNom.toUpperCase()}
                  sEtablissementHospitalisation={request.sEtablissementHospitalisation}
                  sPrenom={
                    request.sPrenom
                      ? `${request.sPrenom[0].toUpperCase()}${request.sPrenom.slice(1)}`
                      : '-'
                  }
                />
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'sCodePostal'}
                selected={iFKDemande === request.iPKDemande}
              >
                <Adresse
                  bEtablissement={request.bEtablissement}
                  sEtablissement={request.sEtablissement}
                  sAdresse={request.sAdresse}
                  sCodePostal={request.sCodePostal}
                  sVille={request.sVille}
                />
              </RequestTableCol>

              <RequestTableCol
                nowrap
                active={sortCol === 'sNature'}
                selected={iFKDemande === request.iPKDemande}
              >
                {request.sNature}
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'sDemandeur'}
                selected={iFKDemande === request.iPKDemande}
              >
                {request.sDemandeur.toUpperCase()}
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'sPrestation'}
                selected={iFKDemande === request.iPKDemande}
              >
                {request.sPrestation ? request.sPrestation : '-'}
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'dDate'}
                selected={iFKDemande === request.iPKDemande}
              >
                {DateTime.fromMillis(parseInt(request.dDate, 10)).toFormat('dd/LL/yyyy')}
              </RequestTableCol>

              <RequestTableCol
                active={sortCol === 'iDelai'}
                selected={iFKDemande === request.iPKDemande}
                style={{ textAlign: 'center' }}
              >
                {!request.bAttente ? (
                  <Delai
                    iPKDemande={request.iPKDemande}
                    dDispoPatient={request.dDispoPatient}
                    iDelai={request.iDelai}
                    bModifie={request.bModifie}
                    historique={request.historiqueDelais}
                  />
                ) : (
                  '-'
                )}
              </RequestTableCol>

              {view === 0 ? (
                <RequestTableCol
                  active={sortCol === 'dDateProgrammation'}
                  selected={iFKDemande === request.iPKDemande}
                >
                  {request.dDateProgrammation
                    ? DateTime.fromMillis(parseInt(request.dDateProgrammation, 10)).toFormat(
                        'dd/LL/yyyy'
                      )
                    : '-'}
                </RequestTableCol>
              ) : (
                <RequestTableCol
                  active={sortCol === 'dDateProgrammation'}
                  selected={iFKDemande === request.iPKDemande}
                >
                  <Appointment tRendezVous={request.tRendezVous} />
                </RequestTableCol>
              )}

              <RequestTableCol
                active={sortCol === 'bVisiteAssociation'}
                selected={iFKDemande === request.iPKDemande}
              >
                {request.bVisiteAssociation ? 'Oui' : 'Non'}
              </RequestTableCol>
            </RequestTableRow>
          ))}
        </tbody>
      </RequestTable>
    );
  }
}

RequestsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requests: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.number])
    )
  ).isRequired,
  sortCol: PropTypes.string.isRequired,
  locked: PropTypes.number.isRequired,
  iFKDemande: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  view: PropTypes.number.isRequired,
  loading: PropTypes.objectOf(PropTypes.bool).isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  oParametres: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  requests: state.request.list,
  sortCol: state.request.order.col,
  iFKDemande: state.request.locked.iFKDemande,
  locked: state.request.locked.iPKVerrouillage,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  view: state.view.selected,
  loading: state.loadings.loaders,
  oFilters: state.request.filters,
  oOrder: state.request.order,
  oParametres: state.config.oParametres
});

export default connect(mapStateToProps)(RequestsTable);
