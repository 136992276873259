import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Icon, Popup } from 'semantic-ui-react';

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 10;
`;

const HospitIcon = styled(Icon)`
  display: block !important;
  width: 20px !important;
  height: 20px !important;
  text-align: center !important;
  line-height: 20px !important;
  font-size: 20px !important;
  color: ${props => props.theme.colors.purple};
`;

const Hospitalisation = ({ dHospitalisation, sEtablissementHospitalisation }) => {
  if (dHospitalisation) {
    const sHospDebut = DateTime.fromMillis(parseInt(dHospitalisation, 10)).toFormat('dd/LL/yyyy');

    return (
      <Popup
        trigger={
          <IconWrapper>
            <HospitIcon name="h square" />
          </IconWrapper>
        }
        content={`Hospitalisation à ${sEtablissementHospitalisation} depuis le ${sHospDebut}`}
      />
    );
  }

  return null;
};

Hospitalisation.propTypes = {
  dHospitalisation: PropTypes.string,
  sEtablissementHospitalisation: PropTypes.string
};

Hospitalisation.defaultProps = {
  dHospitalisation: null,
  sEtablissementHospitalisation: ''
};

export default Hospitalisation;
