import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

import { countRequests } from '../../../../../actions/request';

const StyledViewItem = styled.li`
  position: relative;
  margin-right: 10px;
  display: inline-block;
  padding: ${props => (props.closable ? '9px 105px 9px 9px' : '9px 80px 9px 9px')};
  height: 40px;
  list-style: none;

  font-family: 'Futura';
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.textBlack)};
  background: ${props =>
    props.selected ? props.theme.colors.blueDark : props.theme.colors.backgroundLight};
  border: 1px solid
    ${props => (props.selected ? props.theme.colors.blueDark : props.theme.colors.backgroundLight)};
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props =>
      props.selected ? props.theme.colors.blueDark : props.theme.colors.blue};
    border: 1px solid
      ${props => (props.selected ? props.theme.colors.blueDark : props.theme.colors.blue)};
  }

  &:hover span {
    opacity: 0.66;
  }
`;

const BtnViewItemClose = styled.button`
  position: absolute;
  top: 5px;
  right: 75px;
  z-index: 100;
  padding: 0;
  width: 15px;
  height: 15px;
  text-align: center;
  background: transparent;
  border: 0;
  outline: none;
  transition: all ease-in-out 0.25s

  &:hover {
    color: ${props => props.theme.colors.blue};
    background: ${props => props.theme.colors.grey};
  }

  & i {
    margin: 0;
  }
`;

const Count = styled.span`
  position: absolute;
  top: -1px;
  right: 35px;
  display: inline-block;
  width: 36px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.textBlack)};
  background: ${props =>
    props.selected ? props.theme.colors.textBlack : props.theme.colors.inputBorderLight};
  transition: all ease-in-out 0.25s;
`;

const Nouvelle = styled.span`
  position: absolute;
  top: -1px;
  right: -1px;
  display: inline-block;
  width: 36px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};
  transition: all ease-in-out 0.25s;
`;

class ViewItem extends Component {
  componentDidMount() {
    const { dispatch, id, oFilters } = this.props;

    dispatch(countRequests(id, oFilters));
  }

  onBtnCloseClick = evt => {
    const { onClose, id } = this.props;
    onClose(evt, id);
  };

  render() {
    const { label, iCount, iNouvelles, selected, closable, onClick } = this.props;

    return (
      <StyledViewItem selected={selected} closable={closable} onClick={onClick}>
        {closable && (
          <BtnViewItemClose onClick={this.onBtnCloseClick}>
            <Icon name="close" />
          </BtnViewItemClose>
        )}
        {label}
        <Count selected={selected}>{iCount}</Count>
        <Nouvelle selected={selected}>{iNouvelles}</Nouvelle>
      </StyledViewItem>
    );
  }
}

ViewItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  iCount: PropTypes.number.isRequired,
  iNouvelles: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  closable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired
};

ViewItem.defaultProps = {
  closable: false
};

export default connect(null)(ViewItem);
