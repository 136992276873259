import React from 'react';
import PropTypes from 'prop-types';

import PatientNom from './Nom';
import PatientPrenom from './Prenom';
import Hospitalisation from './Hospitalisation';

export default function Patient({ sCodeCivilite, sPrenom, sNom, dHospitalisation, sEtablissementHospitalisation }) {
  return (
    <>
      <Hospitalisation dHospitalisation={dHospitalisation} sEtablissementHospitalisation={sEtablissementHospitalisation} />
      <PatientNom sCodeCivilite={sCodeCivilite} sNom={sNom} />
      <PatientPrenom sPrenom={sPrenom} />
    </>
  );
}

Patient.propTypes = {
  sCodeCivilite: PropTypes.string,
  sPrenom: PropTypes.string.isRequired,
  sNom: PropTypes.string.isRequired,
  sEtablissementHospitalisation: PropTypes.string,
  dHospitalisation: PropTypes.string
};

Patient.defaultProps = {
  sCodeCivilite: null,
  sEtablissementHospitalisation: '',
  dHospitalisation: null
};
