import { put } from 'redux-saga/effects';

import { LOADING_ACTIVATE_SUCCESS, LOADING_DEACTIVATE_SUCCESS } from '../constants/ActionTypes';

export function* showLoading({ payload: { target } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target } });
}

export function* hideLoading({ payload: { target } }) {
  yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target } });
}
