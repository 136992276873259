import { NETWORK_ONLINE_REQUEST, NETWORK_OFFLINE_REQUEST } from '../constants/ActionTypes';

export const setOnlineMode = status => ({
  type: NETWORK_ONLINE_REQUEST,
  payload: { status }
});

export const setOfflineMode = status => ({
  type: NETWORK_OFFLINE_REQUEST,
  payload: { status }
});
