import { combineReducers } from 'redux';

import { USER_SIGNOUT_SUCCESS } from './constants/ActionTypes';

import config from './reducers/config';
import loadings from './reducers/loadings';
import user from './reducers/user';
import network from './reducers/network';
import request from './reducers/request';
import view from './reducers/view';
import rappel from './reducers/rappel';

const appReducer = combineReducers({
  config,
  loadings,
  network,
  request,
  user,
  view,
  rappel
});

export default (state, action) => {
  let currentState = state;

  if (action.type === USER_SIGNOUT_SUCCESS) {
    currentState = undefined;
  }

  return appReducer(currentState, action);
};
