import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';

import { createGlobalStyle, ThemeProvider } from 'styled-components';

import Offline from 'react-offline';
import { setOnlineMode, setOfflineMode } from './actions/network';
import { loadRequests } from './actions/request';
import { listViews } from './actions/view';
import { listRappels } from './actions/rappel';

import Routes from './Routes';

import theme from './theme';

import UniversCondensed from './assets/fonts/Univers-Condensed.otf';
import UniversCondensedBold from './assets/fonts/Univers-Condensed-Bold.otf';
import Futura from './assets/fonts/Futura-LT-Book.otf';

import 'semantic-ui-css/semantic.min.css';
import 'react-reflex/styles.css';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "UniversCondensed";
    src: url(${UniversCondensed});
  }

  @font-face {
    font-family: "UniversCondensedBold";
    src: url(${UniversCondensedBold});
  }

  @font-face {
    font-family: "Futura";
    src: url(${Futura});
  }

  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.colors.textBlack};
  }

  body {
    margin: 0;
    padding: 0;
    min-width: ${window.innerWidth}px;
    min-height: ${window.innerHeight}px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "UniversCondensed", sans-serif !important;
    font-size: 14px;
    line-height: 1.2857142857142857142857142857143em;
    color: ${props => props.theme.colors.textBlack} !important;
    background: ${props => props.theme.colors.textBlack} !important;
    overscroll-behavior: none;
    overflow: hidden;
  }

  h1, h2, h3, h4,
  strong {
    font-family: "UniversCondensedBold";
  }

  label,
  input,
  textarea {
    font-family: "Futura", sans-serif !important;
  }

  .ui.button {
    font-family: "Futura", sans-serif !important;
    font-weight: 400 !important;
  }

  a {
    color: ${props => props.theme.colors.primary};

    &:focus,
    &:visited {
      color: ${props => props.theme.colors.primary};
      outline: none;
    }
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  @media(max-width: 1366px) {
    body {
      font-size: 12px !important;
      line-height: 1.2857142857142857142857142857143em;
    }
  }
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.requestsTimer = null;
    this.rappelsTimer = null;
  }

  componentDidMount() {
    this.mounted = true;

    const { oParametres } = this.props;

    this.requestsTimer = window.setInterval(
      this.loadRequests,
      oParametres.refreshDelay * 60 * 1000
    );

    // this.loadRequests();

    this.rappelsTimer = window.setInterval(this.loadUserRappels, 60 * 1000);

    this.loadUserRappels();
  }

  componentWillUnmount() {
    this.mounted = false;

    this.requestsTimer = window.clearInterval(this.requestsTimer);
    this.requestsTimer = null;
  }

  onNetworkUpdate = ({ isOffline }) => {
    const { dispatch } = this.props;

    if (isOffline) {
      dispatch(setOfflineMode());
    } else {
      dispatch(setOnlineMode());
    }
  };

  loadRequests = () => {
    const {
      isAuthenticated,
      iPKVerrouillage,
      dispatch,
      filters,
      order,
      iPKUtilisateur
    } = this.props;

    if (isAuthenticated && iPKVerrouillage <= 0) {
      dispatch(loadRequests(filters, order));
      dispatch(listViews(iPKUtilisateur));
    }
  };

  loadUserRappels = () => {
    const { dispatch, isAuthenticated, iPKUtilisateur, tRappel } = this.props;

    if (isAuthenticated && tRappel.length > 0) {
      dispatch(listRappels(iPKUtilisateur));
    }
  };

  render() {
    const { history } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <>
          <Offline onChange={this.onNetworkUpdate} />
          <Router>
            <Routes history={history} animatedTransitions />
          </Router>
          <GlobalStyle />
        </>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  offline: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  order: PropTypes.objectOf(PropTypes.string).isRequired,
  iPKUtilisateur: PropTypes.number,
  iPKVerrouillage: PropTypes.number.isRequired,
  oParametres: PropTypes.objectOf(PropTypes.any).isRequired,
  tRappel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

App.defaultProps = {
  iPKUtilisateur: 0
};

const mapStateToProps = state => ({
  oParametres: state.config.oParametres,
  offline: state.network.offline,
  isAuthenticated: state.user.isAuthenticated,
  antenne: state.user.data.iFKAntenne,
  filters: state.request.filters,
  order: state.request.order,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  iPKVerrouillage: state.request.locked.iPKVerrouillage,
  tRappel: state.rappel.list
});

export default connect(mapStateToProps)(App);
