import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Label from './Info/Label';
import Detail from './Info/Detail';

const Info = styled.div`
  display: ${props => (props.flex ? 'flex' : 'block')};
  width: 100%;
  padding: 0 0 0 15px;
`;

const Text = styled.div`
  padding: 0 0 10px;
  line-height: 24px;
  white-space: pre-line;
`;

const LongText = ({ label, text }) => {
  return (
    <Info flex={!text}>
      {label && <Label>{label}&nbsp;:</Label>}
      {text ? <Text>{text}</Text> : <Detail>-</Detail>}
    </Info>
  );
};

LongText.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string.isRequired
};

LongText.defaultProps = {
  label: ''
};

export default LongText;
