import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { Label, Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';

import {
  handleNotificationsContainerCloseAnimation,
  handleNotificationsContainerOpenAnimation
} from '../../../../animations';

import User from './User';

import logoBlancBleu from '../../../../assets/img/logoAGIR_blancbleu.png';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 300px;
  height: 40px;
  width: 100%;
  /* background-image: url(${logoBlancBleu});
  background-position: 20px 50%;
  background-repeat: no-repeat; */
  background-color: ${props => props.theme.colors.blue};
`;

const BtnNotificationToggle = styled.button`
  position: relative;
  float: right;
  margin: 0 20px 0 0;
  padding: 5px;
  height: 40px;
  background: ${props => (props.opened ? props.theme.colors.blueDark : 'transparent')};
  border: 0;
  outline: none;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  & i {
    margin: 0 !important;
    color: ${props => props.theme.colors.white};
  }

  & .ui.floating.label {
    top: 4px;
    padding: 3px 5px;
  }

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

const MainNav = styled.ul`
  margin: 0;
  padding: 0;
`;
const NavItem = styled.li`
  display: inline-block;
  list-style: none;
`;
const NavItemLink = styled(NavLink)`
  display: block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  font-family: 'Futura';
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
  }

  &.active {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationsContainerOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    const { tRappel } = this.props;

    if (tRappel.length !== prevProps.tRappel.length) {
      const now = Date.now();

      this.setState({
        notificationsContainerOpened:
          tRappel.filter(rappel => parseInt(rappel.dDate, 10) <= now).length > 0
      });
    }
  }

  toggleNotificationsContainer = evt => {
    evt.preventDefault();

    const { notificationsContainerOpened } = this.state;
    const { notificationsContainer } = this.props;

    if (notificationsContainerOpened === true) {
      handleNotificationsContainerCloseAnimation(notificationsContainer);

      this.setState({ notificationsContainerOpened: false });
    } else {
      handleNotificationsContainerOpenAnimation(notificationsContainer);

      this.setState({ notificationsContainerOpened: true });
    }
  };

  render() {
    const { tRappel } = this.props;
    const { notificationsContainerOpened } = this.state;

    const now = Date.now();
    const tExpired = tRappel.filter(rappel => parseInt(rappel.dDate, 10) <= now);

    return (
      <Wrapper>
        <User />

        <BtnNotificationToggle
          opened={notificationsContainerOpened}
          icon
          onClick={this.toggleNotificationsContainer}
          floated="right"
        >
          <Icon name="bell outline" size="large" />
          {tExpired.length > 0 && (
            <Label color="red" floating>
              {tExpired.length}
            </Label>
          )}
        </BtnNotificationToggle>

        <MainNav>
          <NavItem>
            <NavItemLink to="/planification">Planification</NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink to="/rappels">Rappels</NavItemLink>
          </NavItem>

          {/* <NavItem>
            <NavItemLink to="/stats">Stats</NavItemLink>
          </NavItem> 
          */}
        </MainNav>
      </Wrapper>
    );
  }
}

TopBar.propTypes = {
  notificationsContainer: PropTypes.objectOf(PropTypes.any),
  tRappel: PropTypes.arrayOf(PropTypes.any).isRequired
};

TopBar.defaultProps = {
  notificationsContainer: null
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  tRappel: state.rappel.list
});

export default connect(mapStateToProps)(TopBar);
