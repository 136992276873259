import React from 'react';

import { Link } from 'react-router-dom';

export default function Deconnexion() {
  return (
    <div>
      <h1>Déconnexion</h1>

      <Link to="/connexion">Cliquez ici pour vous re-connecter</Link>
    </div>
  );
}
