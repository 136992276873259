import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Message from './Message';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 40px;
  right: ${props => (props.rappels ? '0px' : '-300px')};
  padding: 20px;
  height: ${window.innerHeight - 40}px;
  width: 300px;
  background: rgba(0, 0, 0, 0.75);
`;

const EmptyNotificationsInfo = styled.div`
  color: ${props => props.theme.colors.white};
`;

class Container extends Component {
  render() {
    const { forwardRef, tRappel, onBtnNotificationCloseClick } = this.props;

    const now = Date.now();
    const tExpired = tRappel.filter(rappel => parseInt(rappel.dDate, 10) <= now);

    return (
      <Wrapper ref={forwardRef} rappels={tExpired.length > 0}>
        {tExpired.length === 0 ? (
          <EmptyNotificationsInfo>Aucune nouvelle notification</EmptyNotificationsInfo>
        ) : (
          tExpired.map(rappel => (
            <Message
              notificationsContainer={forwardRef.current}
              key={rappel.iPKRappel}
              rappel={rappel}
              onBtnNotificationCloseClick={onBtnNotificationCloseClick}
            />
          ))
        )}
      </Wrapper>
    );
  }
}

Container.propTypes = {
  tRappel: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  forwardRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  onBtnNotificationCloseClick: PropTypes.func.isRequired
};

export default Container;
