import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Statistic } from 'semantic-ui-react';

const StyledStats = styled.div`
  float: right;
`;

const Stats = ({ requests }) => {
  return (
    <StyledStats>
      <Statistic label="Demandes" value={requests.length} />
    </StyledStats>
  );
};

Stats.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  requests: state.request.list
});

export default connect(mapStateToProps)(Stats);
