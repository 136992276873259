import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { Form, Input } from 'semantic-ui-react';

import { addView } from '../../../../../actions/view';

import BtnSubmit from '../../Btn/Submit';

const Wrapper = styled.div`
  margin: 20px 0 0;
  padding: 20px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey};
`;

const PaneTitle = styled.h2`
  margin: 0 0 20px;
  font-family: 'Futura';
  font-size: 16px;
`;

const ErrorInfo = styled.div`
  margin: 10px 0 20px;
  font-family: 'Futura';
  color: ${props => props.theme.colors.purple};
`;

const TooManyViews = styled.div`
  text-align: center;
`;

class CreateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sLabel: '',
      modified: false,
      errors: {}
    };
  }

  onChange = (evt, data) => {
    this.setState({ sLabel: data.value, modified: data.value.length > 0, errors: {} });
  };

  onFocus = () => {
    this.setState({ errors: {} });
  };

  onBlur = () => {
    this.setState({ errors: {} });
  };

  onBtnCreateViewClick = evt => {
    const { dispatch, iPKUtilisateur, filters } = this.props;
    const { sLabel } = this.state;

    evt.preventDefault();

    if (sLabel.trim().length > 0) {
      dispatch(addView(iPKUtilisateur, sLabel.trim(), filters));

      this.setState({ sLabel: '', modified: false });
    } else {
      this.setState({ errors: { viewName: 'Veuillez donner un nom à la nouvelle vue' } });
    }
  };

  render() {
    const { className, viewsNb } = this.props;
    const { sLabel, modified, errors } = this.state;

    return (
      <Wrapper>
        {viewsNb < 10 ? (
          <>
            <PaneTitle>Sauvegarder les filtres</PaneTitle>
            <Form className={className}>
              <Form.Field
                control={Input}
                label="Nom de la vue"
                value={sLabel}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                placeholder="Nouvelle vue"
              />
              {Object.keys(errors).length > 0 && <ErrorInfo>{errors.viewName}</ErrorInfo>}
              <BtnSubmit
                label="Sauvegarder les filtres"
                modified={modified}
                onClick={this.onBtnCreateViewClick}
              />
            </Form>
          </>
        ) : (
          <TooManyViews>Vous avez atteint le nombre maximum de vues.</TooManyViews>
        )}
      </Wrapper>
    );
  }
}

CreateView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  className: PropTypes.string,
  viewsNb: PropTypes.number.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired
};

CreateView.defaultProps = {
  className: ''
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  viewsNb: state.view.tVue.length,
  filters: state.request.filters
});

export default connect(mapStateToProps)(CreateView);
