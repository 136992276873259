import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RequestTableHeaderRow = styled.tr`
  position: relative;
  color: ${props => props.theme.colors.textBlack};
  transition: background ease-in-out 0.25s;
`;

const HeaderRow = ({ children }) => <RequestTableHeaderRow>{children}</RequestTableHeaderRow>;

HeaderRow.propTypes = {
  children: PropTypes.node.isRequired
};

export default HeaderRow;
