import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { resetProgrammationDate } from '../../../../actions/request';
import { unlockRequest, loadRequests } from '../../../../actions/request';
import { listViews } from '../../../../actions/view';

const StyledBtnResetProgram = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.purpleDark};
  }
`;

class BtnResetProgram extends Component {
  onClick = evt => {
    const { dispatch, iPKUtilisateur, iPKDemande, onClick, oFilters, oOrder } = this.props;

    evt.preventDefault();

    onClick(evt);

    dispatch(resetProgrammationDate(iPKUtilisateur, iPKDemande));
    dispatch(loadRequests(oFilters, oOrder));
    dispatch(unlockRequest(iPKUtilisateur));
    dispatch(listViews(iPKUtilisateur));
  };

  render() {
    return <StyledBtnResetProgram onClick={this.onClick}>X</StyledBtnResetProgram>;
  }
}

BtnResetProgram.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.string).isRequired
};

const mapStateToProps = state => ({
  iPKDemande: state.request.locked.iFKDemande,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  oFilters: state.request.filters,
  oOrder: state.request.filters
});

export default connect(mapStateToProps)(BtnResetProgram);
