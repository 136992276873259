import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { DateTime } from 'luxon';
import { fetchApollo, mutateApollo } from '../apolloClient';

import {
  VIEW_LIST_SUCCESS,
  VIEW_LIST_FAILURE,
  VIEW_ADD_SUCCESS,
  VIEW_ADD_FAILURE,
  VIEW_DELETE_SUCCESS,
  VIEW_DELETE_FAILURE,
  VIEW_EDIT_SUCCESS,
  VIEW_EDIT_FAILURE,
  VIEW_ACTIVATE_REQUEST,
  VIEW_ACTIVATE_SUCCESS,
  VIEW_ACTIVATE_FAILURE,
  VIEW_DEACTIVATE_SUCCESS,
  REQUEST_FILTER_REQUEST,
  REQUEST_UNLOCK_REQUEST
} from '../constants/ActionTypes';

export function* listViews({ payload: { iPKUtilisateur } }) {
  if (!navigator.onLine) {
    alert('TODO: VIEW_LIST_REQUEST Offline');
  } else {
    try {
      const LIST_VIEW = gql`
        {
          listeVues(iFKUtilisateur: ${iPKUtilisateur} ) {
            iPKVue
            sLabel
            sFiltres
            iCount
            iNouvelles
          }
        }
      `;
      const response = yield call(fetchApollo, LIST_VIEW);
      const views = response.data.listeVues;

      if (views !== null) {
        yield put({ type: VIEW_LIST_SUCCESS, payload: views });
      } else {
        yield put({ type: VIEW_LIST_FAILURE, payload: 'Erreur de chargement des vues' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des vues';
          break;
        default:
          break;
      }

      yield put({ type: VIEW_LIST_FAILURE, payload: message });
    }
  }
}

export function* addView({ payload: { iPKUtilisateur, sLabel, oFilters } }) {
  if (!navigator.onLine) {
    alert('TODO: VIEW_ADD_REQUEST Offline');
  } else {
    try {
      const ADD_VIEW = gql`
          mutation {
            ajouteVue(iFKUtilisateur: ${iPKUtilisateur}, sLabel: "${sLabel}", sFiltres: "${JSON.stringify(
        JSON.stringify(oFilters)
      ).slice(1, -1)}") {
                iPKVue
                sLabel
                sFiltres
            }
          }
        `;
      const response = yield call(mutateApollo, ADD_VIEW);
      const view = response.data.ajouteVue;

      if (view !== null) {
        yield put({ type: VIEW_ADD_SUCCESS, payload: view });
        yield put({ type: VIEW_ACTIVATE_REQUEST, payload: { iPKVue: view.iPKVue, iLocked: -1 } });
      } else {
        yield put({ type: VIEW_ADD_FAILURE, payload: 'Erreur de création de la vue' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de création de la vue';
          break;
        default:
          break;
      }

      yield put({ type: VIEW_ADD_FAILURE, payload: message });
    }
  }
}

export function* deleteView({ payload: { iPKVue, iPKUtilisateur } }) {
  if (!navigator.onLine) {
    alert('TODO: VIEW_DELETE_REQUEST Offline');
  } else {
    try {
      const DELETE_VIEW = gql`
          mutation {
            supprimeVue(iPKVue: ${iPKVue}) {
              iResultat
            }
          }
        `;
      const response = yield call(mutateApollo, DELETE_VIEW);
      const resultat = response.data.supprimeVue;

      if (resultat.iResultat === 1) {
        yield put({ type: VIEW_DELETE_SUCCESS, payload: { iPKVue } });
        yield put({ type: REQUEST_UNLOCK_REQUEST, payload: { iPKUtilisateur } });
      } else {
        yield put({ type: VIEW_DELETE_FAILURE, payload: 'Erreur de suppression de la vue' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de suppression de la vue';
          break;
        default:
          break;
      }

      yield put({ type: VIEW_DELETE_FAILURE, payload: message });
    }
  }
}

export function* editView({ payload: { iPKVue, oFilters } }) {
  if (!navigator.onLine) {
    alert('TODO: VIEW_EDIT_REQUEST Offline');
  } else {
    try {
      const EDIT_VIEW = gql`
          mutation {
            setVue(iPKVue: ${iPKVue}, sFiltres: "${JSON.stringify(JSON.stringify(oFilters)).slice(1, -1)}") {
                iCount
                iNouvelles
                iPKVue
                sFiltres
                sLabel
            }
          }
        `;

      const response = yield call(mutateApollo, EDIT_VIEW);
      const view = response.data.setVue;

      if (view !== null) {
        yield put({ type: VIEW_EDIT_SUCCESS, payload: view });
        yield put({ type: VIEW_ACTIVATE_REQUEST, payload: { iPKVue: view.iPKVue, iLocked: -1 } });
      } else {
        yield put({ type: VIEW_EDIT_FAILURE, payload: "Erreur d'édition de la vue" });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = "Erreur d'édition de la vue";
          break;
        default:
          break;
      }

      yield put({ type: VIEW_EDIT_FAILURE, payload: message });
    }
  }
}

export function* activateView({
  payload: { iPKVue, iPKUtilisateur, tAntenne, tNature, tPrestation }
}) {
  if (!navigator.onLine) {
    alert('TODO: VIEW_ACTIVATE_REQUEST Offline');
  } else {
    try {
      let view = {
        iPKVue,
        sLabel: 'Demandes du jour',
        sFiltres: JSON.stringify({
          tAntenne,
          tNature,
          tPrestation,
          tEtablissement: [],
          tCouleur: [],
          sCodeAnnuaire: '',
          iDelaiMin: '',
          iDelaiMax: '',
          bVisiteAssociation: null,
          bEtablissement: null,
          bProgramme: null,
          bAttente: 0,
          bVue: null,
          dInsert: DateTime.local()
            .startOf('day')
            .toISO()
        })
      };
      if (iPKVue === -1) {
        view = {
          iPKVue,
          sLabel: 'Toutes les demandes',
          sFiltres: JSON.stringify({
            tAntenne,
            tNature,
            tPrestation,
            tEtablissement: [],
            tCouleur: [],
            sCodeAnnuaire: '',
            iDelaiMin: '',
            iDelaiMax: '',
            bVisiteAssociation: null,
            bEtablissement: null,
            bProgramme: null,
            bAttente: 0,
            bVue: null
          })
        };
      } else if (iPKVue === 0) {
        view = {
          iPKVue,
          sLabel: 'Demandes non vues',
          sFiltres: JSON.stringify({
            tAntenne,
            tNature,
            tPrestation,
            tEtablissement: [],
            tCouleur: [],
            sCodeAnnuaire: '',
            iDelaiMin: '',
            iDelaiMax: '',
            bVisiteAssociation: null,
            bEtablissement: null,
            bProgramme: null,
            bAttente: 0,
            bVue: 0
          })
        };
      } else if (iPKVue === -2) {
        view = {
          iPKVue,
          sLabel: 'Demandes du jour',
          sFiltres: JSON.stringify({
            tAntenne,
            tNature,
            tPrestation,
            tEtablissement: [],
            tCouleur: [],
            sCodeAnnuaire: '',
            iDelaiMin: '',
            iDelaiMax: '',
            bVisiteAssociation: null,
            bEtablissement: null,
            bProgramme: null,
            bAttente: 0,
            bVue: null,
            dInsert: DateTime.local()
              .startOf('day')
              .toISO()
          })
        };
      } else {
        const GET_VIEW = gql`
        {
          recupereVue(iPKVue: ${iPKVue} ) {
            iPKVue
            sLabel
            sFiltres
          }
        }
      `;
        const response = yield call(fetchApollo, GET_VIEW);
        view = response.data.recupereVue;
      }

      if (view !== null) {
        const oFilters = JSON.parse(view.sFiltres);
        const oOrder = { col: 'dLimite', dir: 'ASC' };

        yield put({
          type: REQUEST_FILTER_REQUEST,
          payload: { oFilters, oOrder, iPKUtilisateur }
        });
        yield put({ type: VIEW_ACTIVATE_SUCCESS, payload: view });
      } else {
        yield put({ type: VIEW_LIST_FAILURE, payload: 'Erreur de chargement de la vue' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement de la vue';
          break;
        default:
          break;
      }

      yield put({ type: VIEW_ACTIVATE_FAILURE, payload: message });
    }
  }
}

export function* deactivateView() {
  yield put({ type: VIEW_DEACTIVATE_SUCCESS, payload: {} });
}
