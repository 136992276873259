import {
  REQUEST_LOAD_REQUEST,
  REQUEST_FILTER_REQUEST,
  REQUEST_LOCK_REQUEST,
  REQUEST_UNLOCK_REQUEST,
  REQUEST_ORDER_REQUEST,
  REQUEST_SET_PROGRAMMATION_REQUEST,
  REQUEST_RESET_PROGRAMMATION_REQUEST,
  REQUEST_SET_COMMENTAIRE_REQUEST,
  REQUEST_SET_ANTENNE_REQUEST,
  REQUEST_SET_COULEUR_REQUEST,
  REQUEST_SET_DELAI_REQUEST,
  REQUEST_SET_MOTIF_REQUEST,
  REQUEST_SET_NATURE_REQUEST,
  REQUEST_LOAD_DELAIS_REQUEST,
  REQUEST_LOAD_COMMENTAIRES_REQUEST,
  REQUEST_DELETE_REQUEST,
  REQUEST_RESTORE_REQUEST,
  REQUEST_COUNT_REQUEST,
  REQUEST_SET_VIEWED_REQUEST,
  REQUEST_SET_DEALT_REQUEST,
  REQUEST_SET_VISITE_REQUEST
} from '../constants/ActionTypes';

export const loadRequests = (oFilters, oOrder) => ({
  type: REQUEST_LOAD_REQUEST,
  payload: { oFilters, oOrder }
});

export const countRequests = (id, oFilters) => ({
  type: REQUEST_COUNT_REQUEST,
  payload: { id, oFilters }
});

export const filterRequests = (oFilters, oOrder, iLocked, iPKUtilisateur, unLock = true) => ({
  type: REQUEST_FILTER_REQUEST,
  payload: { oFilters, oOrder, iLocked, iPKUtilisateur, unLock }
});

export const lockRequest = (iPKDemande, iPKUtilisateur, oFilters, oOrder) => ({
  type: REQUEST_LOCK_REQUEST,
  payload: { iPKDemande, iPKUtilisateur, oFilters, oOrder }
});

export const unlockRequest = iPKUtilisateur => ({
  type: REQUEST_UNLOCK_REQUEST,
  payload: { iPKUtilisateur }
});

export const orderRequests = (oFilters, oOrder, iPKUtilisateur) => ({
  type: REQUEST_ORDER_REQUEST,
  payload: { oFilters, oOrder, iPKUtilisateur }
});

export const setProgrammationDate = (iPKUtilisateur, iPKDemande, dDate, sNature, sPrestation) => ({
  type: REQUEST_SET_PROGRAMMATION_REQUEST,
  payload: { iPKUtilisateur, iPKDemande, dDate, sNature, sPrestation }
});

export const resetProgrammationDate = (iPKUtilisateur, iPKDemande) => ({
  type: REQUEST_RESET_PROGRAMMATION_REQUEST,
  payload: { iPKUtilisateur, iPKDemande }
});

export const setAntenne = (iPKDemande, iFKAntenne, iPKUtilisateur) => ({
  type: REQUEST_SET_ANTENNE_REQUEST,
  payload: { iPKDemande, iFKAntenne, iPKUtilisateur }
});

export const setCouleur = (iPKDemande, sCouleur) => ({
  type: REQUEST_SET_COULEUR_REQUEST,
  payload: { iPKDemande, sCouleur }
});

export const setCommentaire = (iPKDemande, iPKUtilisateur, iFKAnnuaire, sCommentaire) => ({
  type: REQUEST_SET_COMMENTAIRE_REQUEST,
  payload: { iPKDemande, iPKUtilisateur, iFKAnnuaire, sCommentaire }
});

export const setDelai = (
  iPKUtilisateur,
  iPKDemande,
  dDispoPatient,
  dLimite,
  sCommentaire,
  iFKAnnuaire,
  sUtilisateur,
  oFilters,
  oOrder
) => ({
  type: REQUEST_SET_DELAI_REQUEST,
  payload: {
    iPKUtilisateur,
    iPKDemande,
    dDispoPatient,
    dLimite,
    sCommentaire,
    iFKAnnuaire,
    sUtilisateur,
    oFilters,
    oOrder
  }
});

export const loadDelais = iPKDemande => ({
  type: REQUEST_LOAD_DELAIS_REQUEST,
  payload: { iPKDemande }
});

export const setMotif = (iPKDemande, sMotif) => ({
  type: REQUEST_SET_MOTIF_REQUEST,
  payload: { iPKDemande, sMotif }
});

export const setNature = (iPKDemande, sNature) => ({
  type: REQUEST_SET_NATURE_REQUEST,
  payload: { iPKDemande, sNature }
});

export const deleteRequest = (iPKDemande, sCommentaire, iPKUtilisateur) => ({
  type: REQUEST_DELETE_REQUEST,
  payload: { iPKDemande, sCommentaire, iPKUtilisateur }
});

export const restoreRequest = (iPKUtilisateur, iPKDemande) => ({
  type: REQUEST_RESTORE_REQUEST,
  payload: { iPKUtilisateur, iPKDemande }
});

export const loadCommentHistory = iPKDemande => ({
  type: REQUEST_LOAD_COMMENTAIRES_REQUEST,
  payload: { iPKDemande }
});

export const setViewed = (iPKDemande, bVue, oFilters, oOrder) => ({
  type: REQUEST_SET_VIEWED_REQUEST,
  payload: { iPKDemande, bVue, oFilters, oOrder }
});

export const setDealt = (iPKDemande, bTraitee, oFilters, oOrder) => ({
  type: REQUEST_SET_DEALT_REQUEST,
  payload: { iPKDemande, bTraitee, oFilters, oOrder }
});

export const setVisite = (iPKDemande, bVisiteAssociation) => ({
  type: REQUEST_SET_VISITE_REQUEST,
  payload: { iPKDemande, bVisiteAssociation }
});
