import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Form, Select, Input } from 'semantic-ui-react';

import { filterRequests } from '../../../../../actions/request';
import { deactivateView, editView } from '../../../../../actions/view';

import BtnSubmit from '../../Btn/Submit';
import AutoComplete from '../../Input/AutoComplete';

const FiltersForm = styled(Form)`
  margin: 0;
  padding: 0 20px 20px;

  & .ui.selection.dropdown {
    min-width: auto !important;
  }
`;

const FilterLabel = styled.label`
  font-family: 'Futura';
  font-weight: bold;
`;

const DelaiField = styled.div`
  font-family: 'Futura';
`;

const DelaiInput = styled(Input)`
  width: 45px !important;
  text-align: center;

  & input {
    margin: 0 7px !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    font-size: 10px important;
  }
`;

const FormCommands = styled.div`
  & > button {
    margin-right: 15px;
  }
`;

class Filters extends Component {
  constructor(props) {
    super(props);

    const { config, filters, iPKView, tAntenneUtilisateur } = this.props;

    const tEtablissementOptions = [];
    config.tEtablissement
      .filter(etablissement => tAntenneUtilisateur.indexOf(etablissement.iFKAntenne) !== -1)
      .forEach(etablissement => {
        tEtablissementOptions.push({
          key: etablissement.iPKEtablissement,
          value: etablissement.iPKEtablissement,
          text: etablissement.sNom
        });
      });

    this.state = {
      ...filters,
      bInactif: 0,
      bProgramme: 0,
      iPKView,
      modified: false,
      tAntenne: tAntenneUtilisateur,
      tEtablissementOptions
    };

    this.tAntenneOptions = [];
    config.tAntenne.forEach(antenne => {
      this.tAntenneOptions.push({
        key: antenne.iFKAnnuaire,
        value: antenne.iFKAnnuaire,
        text: antenne.sNom
      });
    });

    this.tNatureDemandeOptions = [];
    config.tNature.forEach(nature => {
      this.tNatureDemandeOptions.push({
        key: nature.sNature,
        value: nature.sNature,
        text: nature.sNature
      });
    });

    this.tPrestationDemandeOptions = [
      {
        key: -1,
        value: '_NC_',
        text: 'Non renseignée'
      }
    ];
    config.tPrestation.forEach(prestation => {
      this.tPrestationDemandeOptions.push({
        key: prestation.sPrestation,
        value: prestation.sPrestation,
        text: prestation.sPrestation
      });
    });

    this.bEtablissementOptions = [];
    this.bEtablissementOptions.push({
      key: 1,
      value: null,
      text: 'Tous'
    });
    this.bEtablissementOptions.push({
      key: 2,
      value: 1,
      text: 'Oui'
    });
    this.bEtablissementOptions.push({
      key: 3,
      value: 0,
      text: 'Non'
    });

    this.bVisiteAssociationOptions = [];
    this.bVisiteAssociationOptions.push({
      key: 1,
      value: null,
      text: 'Tous'
    });
    this.bVisiteAssociationOptions.push({
      key: 2,
      value: 1,
      text: 'Oui'
    });
    this.bVisiteAssociationOptions.push({
      key: 3,
      value: 0,
      text: 'Non'
    });

    this.bProgrammeOptions = [];
    this.bProgrammeOptions.push({
      key: 2,
      value: 1,
      text: 'Oui'
    });
    this.bProgrammeOptions.push({
      key: 3,
      value: 0,
      text: 'Non'
    });

    this.bInactifOptions = [];
    this.bInactifOptions.push({
      key: 2,
      value: 1,
      text: 'Oui'
    });
    this.bInactifOptions.push({
      key: 3,
      value: 0,
      text: 'Non'
    });

    this.tCouleurOptions = [];

    [
      {
        code: 'rgba(255, 255, 255, 0)',
        text: 'Aucune couleur'
      },
      {
        code: 'rgb(57, 178, 198)',
        text: 'Bleu'
      },
      {
        code: 'rgb(248, 179, 52)',
        text: 'Orange'
      },
      {
        code: 'rgb(62, 167, 28)',
        text: 'Vert'
      },
      {
        code: 'rgb(88, 88, 88)',
        text: 'Gris'
      }
    ].forEach((couleur, index) => {
      this.tCouleurOptions.push({
        key: index,
        text: couleur.text,
        value: couleur.code,
        image: {
          src: `data:image/svg+xml;charset=utf-8;base64,${Buffer.from(
            `<svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="24" height="24" fill="${couleur.code}"/></svg>`
          ).toString('base64')}`
        }
      });
    });

    this.delaiMax = config.iDelaiMax;

    this.delaiMin = config.iDelaiMin;
  }

  componentDidUpdate(prevProps) {
    const { filters, iPKView } = this.props;

    if (filters !== prevProps.filters || iPKView !== prevProps.iPKView) {
      this.filterEtablissements(filters.tAntenne);
      this.setState({ ...filters, iPKView });
    }
  }

  onFieldChange = (evt, data, submit = false) => {
    this.setState(
      {
        [data.id]: data.value,
        modified: true
      },
      () => {
        if (submit) {
          this.onSubmitForm(evt);
        }
      }
    );
  };

  onAntenneFieldChange = (evt, data) => {
    this.filterEtablissements(data.value);

    this.setState(prevState => ({ ...prevState, [data.id]: data.value, modified: true }));
  };

  onFiltersFormSubmit = evt => {
    evt.preventDefault();
  };

  onSubmitForm = evt => {
    const { dispatch, order, locked, iPKUtilisateur } = this.props;
    const {
      tAntenne,
      tNature,
      tPrestation,
      sCodeAnnuaire,
      iDelaiMin,
      iDelaiMax,
      tEtablissement,
      bVisiteAssociation,
      bEtablissement,
      bProgramme,
      bInactif,
      bVue,
      tCouleur,
      modified
    } = this.state;

    evt.preventDefault();

    if (modified) {
      dispatch(
        filterRequests(
          {
            tAntenne,
            tNature,
            tPrestation,
            sCodeAnnuaire,
            iDelaiMin,
            iDelaiMax,
            tEtablissement,
            bVisiteAssociation,
            bEtablissement,
            bProgramme,
            bInactif,
            bVue,
            tCouleur
          },
          order,
          locked,
          iPKUtilisateur
        )
      );
    }

    dispatch(deactivateView());
  };

  onSubmitAndSaveForm = evt => {
    const { dispatch, iPKView } = this.props;
    const {
      bEtablissement,
      bInactif,
      bProgramme,
      bVisiteAssociation,
      iDelaiMax,
      iDelaiMin,
      sCodeAnnuaire,
      tAntenne,
      tCouleur,
      tNature,
      tPrestation,
      tEtablissement
    } = this.state;

    this.onSubmitForm(evt);
    dispatch(
      editView(iPKView, {
        bEtablissement,
        bInactif,
        bProgramme,
        bVisiteAssociation,
        iDelaiMax,
        iDelaiMin,
        sCodeAnnuaire,
        tAntenne,
        tCouleur,
        tNature,
        tPrestation,
        tEtablissement
      })
    );
  };

  filterEtablissements = tAntenne => {
    const { config } = this.props;
    const tEtablissementOptions = [];

    if (tAntenne.length === 0) {
      config.tEtablissement.forEach(etablissement => {
        tEtablissementOptions.push({
          key: etablissement.iPKEtablissement,
          value: etablissement.iPKEtablissement,
          text: etablissement.sNom
        });
      });
    } else {
      this.tEtablissementOptions = [];
      config.tEtablissement
        .filter(etablissement => tAntenne.indexOf(etablissement.iFKAntenne) !== -1)
        .forEach(etablissement => {
          tEtablissementOptions.push({
            key: etablissement.iPKEtablissement,
            value: etablissement.iPKEtablissement,
            text: etablissement.sNom
          });
        });
    }

    this.setState(prevState => ({ ...prevState, tEtablissementOptions }));
  };

  render() {
    const {
      bEtablissement,
      bInactif,
      bProgramme,
      bVisiteAssociation,
      iDelaiMax,
      iDelaiMin,
      iPKView,
      modified,
      tAntenne,
      tCouleur,
      tEtablissement,
      tEtablissementOptions,
      tNature,
      tPrestation
    } = this.state;

    return (
      <>
        <FiltersForm onSubmit={this.onFiltersFormSubmit} autoComplete="new-password">
          <Form.Field
            id="tAntenne"
            control={Select}
            options={this.tAntenneOptions}
            label={
              <FilterLabel>
                Choisissez une antenne<sup>*</sup>
              </FilterLabel>
            }
            multiple
            search
            onChange={this.onAntenneFieldChange}
            onSearchChange={this.onAntenneFieldChange}
            searchInput={{ id: 'tAntenne' }}
            value={tAntenne}
            placeholder="Toutes les antennes"
          />
          <Form.Field
            id="tNature"
            control={Select}
            options={this.tNatureDemandeOptions}
            label={
              <FilterLabel>
                Choisissez la nature des demandes<sup>*</sup>
              </FilterLabel>
            }
            multiple
            search
            onChange={this.onFieldChange}
            onSearchChange={this.onFieldChange}
            searchInput={{ id: 'tNature' }}
            value={tNature}
            placeholder="Toutes les natures"
          />

          <Form.Field
            id="tPrestation"
            control={Select}
            options={this.tPrestationDemandeOptions}
            label={
              <FilterLabel>
                Choisissez la prestation des demandes<sup>*</sup>
              </FilterLabel>
            }
            multiple
            search
            onChange={this.onFieldChange}
            onSearchChange={this.onFieldChange}
            searchInput={{ id: 'tPrestation' }}
            value={tPrestation}
            placeholder="Toutes les prestations"
          />

          <Form.Field>
            <FilterLabel>Patient (n° ou nom)</FilterLabel>
            <AutoComplete
              id="sCodeAnnuaire"
              tAntenne={tAntenne}
              onClick={this.onSubmitForm}
              onChange={this.onFieldChange}
            />
          </Form.Field>

          <Form.Group>
            <Form.Field>
              <FilterLabel>&Eacute;chéance</FilterLabel>
              <DelaiField>
                Délai compris entre{' '}
                <DelaiInput
                  id="iDelaiMin"
                  value={iDelaiMin}
                  onChange={this.onFieldChange}
                  placeholder={this.delaiMin}
                />
              </DelaiField>
            </Form.Field>

            <Form.Field>
              <FilterLabel>&nbsp;</FilterLabel>
              et{' '}
              <DelaiInput
                id="iDelaiMax"
                value={iDelaiMax}
                onChange={this.onFieldChange}
                placeholder={this.delaiMax}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field
            id="tEtablissement"
            control={Select}
            options={tEtablissementOptions}
            label={<FilterLabel>Choisissez des établissements</FilterLabel>}
            multiple
            search
            onChange={this.onFieldChange}
            onSearchChange={this.onFieldChange}
            searchInput={{ id: 'tEtablissement' }}
            value={tEtablissement}
            placeholder="Toutes les établissements"
          />
          <Form.Group widths="equal">
            <Form.Field
              id="bEtablissement"
              control={Select}
              options={this.bEtablissementOptions}
              label={<FilterLabel>&Eacute;tablissement</FilterLabel>}
              search
              onChange={this.onFieldChange}
              onSearchChange={this.onFieldChange}
              searchInput={{ id: 'bEtablissement' }}
              value={bEtablissement}
              placeholder="tous"
            />

            <Form.Field
              id="bVisiteAssociation"
              control={Select}
              options={this.bVisiteAssociationOptions}
              label={<FilterLabel>Visite association</FilterLabel>}
              search
              onChange={this.onFieldChange}
              onSearchChange={this.onFieldChange}
              searchInput={{ id: 'bVisiteAssociation' }}
              value={bVisiteAssociation}
              placeholder="tous"
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              id="bProgramme"
              control={Select}
              options={this.bProgrammeOptions}
              label={<FilterLabel>Demandes programmées</FilterLabel>}
              search
              onChange={this.onFieldChange}
              onSearchChange={this.onFieldChange}
              searchInput={{ id: 'bProgramme' }}
              value={bProgramme}
              placeholder="tous"
            />

            <Form.Field
              id="bInactif"
              control={Select}
              options={this.bInactifOptions}
              label={
                <FilterLabel>
                  Demandes <br />
                  annulées
                </FilterLabel>
              }
              search
              onChange={this.onFieldChange}
              onSearchChange={this.onFieldChange}
              searchInput={{ id: 'bInactif' }}
              value={bInactif}
              placeholder="tous"
            />
          </Form.Group>

          <Form.Field
            id="tCouleur"
            control={Select}
            options={this.tCouleurOptions}
            label={<FilterLabel>Couleur</FilterLabel>}
            multiple
            search
            onChange={this.onFieldChange}
            onSearchChange={this.onFieldChange}
            searchInput={{ id: 'tCouleur' }}
            value={tCouleur}
            placeholder="Toutes les couleurs"
          />

          <FormCommands>
            <BtnSubmit
              onClick={this.onSubmitForm}
              label="Appliquer les filtres"
              modified={modified}
              disabled={!modified}
            />
          </FormCommands>

          {iPKView >= 0 && (
            <FormCommands>
              <BtnSubmit
                onClick={this.onSubmitAndSaveForm}
                label="Appliquer et sauvegarder"
                modified={modified}
                disabled={!modified}
              />
            </FormCommands>
          )}
        </FiltersForm>
      </>
    );
  }
}

Filters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  order: PropTypes.objectOf(PropTypes.string).isRequired,
  locked: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  iPKView: PropTypes.number.isRequired,
  tAntenneUtilisateur: PropTypes.arrayOf(PropTypes.number).isRequired
};

const mapStateToProps = state => ({
  config: state.config,
  filters: state.request.filters,
  order: state.request.order,
  locked: state.request.locked.iFKDemande,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  iPKView: state.view.selected,
  tAntenneUtilisateur: state.user.data.tAntenne
});

export default connect(mapStateToProps)(Filters);
