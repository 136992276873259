import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

import { loadRequests } from '../../../../actions/request';
import { listViews } from '../../../../actions/view';

const Btn = styled.button`
  position: absolute;
  right: 400px;
  margin: 0 0 0 20px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  background: ${props => props.theme.colors.backgroundLight};
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  & i {
    margin: 0 !important;
    color: ${props => props.theme.colors.textBlack};
  }

  &:hover {
    background: ${props => props.theme.colors.grey};

    & i {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

class Refresh extends Component {
  onBtnRefreshRequestsClick = evt => {
    const { dispatch, oFilters, oOrder, iPKUtilisateur } = this.props;

    evt.preventDefault();

    dispatch(loadRequests(oFilters, oOrder));
    dispatch(listViews(iPKUtilisateur));
  };

  render() {
    return (
      <Btn onClick={this.onBtnRefreshRequestsClick}>
        Rafraîchir <Icon name="refresh" />
      </Btn>
    );
  }
}

Refresh.propTypes = {
  dispatch: PropTypes.func.isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  oOrder: PropTypes.objectOf(PropTypes.string).isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  oFilters: state.request.filters,
  oOrder: state.request.order,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(Refresh);
