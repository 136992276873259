export const NETWORK_ONLINE_REQUEST = 'NETWORK_ONLINE_REQUEST';
export const NETWORK_ONLINE_SUCCESS = 'NETWORK_ONLINE_SUCCESS';
export const NETWORK_ONLINE_FAILURE = 'NETWORK_ONLINE_FAILURE';

export const NETWORK_OFFLINE_REQUEST = 'NETWORK_OFFLINE_REQUEST';
export const NETWORK_OFFLINE_SUCCESS = 'NETWORK_OFFLINE_SUCCESS';
export const NETWORK_OFFLINE_FAILURE = 'NETWORK_OFFLINE_FAILURE';

export const LOADING_ACTIVATE_REQUEST = 'LOADING_ACTIVATE_REQUEST';
export const LOADING_ACTIVATE_SUCCESS = 'LOADING_ACTIVATE_SUCCESS';
export const LOADING_ACTIVATE_FAILURE = 'LOADING_ACTIVATE_FAILURE';

export const LOADING_DEACTIVATE_REQUEST = 'LOADING_DEACTIVATE_REQUEST';
export const LOADING_DEACTIVATE_SUCCESS = 'LOADING_DEACTIVATE_SUCCESS';
export const LOADING_DEACTIVATE_FAILURE = 'LOADING_DEACTIVATE_FAILURE';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_FAILURE = 'USER_SIGNOUT_FAILURE';

export const CONFIG_LOAD_REQUEST = 'CONFIG_LOAD_REQUEST';
export const CONFIG_LOAD_SUCCESS = 'CONFIG_LOAD_SUCCESS';
export const CONFIG_LOAD_FAILURE = 'CONFIG_LOAD_FAILURE';

export const REQUEST_LOAD_REQUEST = 'REQUEST_LOAD_REQUEST';
export const REQUEST_LOAD_SUCCESS = 'REQUEST_LOAD_SUCCESS';
export const REQUEST_LOAD_FAILURE = 'REQUEST_LOAD_FAILURE';

export const REQUEST_COUNT_REQUEST = 'REQUEST_COUNT_REQUEST';
export const REQUEST_COUNT_SUCCESS = 'REQUEST_COUNT_SUCCESS';
export const REQUEST_COUNT_FAILURE = 'REQUEST_COUNT_FAILURE';

export const REQUEST_LOCK_REQUEST = 'REQUEST_LOCK_REQUEST';
export const REQUEST_LOCK_SUCCESS = 'REQUEST_LOCK_SUCCESS';
export const REQUEST_LOCK_FAILURE = 'REQUEST_LOCK_FAILURE';

export const REQUEST_UNLOCK_REQUEST = 'REQUEST_UNLOCK_REQUEST';
export const REQUEST_UNLOCK_SUCCESS = 'REQUEST_UNLOCK_SUCCESS';
export const REQUEST_UNLOCK_FAILURE = 'REQUEST_UNLOCK_FAILURE';

export const REQUEST_FILTER_REQUEST = 'REQUEST_FILTER_REQUEST';
export const REQUEST_FILTER_SUCCESS = 'REQUEST_FILTER_SUCCESS';
export const REQUEST_FILTER_FAILURE = 'REQUEST_FILTER_FAILURE';

export const REQUEST_ORDER_REQUEST = 'REQUEST_ORDER_REQUEST';
export const REQUEST_ORDER_SUCCESS = 'REQUEST_ORDER_SUCCESS';
export const REQUEST_ORDER_FAILURE = 'REQUEST_ORDER_FAILURE';

export const REQUEST_DELETE_REQUEST = 'REQUEST_DELETE_REQUEST';
export const REQUEST_DELETE_SUCCESS = 'REQUEST_DELETE_SUCCESS';
export const REQUEST_DELETE_FAILURE = 'REQUEST_DELETE_FAILURE';

export const REQUEST_RESTORE_REQUEST = 'REQUEST_RESTORE_REQUEST';
export const REQUEST_RESTORE_SUCCESS = 'REQUEST_RESTORE_SUCCESS';
export const REQUEST_RESTORE_FAILURE = 'REQUEST_RESTORE_FAILURE';

export const REQUEST_SET_PROGRAMMATION_REQUEST = 'REQUEST_SET_PROGRAMMATION_REQUEST';
export const REQUEST_SET_PROGRAMMATION_SUCCESS = 'REQUEST_SET_PROGRAMMATION_SUCCESS';
export const REQUEST_SET_PROGRAMMATION_FAILURE = 'REQUEST_SET_PROGRAMMATION_FAILURE';

export const REQUEST_RESET_PROGRAMMATION_REQUEST = 'REQUEST_RESET_PROGRAMMATION_REQUEST';
export const REQUEST_RESET_PROGRAMMATION_SUCCESS = 'REQUEST_RESET_PROGRAMMATION_SUCCESS';
export const REQUEST_RESET_PROGRAMMATION_FAILURE = 'REQUEST_RESET_PROGRAMMATION_FAILURE';

export const REQUEST_SET_COMMENTAIRE_REQUEST = 'REQUEST_SET_COMMENTAIRE_REQUEST';
export const REQUEST_SET_COMMENTAIRE_SUCCESS = 'REQUEST_SET_COMMENTAIRE_SUCCESS';
export const REQUEST_SET_COMMENTAIRE_FAILURE = 'REQUEST_SET_COMMENTAIRE_FAILURE';

export const REQUEST_SET_ANTENNE_REQUEST = 'REQUEST_SET_ANTENNE_REQUEST';
export const REQUEST_SET_ANTENNE_SUCCESS = 'REQUEST_SET_ANTENNE_SUCCESS';
export const REQUEST_SET_ANTENNE_FAILURE = 'REQUEST_SET_ANTENNE_FAILURE';

export const REQUEST_SET_COULEUR_REQUEST = 'REQUEST_SET_COULEUR_REQUEST';
export const REQUEST_SET_COULEUR_SUCCESS = 'REQUEST_SET_COULEUR_SUCCESS';
export const REQUEST_SET_COULEUR_FAILURE = 'REQUEST_SET_COULEUR_FAILURE';

export const REQUEST_SET_DELAI_REQUEST = 'REQUEST_SET_DELAI_REQUEST';
export const REQUEST_SET_DELAI_SUCCESS = 'REQUEST_SET_DELAI_SUCCESS';
export const REQUEST_SET_DELAI_FAILURE = 'REQUEST_SET_DELAI_FAILURE';

export const REQUEST_SET_MOTIF_REQUEST = 'REQUEST_SET_MOTIF_REQUEST';
export const REQUEST_SET_MOTIF_SUCCESS = 'REQUEST_SET_MOTIF_SUCCESS';
export const REQUEST_SET_MOTIF_FAILURE = 'REQUEST_SET_MOTIF_FAILURE';

export const REQUEST_SET_NATURE_REQUEST = 'REQUEST_SET_NATURE_REQUEST';
export const REQUEST_SET_NATURE_SUCCESS = 'REQUEST_SET_NATURE_SUCCESS';
export const REQUEST_SET_NATURE_FAILURE = 'REQUEST_SET_NATURE_FAILURE';

export const REQUEST_LOAD_DELAIS_REQUEST = 'REQUEST_LOAD_DELAIS_REQUEST';
export const REQUEST_LOAD_DELAIS_SUCCESS = 'REQUEST_LOAD_DELAIS_SUCCESS';
export const REQUEST_LOAD_DELAIS_FAILURE = 'REQUEST_LOAD_DELAIS_FAILURE';

export const REQUEST_SET_VIEWED_REQUEST = 'REQUEST_SET_VIEWED_REQUEST';
export const REQUEST_SET_VIEWED_SUCCESS = 'REQUEST_SET_VIEWED_SUCCESS';
export const REQUEST_SET_VIEWED_FAILURE = 'REQUEST_SET_VIEWED_FAILURE';

export const REQUEST_SET_DEALT_REQUEST = 'REQUEST_SET_DEALT_REQUEST';
export const REQUEST_SET_DEALT_SUCCESS = 'REQUEST_SET_DEALT_SUCCESS';
export const REQUEST_SET_DEALT_FAILURE = 'REQUEST_SET_DEALT_FAILURE';

export const REQUEST_LOAD_COMMENTAIRES_REQUEST = 'REQUEST_LOAD_COMMENTAIRES_REQUEST';
export const REQUEST_LOAD_COMMENTAIRES_SUCCESS = 'REQUEST_LOAD_COMMENTAIRES_SUCCESS';
export const REQUEST_LOAD_COMMENTAIRES_FAILURE = 'REQUEST_LOAD_COMMENTAIRES_FAILURE';

export const VIEW_LIST_REQUEST = 'VIEW_LIST_REQUEST';
export const VIEW_LIST_SUCCESS = 'VIEW_LIST_SUCCESS';
export const VIEW_LIST_FAILURE = 'VIEW_LIST_FAILURE';

export const VIEW_ADD_REQUEST = 'VIEW_ADD_REQUEST';
export const VIEW_ADD_SUCCESS = 'VIEW_ADD_SUCCESS';
export const VIEW_ADD_FAILURE = 'VIEW_ADD_FAILURE';

export const VIEW_EDIT_REQUEST = 'VIEW_EDIT_REQUEST';
export const VIEW_EDIT_SUCCESS = 'VIEW_EDIT_SUCCESS';
export const VIEW_EDIT_FAILURE = 'VIEW_EDIT_FAILURE';

export const VIEW_DELETE_REQUEST = 'VIEW_DELETE_REQUEST';
export const VIEW_DELETE_SUCCESS = 'VIEW_DELETE_SUCCESS';
export const VIEW_DELETE_FAILURE = 'VIEW_DELETE_FAILURE';

export const VIEW_ACTIVATE_REQUEST = 'VIEW_ACTIVATE_REQUEST';
export const VIEW_ACTIVATE_SUCCESS = 'VIEW_ACTIVATE_SUCCESS';
export const VIEW_ACTIVATE_FAILURE = 'VIEW_ACTIVATE_FAILURE';

export const VIEW_DEACTIVATE_REQUEST = 'VIEW_DEACTIVATE_REQUEST';
export const VIEW_DEACTIVATE_SUCCESS = 'VIEW_DEACTIVATE_SUCCESS';
export const VIEW_DEACTIVATE_FAILURE = 'VIEW_DEACTIVATE_FAILURE';

export const RAPPEL_LIST_REQUEST = 'RAPPEL_LIST_REQUEST';
export const RAPPEL_LIST_SUCCESS = 'RAPPEL_LIST_SUCCESS';
export const RAPPEL_LIST_FAILURE = 'RAPPEL_LIST_FAILURE';

export const RAPPEL_LIST_USER_REQUEST = 'RAPPEL_LIST_USER_REQUEST';
export const RAPPEL_LIST_USER_SUCCESS = 'RAPPEL_LIST_USER_SUCCESS';
export const RAPPEL_LIST_USER_FAILURE = 'RAPPEL_LIST_USER_FAILURE';

export const RAPPEL_ADD_REQUEST = 'RAPPEL_ADD_REQUEST';
export const RAPPEL_ADD_SUCCESS = 'RAPPEL_ADD_SUCCESS';
export const RAPPEL_ADD_FAILURE = 'RAPPEL_ADD_FAILURE';

export const RAPPEL_EDIT_REQUEST = 'RAPPEL_EDIT_REQUEST';
export const RAPPEL_EDIT_SUCCESS = 'RAPPEL_EDIT_SUCCESS';
export const RAPPEL_EDIT_FAILURE = 'RAPPEL_EDIT_FAILURE';

export const RAPPEL_DELETE_REQUEST = 'RAPPEL_DELETE_REQUEST';
export const RAPPEL_DELETE_SUCCESS = 'RAPPEL_DELETE_SUCCESS';
export const RAPPEL_DELETE_FAILURE = 'RAPPEL_DELETE_FAILURE';

export const REQUEST_SET_VISITE_REQUEST = 'REQUEST_SET_VISITE_REQUEST';
export const REQUEST_SET_VISITE_SUCCESS = 'REQUEST_SET_VISITE_SUCCESS';
export const REQUEST_SET_VISITE_FAILURE = 'REQUEST_SET_VISITE_FAILURE';
