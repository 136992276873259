import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import BtnDeleteRappel from '../../Btn/DeleteRappel';

const Table = styled.table`
  margin-bottom: 20px;
  width: 50%;
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const Th = styled.th`
  padding: 10px;
  height: 52px;
  font-family: 'Futura';
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  background: ${props => props.theme.colors.backgroundLight};
  border-bottom: 1px solid ${props => props.theme.colors.inputBorderLight};
`;

const Td = styled.td`
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  vertical-align: top;
`;

const DateInfo = styled.div`
  white-space: nowrap;
`;
const AuthorInfo = styled.div`
  white-space: nowrap;
`;

const InfoNotice = styled.div`
  width: 50%;
  taxt-align: center;
`;

const RappelList = ({ tRappel, iPKUtilisateur }) =>
  tRappel.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <Th>Date</Th>
          <Th>Auteur</Th>
          <Th>Commentaire</Th>
          <Th>Action</Th>
        </tr>
      </thead>
      <tbody>
        {tRappel.map(rappel => (
          <tr key={rappel.iPKRappel}>
            <Td>
              <DateInfo>{`Le ${DateTime.fromMillis(parseInt(rappel.dDate, 10)).toFormat(
                'dd/LL/yyyy à HH:mm'
              )}`}</DateInfo>
            </Td>
            <Td>
              <AuthorInfo>{`${rappel.Utilisateur.sPrenom[0]}. ${
                rappel.Utilisateur.sNom
              }`}</AuthorInfo>
            </Td>
            <Td>{rappel.sCommentaire}</Td>
            <Td>
              {rappel.iFKUtilisateur === iPKUtilisateur && (
                <BtnDeleteRappel iPKRappel={rappel.iPKRappel} />
              )}
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <InfoNotice>Il n&apos;y a pas de rappel pour le moment.</InfoNotice>
  );

RappelList.propTypes = {
  tRappel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  tRappel: state.rappel.all,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(RappelList);
