import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.h1`
  float: left;
  margin: 0 20px 0 0;
  font-size: 1.4em;
  color: ${props => props.theme.colors.purple};
`;

const H1 = ({ children }) => {
  return <Title>{children}</Title>;
};

H1.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default H1;
