import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Icon, Popup, Loader } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { loadDelais } from '../../../../../../../actions/request';

import { getDelaiBackground, getDelaiBorderColor, getDelaiColor } from '../../../../../../../utils';

const Echeance = styled.div`
  float: right;
  position: relative;
  padding-right: 30px;
`;

const EcheanceIcon = styled(Icon)`
  position: absolute;
  bottom: 5px;
  right: 0;
`;

const EcheanceBubbleTrigger = styled.div``;

const EcheanceBubble = styled.div`
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 36px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  color: ${props => getDelaiColor(props.value, props.theme.colors)};
  background: ${props => getDelaiBackground(props.value, props.theme.colors)};
  border: 2px solid ${props => getDelaiBorderColor(props.value, props.theme.colors)};
  border-radius: 50%;
  overflow: hidden;
`;

const Historique = styled.ul`
  margin: 0;
  padding: 0;
`;

const Modification = styled.li`
  margin: 5px 0;
  padding: 7px 0 0;
  list-style: none;
  border-top: 1px solid ${props => props.theme.colors.borderLight};
`;

const ModificationContent = styled.dl`
  margin: 0;
  padding: 0;
`;

const ModificationDate = styled.dt`
  float: left;
  width: 65px;
`;

const ModificationDetail = styled.dd`
  margin: 0 0 0 75px;
`;

const ModificationMotif = styled.div`
  padding: 5px 0;
`;

class Delai extends Component {
  constructor(props) {
    super(props);

    const { historique } = this.props;

    this.state = {
      historique
    };
  }

  componentDidUpdate(prevProps) {
    const { historique } = this.props;

    if (prevProps.historique !== historique) {
      this.setState({ historique });
    }
  }

  onMouseOver = () => {
    const { dispatch, iPKDemande } = this.props;

    dispatch(loadDelais(iPKDemande));
  };

  render() {
    const { dDispoPatient, iDelai, bModifie } = this.props;
    const { historique } = this.state;
    const relativeDelai =
      Math.ceil(
        DateTime.fromMillis(parseInt(dDispoPatient, 10))
          .diff(DateTime.local().startOf('day'), ['days'])
          .toObject().days
      ) + iDelai;

    return (
      <Echeance>
        {bModifie ? (
          <Popup
            trigger={
              <EcheanceBubbleTrigger onMouseOver={this.onMouseOver} onFocus={this.onMouseOver}>
                <EcheanceBubble value={relativeDelai}>{relativeDelai}</EcheanceBubble>
                <EcheanceIcon name="edit outline" />
              </EcheanceBubbleTrigger>
            }
            header="Historique des modifications"
            content={
              historique ? (
                <Historique>
                  {historique.map(delai => (
                    <Modification key={delai.iPKDelai}>
                      <ModificationContent>
                        <ModificationDate>
                          {DateTime.fromMillis(parseInt(delai.dDate, 10)).toFormat('dd/LL/yyyy')} :
                        </ModificationDate>
                        <ModificationDetail>
                          Délai :{' '}
                          {
                            DateTime.fromMillis(parseInt(delai.dLimite, 10))
                              .startOf('day')
                              .diff(
                                DateTime.fromMillis(parseInt(delai.dDispoPatient, 10)).startOf(
                                  'day'
                                ),
                                ['days']
                              )
                              .toObject().days
                          }
                          <ModificationMotif>{delai.sCommentaire}</ModificationMotif>
                          Par {delai.sUtilisateur}
                        </ModificationDetail>
                      </ModificationContent>
                    </Modification>
                  ))}
                </Historique>
              ) : (
                <Loader active>Chargement de l&apos;historique</Loader>
              )
            }
          />
        ) : (
          <EcheanceBubble value={relativeDelai}>{relativeDelai}</EcheanceBubble>
        )}
      </Echeance>
    );
  }
}

Delai.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKDemande: PropTypes.number.isRequired,
  iDelai: PropTypes.number.isRequired,
  dDispoPatient: PropTypes.string.isRequired,
  bModifie: PropTypes.bool.isRequired,
  historique: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  )
};

Delai.defaultProps = {
  historique: []
};

export default connect(null)(Delai);
