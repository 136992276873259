import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getBackgroundColor = (selected, active, theme) => {
  if (selected) {
    return 'transparent';
  }

  if (active) {
    return theme.colors.backgroundLight;
  }

  return 'transparent';
};

const RequestTableCol = styled.td`
  position: relative;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  background: ${props => getBackgroundColor(props.selected, props.active, props.theme)};
  cursor: pointer;
`;

const Col = ({ children, active, selected }) => (
  <RequestTableCol active={active} selected={selected}>
    {children}
  </RequestTableCol>
);

Col.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  active: PropTypes.bool,
  selected: PropTypes.bool.isRequired
};

Col.defaultProps = {
  active: false
};

export default Col;
