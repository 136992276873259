import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setCouleur } from '../../../../actions/request';

const StyledBtnSaveCouleur = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnSaveCouleur extends Component {
  onClick = evt => {
    const { onClick, dispatch, iPKDemande, sCouleur } = this.props;

    evt.preventDefault();

    onClick();

    dispatch(setCouleur(iPKDemande, sCouleur));
  };

  render() {
    return <StyledBtnSaveCouleur onClick={this.onClick}>Modifier la couleur</StyledBtnSaveCouleur>;
  }
}

BtnSaveCouleur.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.string.isRequired,
  sCouleur: PropTypes.string.isRequired
};

export default connect(null)(BtnSaveCouleur);
