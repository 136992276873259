import { put } from 'redux-saga/effects';

import { NETWORK_ONLINE_SUCCESS, NETWORK_OFFLINE_SUCCESS } from '../constants/ActionTypes';

export function* setOnlineMode() {
  yield put({ type: NETWORK_ONLINE_SUCCESS, payload: { offline: false } });
}

export function* setOfflineMode() {
  yield put({ type: NETWORK_OFFLINE_SUCCESS, payload: { offline: true } });
}
