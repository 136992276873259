import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAddress = styled.div`
  line-height: 18px;
`;

const Address = ({ address }) => {
  return <StyledAddress>{address}</StyledAddress>;
};

Address.propTypes = {
  address: PropTypes.string.isRequired
};

export default Address;
