import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { fetchApollo, mutateApollo } from '../apolloClient';

import {
  RAPPEL_LIST_SUCCESS,
  RAPPEL_LIST_FAILURE,
  RAPPEL_ADD_SUCCESS,
  RAPPEL_ADD_FAILURE,
  RAPPEL_DELETE_SUCCESS,
  RAPPEL_DELETE_FAILURE,
  RAPPEL_LIST_USER_SUCCESS,
  RAPPEL_LIST_USER_FAILURE
} from '../constants/ActionTypes';

export function* listRappels({ payload: { iPKUtilisateur } }) {
  if (!navigator.onLine) {
    alert('TODO: RAPPEL_LIST_REQUEST Offline');
  } else {
    try {
      const LIST_RAPPEL = gql`
        {
          listeRappels {
            iPKRappel
            iFKDemande
            iFKUtilisateur
            sCommentaire
            dDate
            Demande {
              sNature
            }
            Utilisateur {
              sNom
              sPrenom
            }
          }
        }
      `;
      const response = yield call(fetchApollo, LIST_RAPPEL);
      const tRappel = response.data.listeRappels;

      if (tRappel !== null) {
        yield put({ type: RAPPEL_LIST_SUCCESS, payload: { tRappel, iPKUtilisateur } });
      } else {
        yield put({ type: RAPPEL_LIST_FAILURE, payload: 'Erreur de chargement des rappels' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des rappels';
          break;
        default:
          break;
      }

      yield put({ type: RAPPEL_LIST_FAILURE, payload: message });
    }
  }
}

export function* listUserRappels({ payload: { iPKUtilisateur } }) {
  if (!navigator.onLine) {
    alert('TODO: RAPPEL_LIST_REQUEST Offline');
  } else {
    try {
      const LIST_RAPPEL = gql`
        {
          listeRappelsUtilisateur(iPKUtilisateur: ${iPKUtilisateur}) {
            iPKRappel
            iFKDemande
            iFKUtilisateur
            sCommentaire
            dDate
            Demande {
              sNature
            }
            Utilisateur {
              sNom
              sPrenom
            }
          }
        }
      `;
      const response = yield call(fetchApollo, LIST_RAPPEL);
      const tRappel = response.data.listeRappelsUtilisateur;

      if (tRappel !== null) {
        yield put({ type: RAPPEL_LIST_USER_SUCCESS, payload: tRappel });
      } else {
        yield put({ type: RAPPEL_LIST_USER_FAILURE, payload: 'Erreur de chargement des rappels' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des rappels';
          break;
        default:
          break;
      }

      yield put({ type: RAPPEL_LIST_FAILURE, payload: message });
    }
  }
}

export function* addRappel({ payload: { iPKUtilisateur, iFKDemande, dDate, sCommentaire } }) {
  if (!navigator.onLine) {
    alert('TODO: RAPPEL_ADD_REQUEST Offline');
  } else {
    try {
      const ADD_RAPPEL = gql`
          mutation {
            ajouteRappel(iFKUtilisateur: ${iPKUtilisateur}, iFKDemande: ${iFKDemande}, dDate: "${dDate}", sCommentaire: "${sCommentaire}") {
              iPKRappel
              iFKDemande
              iFKUtilisateur
              sCommentaire
              dDate
              Demande {
                sNature
              }
              Utilisateur {
                sNom
                sPrenom
              }
            }
          }
        `;
      const response = yield call(mutateApollo, ADD_RAPPEL);
      const rappel = response.data.ajouteRappel;

      if (rappel !== null) {
        yield put({ type: RAPPEL_ADD_SUCCESS, payload: rappel });
      } else {
        yield put({ type: RAPPEL_ADD_FAILURE, payload: 'Erreur de création du rappel' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de création du rappel';
          break;
        default:
          break;
      }

      yield put({ type: RAPPEL_ADD_FAILURE, payload: message });
    }
  }
}

export function* deleteRappel({ payload: { iPKRappel } }) {
  if (!navigator.onLine) {
    alert('TODO: RAPPEL_DELETE_REQUEST Offline');
  } else {
    try {
      const DELETE_RAPPEL = gql`
          mutation {
            supprimeRappel(iPKRappel: ${iPKRappel}) {
              iResultat
            }
          }
        `;
      const response = yield call(mutateApollo, DELETE_RAPPEL);
      const resultat = response.data.supprimeRappel;

      if (resultat.iResultat === 1) {
        yield put({ type: RAPPEL_DELETE_SUCCESS, payload: { iPKRappel } });
      } else {
        yield put({ type: RAPPEL_DELETE_FAILURE, payload: 'Erreur de suppression du rappel' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de suppression du rappel';
          break;
        default:
          break;
      }

      yield put({ type: RAPPEL_DELETE_FAILURE, payload: message });
    }
  }
}
