import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

const ISADOMLink = styled.a`
  display: inline-block;
  margin: 0 0 0 10px;
`;

const IconWrapper = ({ bVue, children }) => (
  <StyledIconWrapper bVue={bVue}>{children}</StyledIconWrapper>
);

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  bVue: PropTypes.number
};

IconWrapper.defaultProps = {
  bVue: 0
};

const StyledIconWrapper = styled.div`
  color: ${props =>
    props.bVue === 1 ? props.theme.colors.textBlack : props.theme.colors.blueAGIR};
`;

const StyledIcon = styled(Icon)`
  transition: color 0.25s ease-in-out;
  color: inherit;

  &:hover {
    color: ${props => props.theme.colors.textBlack} !important;
  }
`;

export default function CodeAnnuaire({ iPKAnnuaire, bVue }) {
  return (
    <ISADOMLink
      href="#"
      onClick={evt => {
        evt.stopPropagation();
        evt.preventDefault();
        const windowRef = window.open(`isadomlink://annuaire.${iPKAnnuaire}`, 'isalink');
        windowRef.blur();
        windowRef.close();
      }}
      title="Ouvrir la fiche Patient dans ISADOM"
    >
      <IconWrapper bVue={bVue}>
        <StyledIcon name="external alternate" />
      </IconWrapper>
    </ISADOMLink>
  );
}

CodeAnnuaire.propTypes = {
  iPKAnnuaire: PropTypes.number.isRequired,
  bVue: PropTypes.number
};

CodeAnnuaire.defaultProps = {
  bVue: 0
};
