import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BtnDisabled = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => (props.modified ? props.theme.colors.blue : props.theme.colors.grey)};
  border: 0;
  cursor: default;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props =>
      props.modified ? props.theme.colors.blueDark : props.theme.colors.grey};
  }
`;

const Disabled = ({ className, label, modified }) => {
  return (
    <BtnDisabled className={className} modified={modified} disabled>
      {label}
    </BtnDisabled>
  );
};

Disabled.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  modified: PropTypes.bool
};

Disabled.defaultProps = {
  modified: false,
  className: ''
};

export default Disabled;
