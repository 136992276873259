import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form, Input, TextArea } from 'semantic-ui-react';
import { DatePickerInput } from 'rc-datepicker';
import { DateTime } from 'luxon';
import '../../../../../../../../assets/css/datepicker.css';
import 'moment/locale/fr';

import BtnSaveDelai from '../../../../../Btn/SaveDelai';
import BtnCancelSaveDelai from '../../../../../Btn/CancelSaveDelai';
import BtnDisabled from '../../../../../Btn/Disabled';

import InfoEdit from './Info/Edit';
import Label from './Info/Label';
import Detail from './Info/Detail';
import Actions from './Info/Actions';
import Value from './Info/Value';

const Info = styled.div`
  display: flex;
  margin: 0 0 10px;
  width: 100%;
  padding: 0 0 0 15px;
`;

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const DateSouhaitee = styled.div`
  padding-top: 5px;
  color: ${props => (props.diff > 0 ? props.theme.colors.purple : props.theme.colors.greenDark)};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

const ErrorNotice = styled.div`
  padding: 10px 0 0;
  color: ${props => props.theme.colors.purple};
`;

class Delai extends Component {
  constructor(props) {
    super(props);

    const { request } = this.props;
    const { dDispoPatient, iDelai } = request;

    this.state = {
      edited: false,
      initialDelai: iDelai,
      initialDateDispo: dDispoPatient
        ? DateTime.fromMillis(parseInt(dDispoPatient, 10))
        : DateTime.local(),
      initialDateLimite: dDispoPatient
        ? DateTime.fromMillis(parseInt(dDispoPatient, 10)).plus({ days: parseInt(iDelai, 10) })
        : DateTime.local().plus({ days: parseInt(iDelai, 10) }),
      delai: iDelai,
      motif: '',
      dateDispo: dDispoPatient
        ? DateTime.fromMillis(parseInt(dDispoPatient, 10))
        : DateTime.local(),
      dateLimite: dDispoPatient
        ? DateTime.fromMillis(parseInt(dDispoPatient, 10)).plus({ days: parseInt(iDelai, 10) })
        : DateTime.local().plus({ days: parseInt(iDelai, 10) }),
      errors: {
        dateDispo: false,
        dateLimite: false,
        delais: false,
        motif: false
      }
    };
  }

  onDelaiEditBtnClick = evt => {
    evt.preventDefault();

    this.setState({ edited: true });
  };

  onDateDispoChange = date => {
    const newDate = DateTime.fromJSDate(date);
    const { errors } = this.state;

    const newErrors = Object.assign({}, errors);

    if (newDate.isValid) {
      newErrors.dateDispo = false;

      const { request } = this.props;

      if (request.dSouhaitRealisation !== null) {
        this.setState(() => ({
          dateDispo: newDate,
          delai: request.iDelaiOrigine,
          dateLimite: newDate.plus({ days: parseInt(request.iDelaiOrigine, 10) }),
          errors: newErrors
        }));
      } else {
        this.setState(prevState => ({
          dateDispo: newDate,
          dateLimite: newDate.plus({ days: parseInt(prevState.delai, 10) }),
          errors: newErrors
        }));
      }
    } else {
      newErrors.dateDispo = true;

      this.setState({ errors: newErrors });
    }
  };

  onDateLimiteChange = date => {
    const newDate = DateTime.fromJSDate(date);
    const { errors } = this.state;

    const newErrors = Object.assign({}, errors);

    if (newDate.isValid) {
      newErrors.dateLimite = false;

      this.setState(prevState => ({
        dateLimite: newDate,
        delai: Math.floor(newDate.diff(prevState.dateDispo, ['days']).toObject().days),
        errors: newErrors
      }));
    } else {
      newErrors.dateLimite = true;

      this.setState({ errors: newErrors });
    }
  };

  onDelaiChange = (evt, data) => {
    const { value } = data;
    const { errors } = this.state;

    const newErrors = Object.assign({}, errors);

    if (value.length > 0) {
      if (value && /^\d+$/.test(value)) {
        newErrors.delai = false;

        this.setState(prevState => ({
          delai: value,
          dateLimite: prevState.dateDispo.plus({ days: parseInt(value, 10) }),
          errors: newErrors
        }));
      } else {
        newErrors.delai = true;

        this.setState({ errors: newErrors });
      }
    } else {
      newErrors.delai = true;

      this.setState({ delai: parseInt(value, 10), errors: newErrors });
    }
  };

  onMotifChange = (evt, data) => {
    const { value } = data;
    const { errors } = this.state;

    const newErrors = Object.assign({}, errors);

    if (value.length === 0) {
      newErrors.motif = true;
    } else {
      newErrors.motif = false;
    }

    this.setState({ motif: value, errors: newErrors });
  };

  onBtnCancelSaveDelaiClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({
      edited: false,
      delai: prevState.initialDelai,
      motif: '',
      dateDispo: prevState.initialDateDispo,
      dateLimite: prevState.initialDateLimite,
      errors: {}
    }));
  };

  onBtnSaveDelaiClick = () => {
    const { dateDispo, dateLimite, motif, delai } = this.state;

    if (dateDispo.isValid && (dateLimite.isValid || delai > 0) && motif.length > 0) {
      this.setState({ edited: false });
    } else {
      const errors = {
        dateDispo: false,
        dateLimite: false,
        delais: false,
        motif: false
      };

      if (!dateDispo.isValid) {
        errors.dateDispo = true;
      }

      if (!dateLimite.isValid) {
        errors.dateLimite = true;
      }

      if (delai < 0) {
        errors.delai = true;
      }

      if (motif.length <= 0) {
        errors.motif = true;
      }

      this.setState({ errors });
    }
  };

  render() {
    const { edited, dateDispo, dateLimite, delai, motif, errors } = this.state;
    const { label, iPKDemande, request } = this.props;

    return edited ? (
      <InfoEdit>
        <Form>
          <Form.Field>
            <FormLabel htmlFor="dispo" error={errors.dateDispo}>
              Date de disponibilité du patient :<Mandatory>*</Mandatory>
            </FormLabel>
            <DatePickerInput
              onChange={this.onDateDispoChange}
              value={dateDispo ? dateDispo.toISODate() : null}
              id="dispo"
              locale="fr"
              displayFormat="DD/MM/YYYY"
              minDate={new Date()}
            />
          </Form.Field>
          <Form.Field>
            <FormLabel htmlFor="limite" error={errors.dateLimite}>
              Date limite pour réaliser l&apos;intervention :<Mandatory>*</Mandatory>
            </FormLabel>
            <DatePickerInput
              onChange={this.onDateLimiteChange}
              value={dateLimite ? dateLimite.toISODate() : null}
              id="limite"
              locale="fr"
              displayFormat="DD/MM/YYYY"
              minDate={dateDispo ? dateDispo.toISODate() : new Date()}
            />

            {request.dSouhaitRealisation && (
              <DateSouhaitee
                diff={
                  dateLimite
                    ? dateLimite
                        .endOf('day')
                        .diff(
                          DateTime.fromMillis(parseInt(request.dSouhaitRealisation, 10)).endOf(
                            'day'
                          ),
                          'seconds'
                        )
                        .toObject().seconds
                    : 0
                }
              >
                Date souhaitée :{' '}
                {DateTime.fromMillis(parseInt(request.dSouhaitRealisation, 10)).toFormat(
                  'dd/LL/yyyy'
                )}
              </DateSouhaitee>
            )}
          </Form.Field>
          <Form.Field
            id="delai"
            control={Input}
            label={
              <FormLabel htmlFor="delai" error={errors.delai}>
                Nouveau délai :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            placeholder="Nb de jours"
            value={delai}
            onChange={this.onDelaiChange}
          />
          <Form.Field
            id="motif"
            control={TextArea}
            label={
              <FormLabel htmlFor="motif" error={errors.motif}>
                Motif :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            placeholder="Motif du nouveau délai"
            value={motif}
            onChange={this.onMotifChange}
          />
        </Form>

        <Actions>
          <BtnCancelSaveDelai onClick={this.onBtnCancelSaveDelaiClick} />
          {motif.length > 0 ? (
            <BtnSaveDelai
              onClick={this.onBtnSaveDelaiClick}
              iPKDemande={iPKDemande}
              dDispoPatient={dateDispo}
              dLimite={dateLimite}
              sCommentaire={motif}
            />
          ) : (
            <BtnDisabled label="Modifier le délai" />
          )}
        </Actions>
        {Object.keys(errors).some(key => errors[key]) && (
          <ErrorNotice>
            Certaines informations requises manquent ou sont incorrectes. Merci de vérifier vos
            saisies.
          </ErrorNotice>
        )}
      </InfoEdit>
    ) : (
      <Info>
        <Label wide>{label}&nbsp;:</Label>
        <Detail wide onClick={this.onDelaiEditBtnClick}>
          <Value value={request.iDelai} />
        </Detail>
      </Info>
    );
  }
}

Delai.propTypes = {
  iPKDemande: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  request: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Delai;
