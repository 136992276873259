import {
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT_SUCCESS,
  USER_SIGNOUT_FAILURE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE
} from '../constants/ActionTypes';

const initialAuthState = {
  isAuthenticated: false,
  data: {
    iPKUtilisateur: 0,
    iFKAnnuaire: 0,
    iRole: 3,
    sCodeCivilite: '',
    sNom: 'Invité',
    sPrenom: 'Invité',
    iFKAntenne: 0,
    tAntenne: [],
    tNature: [],
    tPRestation: [],
    date: new Date()
  },
  error: null
};

export default (state = initialAuthState, { type, payload }) => {
  switch (type) {
    case USER_SIGNIN_SUCCESS: {
      const data = {
        iPKUtilisateur: payload.iPKUtilisateur,
        iFKAnnuaire: payload.iFKAnnuaire,
        iRole: payload.iRole,
        sCodeCivilite: payload.sCodeCivilite || '',
        sNom: payload.sNom || '',
        sPrenom: payload.sPrenom || '',
        iFKAntenne: payload.iFKAntenne || 0,
        tAntenne: payload.tAntenne ? JSON.parse(payload.tAntenne) : [],
        tNature: payload.tNature ? JSON.parse(payload.tNature) : [],
        tPrestation: payload.tPrestation ? JSON.parse(payload.tPrestation) : [],
        date: payload.dDate
      };
      return { ...state, isAuthenticated: true, data, error: null };
    }
    case USER_SIGNIN_FAILURE: {
      return { ...initialAuthState, error: payload };
    }
    case USER_SIGNOUT_SUCCESS: {
      return {
        isAuthenticated: false,
        data: { id: 0, name: 'GUEST', short: 'GUEST', date: new Date() },
        error: null,
        profile: payload
      };
    }
    case USER_SIGNOUT_FAILURE: {
      return { ...initialAuthState, error: payload };
    }

    case USER_UPDATE_SUCCESS: {
      const { data } = state;
      return {
        ...state,
        data: {
          ...data,
          tAntenne: payload.tAntenne,
          tNature: payload.tNature,
          tPrestation: payload.tPrestation
        }
      };
    }
    case USER_UPDATE_FAILURE: {
      return { ...state, error: payload };
    }

    default:
      return state;
  }
};
