import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { restoreRequest } from '../../../../actions/request';

const StyledBtnRestoreRequest = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnRestoreRequest extends Component {
  onClick = evt => {
    const { dispatch, iPKDemande, iPKUtilisateur } = this.props;

    evt.preventDefault();

    dispatch(restoreRequest(iPKUtilisateur, iPKDemande));
  };

  render() {
    return (
      <StyledBtnRestoreRequest onClick={this.onClick}>Restaurer la demande</StyledBtnRestoreRequest>
    );
  }
}

BtnRestoreRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKDemande: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  iPKDemande: state.request.locked.iFKDemande,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(BtnRestoreRequest);
