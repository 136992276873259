import React, { Component } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Form, Select } from 'semantic-ui-react';

import BtnCancelSaveNature from '../../../../../Btn/CancelSaveNature';
import BtnDisabled from '../../../../../Btn/Disabled';
import BtnSaveNature from '../../../../../Btn/SaveNature';

import Actions from './Info/Actions';
import Detail from './Info/Detail';
import InfoEdit from './Info/Edit';
import Label from './Info/Label';
import Value from './Info/Value';

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Info = styled.div`
  display: flex;
  margin: 0 0 10px;
  width: 100%;
  padding: 0 0 0 15px;
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

class Nature extends Component {
  constructor(props) {
    super(props);

    const { sNature, tPrecision } = this.props;

    this.tPrecisionOptions = [
      { value: null },
      ...tPrecision.map(p => ({
        key: p.sCodePrecisionEvt,
        value: p.sCodePrecisionEvt,
        text: p.sCodePrecisionEvt
      }))
    ];

    if (sNature === 'DI') this.tPrecisionOptions.shift();

    this.state = {
      sNature,
      sNatureInitiale: sNature,
      edited: false
    };
  }

  componentDidUpdate(prevProps) {
    const { sNature } = this.props;
    if (prevProps.sNature !== sNature) {
      this.setState({ sNature, sNatureInitiale: sNature });
    }
  }

  handleEdition = evt => {
    evt.preventDefault();
    this.setState({ edited: true });
  };

  handleNatureChange = (evt, { value }) => {
    evt.preventDefault();
    const { sNatureInitiale } = this.state;
    this.setState({ edited: sNatureInitiale !== value, sNature: value || 'DI' });
  };

  handleUpdate = () => {
    this.setState(prevState => ({ edited: false, sNature: prevState.sNature }));
  };

  handleUpdateCancellation = evt => {
    evt.preventDefault();
    this.setState(prevState => ({ edited: false, sNature: prevState.sNatureInitiale }));
  };

  render() {
    const { edited, sNature, sNatureInitiale } = this.state;
    const { iPKDemande, label } = this.props;

    return edited ? (
      <InfoEdit>
        <Form onSubmit={this.handleNatureChange}>
          <Form.Field
            control={Select}
            id="sNature"
            label={
              <FormLabel htmlFor="sNature">
                Nouvelle nature :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            onChange={this.handleNatureChange}
            options={this.tPrecisionOptions}
            placeholder="Toutes les natures"
            value={sNature}
          />
        </Form>

        <Actions>
          <BtnCancelSaveNature onClick={this.handleUpdateCancellation} />
          {sNature !== sNatureInitiale
            ? <BtnSaveNature iPKDemande={iPKDemande} onClick={this.handleUpdate} sNature={sNature} />
            : <BtnDisabled label="Modifier la nature" />
          }
        </Actions>
      </InfoEdit>
    ) : (
        <Info>
          <Label wide>{label}&nbsp;:</Label>
          <Detail wide onClick={this.handleEdition}>
            <Value value={sNature} />
          </Detail>
        </Info>
      );
  }
}

Nature.propTypes = {
  iPKDemande: number.isRequired,
  label: string.isRequired,
  sNature: string.isRequired,
  tPrecision: arrayOf(shape({
    sCodePrecisionEvt: string.isRequired,
    sPKTypeEvt: string.isRequired
  })).isRequired
};

const mapStateToProps = state => ({
  tPrecision: state.config.tPrecision.filter(p => p.sPKTypeEvt === 'DI')
});

export default connect(mapStateToProps)(Nature);
