import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Popup } from 'semantic-ui-react';

import Date from './Date';
import Nature from './Nature';
import Intervenant from './Intervenant';

const Appointment = ({ tRendezVous }) =>
  tRendezVous.map(rdv => {
    const chunks = rdv.dRdv.split('-');
    const dRdv = DateTime.local().set({
      year: chunks[0],
      month: chunks[1],
      day: chunks[2]
    });

    return (
      <Popup
        key={`${rdv.dRdv}-${rdv.sCodeIntervenant}-${rdv.sTypeRdvCode}`}
        trigger={<div>{`${dRdv.toFormat('dd/LL')} ${rdv.sTypeRdvCode}`}</div>}
        content={
          <>
            <Date>Le {dRdv.toFormat('dd/LL/yy')}</Date>
            <Nature>{rdv.sTypeRdvLibelle}</Nature>
            <Intervenant>{rdv.sIntervenantComplet}</Intervenant>
          </>
        }
      />
    );
  });

Appointment.propTypes = {
  tRendezVous: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired
};

export default Appointment;
