import { NETWORK_OFFLINE_SUCCESS, NETWORK_ONLINE_SUCCESS } from '../constants/ActionTypes';

const initialNetworkState = {
  offline: false
};

export default (state = initialNetworkState, { type }) => {
  switch (type) {
    case NETWORK_OFFLINE_SUCCESS: {
      return { ...state, offline: true };
    }

    case NETWORK_ONLINE_SUCCESS: {
      return { ...state, offline: false };
    }

    default:
      return state;
  }
};
