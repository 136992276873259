import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setAntenne } from '../../../../actions/request';
import { listViews } from '../../../../actions/view';

const StyledBtnSaveAntenne = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnSaveAntenne extends Component {
  onClick = evt => {
    const { onClick, dispatch, iPKDemande, iFKAntenne, iPKUtilisateur } = this.props;

    evt.preventDefault();

    onClick();

    dispatch(setAntenne(iPKDemande, iFKAntenne, iPKUtilisateur));
  };

  render() {
    return (
      <StyledBtnSaveAntenne onClick={this.onClick}>Modifier l&apos;Antenne</StyledBtnSaveAntenne>
    );
  }
}

BtnSaveAntenne.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.string.isRequired,
  iFKAntenne: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(BtnSaveAntenne);
