import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setNature } from '../../../../actions/request';

const StyledBtnSaveNature = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnSaveNature extends Component {
  onClick = evt => {
    evt.preventDefault();
    const { dispatch, iPKDemande, onClick, sNature } = this.props;
    onClick();
    dispatch(setNature(iPKDemande, sNature));
  };

  render() {
    return (
      <StyledBtnSaveNature onClick={this.onClick}>Modifier la nature</StyledBtnSaveNature>
    );
  }
}

BtnSaveNature.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKDemande: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  sNature: PropTypes.string.isRequired
};

export default connect(null)(BtnSaveNature);
