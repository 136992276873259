import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BtnSubmit = styled.button`
  padding: 0 20px;
  width: 100%;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => (props.modified ? props.theme.colors.blue : props.theme.colors.grey)};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props =>
      props.modified ? props.theme.colors.blueDark : props.theme.colors.grey};
  }

  &:disabled {
    cursor: default;
  }
`;

const Submit = ({ className, label, modified, disabled, onClick }) => {
  return (
    <BtnSubmit className={className} modified={modified} disabled={disabled} onClick={onClick}>
      {label}
    </BtnSubmit>
  );
};

Submit.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  modified: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Submit.defaultProps = {
  className: '',
  disabled: false
};

export default Submit;
