import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styled from 'styled-components';

import { deleteView, activateView, listViews } from '../../../../../actions/view';
import { orderRequests } from '../../../../../actions/request';

import ViewItem from './Item';

const StyledSystemViewList = styled.ul`
  display: inline-block;
  margin: -10px 0 0;
  padding: 0;
`;

const StyledViewList = styled.ul`
  position: absolute;
  bottom: 22px;
  margin: 10px 0 0;
  padding: 0;
`;

class ViewList extends Component {
  onDefaultViewClick = evt => {
    evt.preventDefault();

    const { dispatch, selected, iPKUtilisateur, tAntenne, tNature, tPrestation } = this.props;

    if (selected !== -1) {
      dispatch(activateView(-1, iPKUtilisateur, tAntenne, tNature, tPrestation));
      dispatch(listViews(iPKUtilisateur));
    }
  };

  onProgrammeViewClick = evt => {
    evt.preventDefault();

    const { dispatch, selected, iPKUtilisateur, tAntenne, tNature, tPrestation } = this.props;

    if (selected !== 0) {
      dispatch(activateView(0, iPKUtilisateur, tAntenne, tNature, tPrestation));
      dispatch(listViews(iPKUtilisateur));
    }
  };

  onClick = view => {
    const {
      dispatch,
      selected,
      iPKUtilisateur,
      tAntenne,
      tNature,
      tPrestation,
      oFilters
    } = this.props;

    if (selected !== view.iPKVue) {
      // dispatch(orderRequests(oFilters, { col: 'dLimite', dir: 'ASC' }, iPKUtilisateur));
      dispatch(activateView(view.iPKVue, iPKUtilisateur, tAntenne, tNature, tPrestation));
      dispatch(listViews(iPKUtilisateur));

      localStorage.setItem('scroll', 0);
    }
  };

  onClose = (evt, iPKVue) => {
    evt.preventDefault();
    const { dispatch, selected, iPKUtilisateur, tAntenne, tNature, tPrestation } = this.props;

    dispatch(deleteView(iPKVue, iPKUtilisateur));

    if (iPKVue === selected) {
      dispatch(activateView(-1, iPKUtilisateur, tAntenne, tNature, tPrestation));
    }

    dispatch(listViews(iPKUtilisateur));
  };

  render() {
    const { views, selected } = this.props;

    return (
      <>
        <StyledSystemViewList>
          {views.slice(0, 3).map(view => (
            <ViewItem
              key={view.iPKVue}
              id={view.iPKVue}
              label={view.sLabel}
              iCount={view.iCount}
              iNouvelles={view.iNouvelles}
              oFilters={JSON.parse(view.sFiltres)}
              selected={selected === view.iPKVue}
              onClose={this.onClose}
              onClick={evt => {
                evt.preventDefault();
                this.onClick(view);
              }}
            />
          ))}
        </StyledSystemViewList>
        <StyledViewList>
          {views.slice(3, views.length).map(view => (
            <ViewItem
              key={view.iPKVue}
              id={view.iPKVue}
              label={view.sLabel}
              iCount={view.iCount}
              iNouvelles={view.iNouvelles}
              oFilters={JSON.parse(view.sFiltres)}
              selected={selected === view.iPKVue}
              closable
              onClose={this.onClose}
              onClick={evt => {
                evt.preventDefault();
                this.onClick(view);
              }}
            />
          ))}
        </StyledViewList>
      </>
    );
  }
}

ViewList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))
  ).isRequired,
  selected: PropTypes.number.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  tAntenne: PropTypes.arrayOf(PropTypes.number).isRequired,
  tNature: PropTypes.arrayOf(PropTypes.string).isRequired,
  tPrestation: PropTypes.arrayOf(PropTypes.string).isRequired,
  oFilters: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  views: state.view.tVue || [],
  selected: state.view.selected,
  locked: state.request.locked.iFKDemande,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  tAntenne: state.user.data.tAntenne,
  tNature: state.user.data.tNature,
  tPrestation: state.user.data.tPrestation,
  oFilters: state.request.filters
});

export default connect(mapStateToProps)(ViewList);
