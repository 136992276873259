import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { deleteRappel } from '../../../../actions/rappel';

const StyledBtnDeleteRappel = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.purpleDark};
  }
`;

class BtnDeleteRappel extends Component {
  onClick = evt => {
    const { onBtnRappelCancelClick, iPKRappel } = this.props;

    evt.preventDefault();

    onBtnRappelCancelClick(iPKRappel);
  };

  render() {
    return <StyledBtnDeleteRappel onClick={this.onClick}>Supprimer</StyledBtnDeleteRappel>;
  }
}

BtnDeleteRappel.propTypes = {
  onBtnRappelCancelClick: PropTypes.func.isRequired,
  iPKRappel: PropTypes.number.isRequired
};

const mapDispatchToProps = dispatch => ({
  onBtnRappelCancelClick: iPKRappel => {
    dispatch(deleteRappel(iPKRappel));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(BtnDeleteRappel);
