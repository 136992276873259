import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.textBlack} !important;
`;

const getLockStatus = (locked, over, busy) => {
  if (busy) {
    return <StyledIcon name="ban" size="large" />;
  }

  if (locked) {
    return <StyledIcon name="lock" size="large" />;
  }

  if (over) {
    return <StyledIcon name="lock open" size="large" />;
  }

  return <NoLock />;
};

const NoLock = styled.div`
  width: 28.2656px;
`;

const Lock = ({ locked, over }) => getLockStatus(locked, over);

Lock.propTypes = {
  locked: PropTypes.bool.isRequired,
  busy: PropTypes.bool.isRequired,
  over: PropTypes.bool
};

Lock.defaultProps = {
  over: false
};

export default Lock;
