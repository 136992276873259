import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Form, TextArea, Icon } from 'semantic-ui-react';

import BtnSaveComment from '../../../../../Btn/SaveComment';
import BtnCancelSaveComment from '../../../../../Btn/CancelSaveComment';
import BtnDisabled from '../../../../../Btn/Disabled';

import InfoEdit from './Info/Edit';
import Actions from './Info/Actions';
import Value from './Info/Value';

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

const Info = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 20px 15px;
`;

const CommentInfo = styled.div`
  font-weight: bold;
`;

const StyledValue = styled(Value)`
  padding-right: 30px;
  white-space: pre-line;
`;

const HistoryBtn = styled.button`
  float: right;
  margin: 10px 0 0;
  padding: 0 0.25rem 0 0.5rem;
  height: 30px;
  line-height: 30px;
  font-family: 'Futura';
  text-align: left;
  color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.textBlack)};
  background: ${props => (props.active ? props.theme.colors.blue : props.theme.colors.grey)};
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
  }
`;

const HistoryBtnIcon = styled(Icon)`
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  cursor: pointer;
  color: inherit;
`;

const History = styled.div`
  position: relative;
  margin: 50px 0 0;
`;

const Archive = styled.div`
  margin: 0 0 5px;
  padding: 5px;
  background: ${props => props.theme.colors.backgroundLight};
`;

class Commentaire extends Component {
  constructor(props) {
    super(props);

    const { tCommentaire } = this.props;

    this.state = {
      sCommentaire: tCommentaire.length > 0 ? tCommentaire[0].sMessage : '',
      sCommentaireInitial: tCommentaire.length > 0 ? tCommentaire[0].sMessage : '',
      edited: false,
      modified: false,
      showHistory: false
    };
  }

  onCommentaireEditBtnClick = evt => {
    evt.preventDefault();
    this.setState({ edited: true });
  };

  onCommentaireFieldChange = (evt, data) => {
    evt.preventDefault();

    const { value } = data;

    const { sCommentaireInitial } = this.state;

    this.setState({ sCommentaire: value, modified: sCommentaireInitial !== value });
  };

  onBtnCancelSaveCommentaireClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ sCommentaire: prevState.sCommentaireInitial, edited: false }));
  };

  onBtnSaveCommentaireClick = () => {
    this.setState(prevStaate => ({ edited: false, sCommentaire: prevStaate.sCommentaireInitial }));
  };

  onHistoryBtnClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ showHistory: !prevState.showHistory }));
  };

  onBtnHistoryCloseClick = evt => {
    evt.preventDefault();

    this.setState({ showHistory: false });
  };

  render() {
    const { edited, sCommentaire, modified, showHistory } = this.state;
    const { iPKDemande, tCommentaire } = this.props;

    return edited ? (
      <InfoEdit>
        <Form onSubmit={this.onCommentaireFieldChange}>
          <Form.Field
            id="sCommentaire"
            label={
              <FormLabel htmlFor="sCommentaire">
                Nouveau commentaire :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            control={TextArea}
            rows={12}
            onChange={this.onCommentaireFieldChange}
            value={sCommentaire}
            placeholder="Entrez votre commentaire"
          />
        </Form>

        <Actions>
          <BtnCancelSaveComment onClick={this.onBtnCancelSaveCommentaireClick} />
          {modified ? (
            <BtnSaveComment
              onClick={this.onBtnSaveCommentaireClick}
              iPKDemande={iPKDemande}
              sCommentaire={sCommentaire}
            />
          ) : (
            <BtnDisabled label="Enregistrer" />
          )}
        </Actions>
      </InfoEdit>
    ) : (
      <Info>
        <StyledValue
          value={
            tCommentaire.length > 0 ? (
              <>
                <CommentInfo>
                  {`Le ${DateTime.fromMillis(parseInt(tCommentaire[0].dDate, 10)).toFormat(
                    'dd/LL/yyyy'
                  )} par ${tCommentaire[0].sUtilisateur} :`}
                </CommentInfo>
                {tCommentaire[0].sMessage}
              </>
            ) : (
              ''
            )
          }
          onClick={this.onCommentaireEditBtnClick}
        />

        {tCommentaire.length > 0 && (
          <HistoryBtn active={showHistory} onClick={this.onHistoryBtnClick}>
            Historique <HistoryBtnIcon name="history" />
          </HistoryBtn>
        )}
        {showHistory && (
          <History>
            {tCommentaire.map(commentaire => (
              <Archive>
                <CommentInfo>
                  {`Le ${DateTime.fromMillis(parseInt(commentaire.dDate, 10)).toFormat(
                    'dd/LL/yyyy'
                  )} par ${commentaire.sUtilisateur} :`}
                </CommentInfo>
                {commentaire.sMessage}
              </Archive>
            ))}
          </History>
        )}
      </Info>
    );
  }
}

Commentaire.propTypes = {
  iPKDemande: PropTypes.number.isRequired,
  tCommentaire: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

export default Commentaire;
