import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setProgrammationDate } from '../../../../actions/request';
import { listViews } from '../../../../actions/view';

const StyledBtnProgramm = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

class BtnProgramm extends Component {
  onClick = evt => {
    const { dispatch, iPKUtilisateur, oDate, sNature, sPrestation } = this.props;

    evt.preventDefault();

    Object.keys(oDate).forEach(iPKDemande => {
      dispatch(setProgrammationDate(iPKUtilisateur, iPKDemande, oDate[iPKDemande], sNature, sPrestation));
      dispatch(listViews(iPKUtilisateur));
    });
  };

  render() {
    return <StyledBtnProgramm onClick={this.onClick}>Programmer le RDV</StyledBtnProgramm>;
  }
}

BtnProgramm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  oDate: PropTypes.arrayOf(PropTypes.any).isRequired,
  sNature: PropTypes.string.isRequired,
  sPrestation: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  oFilters: state.request.filters,
  oOrder: state.request.filters
});

export default connect(mapStateToProps)(BtnProgramm);
