import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

const ResultsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: ${props => props.input.inputRef.current.clientWidth + 2}px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.inputBorderLight};
  border-top: 0;
`;

const Result = styled.button`
  display: block;
  padding: 5px;
  width: 100%;
  text-align: left;
  background: ${props => props.theme.colors.white};
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.inputBorderLight};
  transition: all 0.25s ease-in-out;
  outline: none;
  cursor: pointer;

  &::last-child {
    border: 0;
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark};
  }
`;

const ResultName = styled.span`
  display: block;
  font-weight: bold;
`;

const ResultCodeAnnuaire = styled.span`
  display: block;
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      results: [],
      show: true
    };

    this.inputRef = React.createRef();
  }

  onChange = (evt, data) => {
    const { value } = data;
    const { onChange, tAntenne } = this.props;

    if (value.length > 2) {
      axios
        .post(`${process.env.REACT_APP_GRAPHQL_URL}/graphql`, {
          query: `{
          recherchePatient(sQuery: "${value}", sAntenne:"${tAntenne.join(',')}") {
            iPKAnnuaire
            sCodeAnnuaire
            sNom
            sPrenom
        }
      }`
        })
        .then(response => {
          this.setState({ results: response.data.data.recherchePatient, value, show: true }, () => {
            onChange(evt, data, false);
          });
        });
    } else {
      this.setState({ results: [], value, show: true }, () => {
        onChange(evt, data, false);
      });
    }
  };

  onClick = (evt, result) => {
    const { onChange, onClick } = this.props;
    onChange(evt, { id: 'sCodeAnnuaire', value: result.sCodeAnnuaire }, true);

    this.setState(
      {
        value: `${result.sNom} ${result.sPrenom}`,
        show: false
      },
      () => {
        onClick(evt);
      }
    );
  };

  onFocus = () => {
    this.setState({ show: true });
  };

  onBlur = () => {
    setTimeout(() => this.setState({ show: false }), 250);
  };

  render() {
    const { id } = this.props;
    const { value, results, show } = this.state;

    return (
      <>
        <Input
          ref={this.inputRef}
          id={id}
          value={value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoComplete="off"
        />
        {show && results.length > 0 && (
          <ResultsWrapper input={this.inputRef.current}>
            {results.map(result => (
              <Result
                key={result.iPKAnnuaire}
                onClick={evt => {
                  this.onClick(evt, result);
                }}
              >
                <ResultName>{`${result.sNom} ${result.sPrenom}`}</ResultName>
                <ResultCodeAnnuaire>{result.sCodeAnnuaire}</ResultCodeAnnuaire>
              </Result>
            ))}
          </ResultsWrapper>
        )}
      </>
    );
  }
}

AutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  tAntenne: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default AutoComplete;
