import { DateTime } from 'luxon';

import {
  REQUEST_LOAD_SUCCESS,
  REQUEST_LOAD_FAILURE,
  REQUEST_FILTER_SUCCESS,
  REQUEST_FILTER_FAILURE,
  REQUEST_ORDER_SUCCESS,
  REQUEST_ORDER_FAILURE,
  REQUEST_LOCK_SUCCESS,
  REQUEST_LOCK_FAILURE,
  REQUEST_UNLOCK_SUCCESS,
  REQUEST_UNLOCK_FAILURE,
  REQUEST_SET_COMMENTAIRE_SUCCESS,
  REQUEST_SET_COMMENTAIRE_FAILURE,
  REQUEST_SET_ANTENNE_SUCCESS,
  REQUEST_SET_ANTENNE_FAILURE,
  REQUEST_SET_COULEUR_SUCCESS,
  REQUEST_SET_COULEUR_FAILURE,
  REQUEST_SET_PROGRAMMATION_SUCCESS,
  REQUEST_SET_PROGRAMMATION_FAILURE,
  REQUEST_RESET_PROGRAMMATION_SUCCESS,
  REQUEST_RESET_PROGRAMMATION_FAILURE,
  REQUEST_SET_DELAI_SUCCESS,
  REQUEST_SET_DELAI_FAILURE,
  REQUEST_LOAD_DELAIS_SUCCESS,
  REQUEST_LOAD_DELAIS_FAILURE,
  REQUEST_SET_MOTIF_SUCCESS,
  REQUEST_SET_MOTIF_FAILURE,
  REQUEST_SET_NATURE_SUCCESS,
  REQUEST_SET_NATURE_FAILURE,
  REQUEST_LOAD_COMMENTAIRES_SUCCESS,
  REQUEST_LOAD_COMMENTAIRES_FAILURE,
  REQUEST_DELETE_SUCCESS,
  REQUEST_DELETE_FAILURE,
  REQUEST_RESTORE_SUCCESS,
  REQUEST_RESTORE_FAILURE,
  REQUEST_SET_VIEWED_SUCCESS,
  REQUEST_SET_VIEWED_FAILURE,
  REQUEST_SET_VISITE_SUCCESS,
  REQUEST_SET_VISITE_FAILURE
} from '../constants/ActionTypes';

const initialRequestState = {
  list: [],
  locked: {
    iPKVerrouillage: 0,
    iFKDemande: -1,
    iFKUtilisateur: -1,
    dDate: new Date()
  },
  filters: {
    tAntenne: [],
    tNature: [],
    tPrestation: [],
    tEtablissement: [],
    tCouleur: [],
    sCodeAnnuaire: '',
    iDelaiMin: '',
    iDelaiMax: '',
    bVisiteAssociation: null,
    bEtablissement: null,
    bProgramme: null,
    bVue: null,
    dInsert: DateTime.local()
      .startOf('day')
      .toISO()
  },
  order: {
    col: 'dLimite',
    dir: 'ASC'
  },
  error: null
};

export default (state = initialRequestState, { type, payload }) => {
  switch (type) {
    case REQUEST_LOAD_SUCCESS: {
      return { ...state, list: payload };
    }

    case REQUEST_LOAD_FAILURE: {
      return { ...state, list: [], error: payload };
    }

    case REQUEST_FILTER_SUCCESS: {
      return { ...state, filters: payload };
    }

    case REQUEST_FILTER_FAILURE: {
      return { ...state, filters: {}, error: payload };
    }

    case REQUEST_ORDER_SUCCESS: {
      return { ...state, order: payload };
    }

    case REQUEST_ORDER_FAILURE: {
      return { ...state, order: { col: 'iDelai', dir: 'ASC' }, error: payload };
    }

    case REQUEST_LOCK_SUCCESS: {
      const locked = {
        iPKVerrouillage: payload.iPKVerrouillage,
        iFKDemande: payload.iFKDemande,
        iFKUtilisateur: payload.iFKUtilisateur,
        sUtilisateur: payload.sUtilisateur,
        dDate: payload.dDate
      };

      const newList = state.list.map(demande =>
        demande.iPKDemande === payload.iFKDemande ? { ...payload.demande, bVue: 1 } : demande
      );

      return { ...state, list: newList, locked, error: null };
    }

    case REQUEST_LOCK_FAILURE: {
      return { ...state, locked: payload, error: payload };
    }

    case REQUEST_UNLOCK_SUCCESS: {
      return {
        ...state,
        locked: {
          iPKVerrouillage: 0,
          iFKDemande: -1,
          iFKUtilisateur: -1,
          dDate: new Date()
        },
        error: null
      };
    }
    case REQUEST_UNLOCK_FAILURE: {
      return {
        ...state,
        locked: {
          iPKVerrouillage: 0,
          iFKDemande: -1,
          iFKUtilisateur: -1,
          dDate: new Date()
        },
        error: payload
      };
    }

    case REQUEST_SET_COMMENTAIRE_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_COMMENTAIRE_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_ANTENNE_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_ANTENNE_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_COULEUR_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_COULEUR_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_VISITE_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_VISITE_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_PROGRAMMATION_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_PROGRAMMATION_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_RESET_PROGRAMMATION_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_RESET_PROGRAMMATION_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_DELAI_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_DELAI_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_MOTIF_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_MOTIF_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_NATURE_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande ? payload : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_SET_NATURE_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_LOAD_DELAIS_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande
          ? Object.assign({}, request, { historiqueDelais: payload.historique })
          : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_LOAD_DELAIS_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_LOAD_COMMENTAIRES_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande
          ? Object.assign({}, request, { tCommentaire: payload.historique })
          : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_LOAD_COMMENTAIRES_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_DELETE_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande
          ? Object.assign({}, request, { bInactif: 1 })
          : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_DELETE_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_RESTORE_SUCCESS: {
      const { list } = state;

      const updatedList = list.map(request =>
        request.iPKDemande === payload.iPKDemande
          ? Object.assign({}, request, { bInactif: 0 })
          : request
      );

      return { ...state, list: updatedList, error: null };
    }
    case REQUEST_RESTORE_FAILURE: {
      return { ...state, error: payload };
    }

    case REQUEST_SET_VIEWED_SUCCESS: {
      const newList = state.list.map(demande =>
        demande.iPKDemande === payload.iFKDemande
          ? { ...payload.request, bVue: payload.bVue }
          : demande
      );

      return { ...state, list: newList, error: null };
    }

    case REQUEST_SET_VIEWED_FAILURE: {
      return { ...state, locked: payload, error: payload };
    }

    default:
      return state;
  }
};
