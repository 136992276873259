import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DateTime } from 'luxon';
import { Icon } from 'semantic-ui-react';

const StyledBtnSendEmail = styled.a`
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 170px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blueDark} !important;
  }

  &:active,
  &:visited {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blue};
  }
`;

const BtnSendEmail = ({ request }) => {
  const URL = `mailto:?subject=Nouvelle demande d'intervention&body=N° Patient: ${
    request.sCodeAnnuaire
  }%0D%0ANom : ${request.sNom}%0D%0APrénom : ${request.sPrenom}%0D%0AAdresse : ${
    request.sAdresse
  } ${request.sCodePostal} ${request.sVille}%0D%0ATéléphone fixe : ${
    request.sTelFixe
  }%0D%0ATéléphone portable : ${request.sTelMobile}%0D%0A%0D%0APrestation : ${
    request.sPrestation
  }%0D%0ANature : ${request.sNature}%0D%0ADate programmation : ${DateTime.fromMillis(
    parseInt(request.dDateProgrammation, 10)
  ).toFormat('dd/LL/yyyy')}%0D%0A%0D%0ACommentaire :%0D%0A${
    request.tCommentaire.length > 0
      ? request.tCommentaire[0].sMessage.replace(/\n/g, '%0D%0A')
      : '-'
  }%0D%0A%0D%0ACommentaire ISADOM :%0D%0A${
    request.sMotif ? request.sMotif.replace(/\n/g, '%0D%0A') : ''
  }`;

  return (
    <StyledBtnSendEmail
      href={URL}
      onClick={evt => {
        evt.preventDefault();
        window.open(URL);
      }}
    >
      <Icon name="mail" />
    </StyledBtnSendEmail>
  );
};

BtnSendEmail.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired
};

export default BtnSendEmail;
