import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import jwt from 'jsonwebtoken';
import md5 from 'md5';
import { DateTime } from 'luxon';

import { setAuthorizationToken } from '../utils';
import { client, fetchApollo, mutateApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILURE,
  USER_SIGNOUT_SUCCESS,
  USER_SIGNOUT_FAILURE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  REQUEST_FILTER_SUCCESS,
  REQUEST_LOAD_REQUEST,
  RAPPEL_LIST_REQUEST,
  VIEW_LIST_REQUEST
} from '../constants/ActionTypes';

export function* signUserIn({ payload: { sLogin, sPassword } }) {
  if (!navigator.onLine) {
    alert('TODO: USER_SIGNIN_REQUEST Offline');
  } else {
    try {
      const AUTORISE = gql`{
        autoriseUtilisateur(sLogin: "${sLogin}", sPassword: "${sPassword}") {
          iPKUtilisateur
          iFKAnnuaire
          iRole
          sCodeCivilite
          sNom
          sPrenom
          iFKAntenne
          tAntenne
          tNature
          tPrestation
        }
      }`;
      const response = yield call(fetchApollo, AUTORISE);
      const user = response.data.autoriseUtilisateur;

      if (user !== null) {
        user.sPassword = md5(sPassword);
        user.dDate = new Date();

        const token = jwt.sign(user, process.env.REACT_APP_JWT_SECRET, {
          expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN
        });

        setAuthorizationToken(token);
        localStorage.setItem('jwtToken', JSON.stringify(token));
        localStorage.setItem('user', JSON.stringify(user));

        const oFilters = {
          tAntenne: JSON.parse(user.tAntenne),
          tNature: JSON.parse(user.tNature),
          tPrestation: JSON.parse(user.tPrestation),
          tEtablissement: [],
          tCouleur: [],
          sCodeAnnuaire: '',
          iDelaiMin: '',
          iDelaiMax: '',
          bVisiteAssociation: null,
          bEtablissement: null,
          bProgramme: null,
          bVue: null,
          dInsert: DateTime.local()
            .startOf('day')
            .toISO()
        };

        const oOrder = { col: 'dLimite', dir: 'ASC' };

        yield put({ type: USER_SIGNIN_SUCCESS, payload: user });
        yield put({
          type: REQUEST_FILTER_SUCCESS,
          payload: oFilters
        });
        yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
        yield put({
          type: RAPPEL_LIST_REQUEST,
          payload: { iPKUtilisateur: user.iPKUtilisateur }
        });
      } else {
        localStorage.removeItem('jwtToken');

        yield put({ type: USER_SIGNIN_FAILURE, payload: 'Identifiants incorrects' });
        yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'login' } });
      }
    } catch (error) {
      let { message } = error;

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Identifiants incorrects';
          break;
        default:
          break;
      }

      localStorage.removeItem('jwtToken');
      yield put({ type: USER_SIGNIN_FAILURE, payload: message });
    }
  }
}

export function* signUserOut() {
  if (localStorage.getItem('jwtToken')) {
    localStorage.removeItem('jwtToken');
    // client.resetStore();

    yield put({
      type: USER_SIGNOUT_SUCCESS,
      payload: {}
    });
  } else {
    client.resetStore();

    yield put({
      type: USER_SIGNOUT_FAILURE,
      payload: {}
    });
  }
}

export function* updateUser({ payload: { iPKUtilisateur, tAntenne, tNature, tPrestation } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'user' } });

  if (!navigator.onLine) {
    alert('TODO: USER_UPDATE_REQUEST Offline');
  } else {
    try {
      const UPDATE = gql`
        mutation {
          enregistreUtilisateur(
            iPKUtilisateur: ${iPKUtilisateur}, 
            sAntennes: "${JSON.stringify(tAntenne)}",
            sNatures: "${JSON.stringify(JSON.stringify(tNature)).slice(1, -1)}",
            sPrestations: "${JSON.stringify(JSON.stringify(tPrestation)).slice(1, -1)}") {
              iResultat
            }
        }`;

      const response = yield call(mutateApollo, UPDATE);
      const result = response.data.enregistreUtilisateur;

      if (result && result.iResultat === 1) {
        const oFilters = {
          tAntenne,
          tNature,
          tPrestation,
          tEtablissement: [],
          tCouleur: [],
          sCodeAnnuaire: '',
          iDelaiMin: '',
          iDelaiMax: '',
          bVisiteAssociation: null,
          bEtablissement: null,
          bProgramme: null,
          bVue: null,
          dInsert: DateTime.local()
            .startOf('day')
            .toISO()
        };

        const oOrder = { col: 'dLimite', dir: 'ASC' };

        yield put({
          type: REQUEST_FILTER_SUCCESS,
          payload: oFilters
        });
        yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });

        yield put({ type: VIEW_LIST_REQUEST, payload: { iPKUtilisateur } });

        yield put({ type: USER_UPDATE_SUCCESS, payload: { tAntenne, tNature, tPrestation } });
      } else {
        yield put({ type: USER_UPDATE_FAILURE, payload: 'User update failed...' });
      }
    } catch (error) {
      let { message } = error;

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Enregistrements incorrects';
          break;
        default:
          break;
      }

      yield put({ type: USER_UPDATE_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'user' } });
    }
  }
}
