import axios from 'axios';
import crypto from 'crypto';

export const WEEKDAYS_SHORT = {
  fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
};

export const WEEKDAYS_LONG = {
  fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
};

export const MONTHS = {
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ]
};

export const FIRST_DAY_OF_WEEK = {
  fr: 1
};

export const LABELS = {
  fr: { nextMonth: 'Mois suivant', previousMonth: 'Mois précédent' }
};

export const htmlEntitiesDecode = text => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html');
  return dom.body.textContent
    .replace(/&apos;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&#13;/g, '');
};

export const truncate = (text, limit, after = '...') => {
  const sourceText = htmlEntitiesDecode(text);

  if (sourceText.length <= limit) return sourceText;

  const words = sourceText.split(' ');
  let truncated = '';

  words.some(word => {
    if (truncated.length + word.length <= limit) {
      truncated += ` ${word}`;

      return false;
    }

    return true;
  });

  return truncated + after;
};

export const formatPhone = number => {
  if (!number) {
    return '-';
  }
  const numbers = number.replace(/\D/g, '');
  const char = { 2: ' ', 4: ' ', 6: ' ', 8: ' ' };

  let formatedNumber = '';
  for (let i = 0; i < numbers.length; i += 1) {
    formatedNumber += (char[i] || '') + numbers[i];
  }

  return formatedNumber;
};

export const setAuthorizationToken = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

const key = JSON.parse(process.env.REACT_APP_CRYPTO_KEY).data;
const iv = JSON.parse(process.env.REACT_APP_CRYPTO_IV).data;

export const encrypt = text => {
  const cipher = crypto.createCipheriv('aes-256-ctr', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { encryptedData: encrypted.toString('hex') };
};

export const decrypt = text => {
  const encryptedText = Buffer.from(text.encryptedData, 'hex');
  const decipher = crypto.createDecipheriv('aes-256-ctr', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

export const getDelaiBackground = (value, colors) => {
  if (value > 3) {
    if (value > 6) {
      if (value >= 14) {
        return colors.white;
      }

      return colors.greenDark;
    }

    return colors.orange;
  }

  return colors.purpleDark;
};

export const getDelaiBorderColor = (value, colors) => {
  if (value < 14) {
    return colors.white;
  }

  return colors.grey;
};

export const getDelaiColor = (value, colors) => {
  if (value < 14) {
    return colors.white;
  }

  return colors.textBlack;
};

export const getBackgroundColor = (index, selected, echeance, bAttente, colors) => {
  if (selected) {
    if (!bAttente && echeance <= 3) {
      return colors.purpleBackgroundLight;
    }

    return colors.tableRowSelected;
  }

  if (!bAttente && echeance <= 0) {
    return colors.purpleBackgroundLighter;
  }

  return index % 2 === 0 ? colors.tableRowEven : colors.tableRowUneven;
};

export const getHoverBackgroundColor = (index, selected, echeance, bAttente, colors) => {
  if (selected) {
    if (!bAttente && echeance <= 3) {
      return colors.purpleBackgroundLight;
    }

    return colors.grey;
  }

  if (!bAttente && echeance <= 0) {
    return colors.purpleBackgroundLight;
  }

  return colors.tableRowEvenHover;
};
