import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InfoZoneTitle from './Title';
import InfoZoneAnchor from './Anchor';
import InfoZoneContent from './Content';

const StyledInfoZone = styled.div`
  margin: 0 0 20px;
`;

const InfoZone = ({ request, anchor, title, data }) => {
  return (
    <StyledInfoZone>
      {title.length > 0 ? (
        <InfoZoneTitle>
          <InfoZoneAnchor anchor={anchor} label={title} />
        </InfoZoneTitle>
      ) : (
        <a href="#top" ref={anchor}>
          {null}
        </a>
      )}
      <InfoZoneContent request={request} data={data} />
    </StyledInfoZone>
  );
};

InfoZone.propTypes = {
  request: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array])
  ).isRequired,
  anchor: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired
};

export default InfoZone;
