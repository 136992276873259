import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import styled from 'styled-components';

const StyledAlert = styled.div`
  position: fixed;
  z-index: 500;
  /* top: 60px; */
  /* right: ${props => props.index * (360 + 20) + 20}px; */
  top: ${props => props.index * (80 + 20) + 60}px;
  right: 20px;
  padding: 20px;
  height: 80px;
  width: 360px;
  color: ${props => props.theme.colors.white};
  background: rgba(0, 0, 0, 0.5);
`;

const AlertDate = styled.div`
  color: ${props => props.theme.colors.white};
  font-weight: bold;
`;

const AlertComment = styled.div`
  color: ${props => props.theme.colors.white};
`;

class Alert extends Component {
  state = {
    tExpired: [],
    tDisplayed: []
  };

  timer = null;

  componentDidUpdate() {
    const { tRappel } = this.props;
    const { tExpired } = this.state;
    const now = Date.now();
    const tExpiredNext = tRappel.filter(rappel => parseInt(rappel.dDate, 10) <= now);

    if (tExpiredNext.length > tExpired.length) {
      this.setState({ tExpired: tExpiredNext }, this.autoHide());
    }
  }

  componentWillUnmount() {
    this.timer = window.clearInterval(this.timer);
    this.timer = null;
  }

  autoHide = () => {
    window.clearInterval(this.timer);
    this.timer = window.setInterval(this.hideAlert, 1500);
  };

  hideAlert = () => {
    const { tExpired } = this.state;

    if (tExpired.length > 0) {
      this.setState(prevState => ({
        tExpired: [...prevState.tExpired.slice(0, -1)],
        tDisplayed: [...prevState.tDisplayed, prevState.tExpired.slice(-1)[0].iPKRappel]
      }));
    } else {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  };

  render() {
    const { tExpired, tDisplayed } = this.state;
    const tAlert = tExpired.filter(r => tDisplayed.indexOf(r.iPKRappel) === -1);

    return tAlert.length > 0 ? (
      tAlert.map((rappel, index) => (
        <StyledAlert key={rappel.iPKRappel} index={index}>
          <AlertDate>
            {`Le ${DateTime.fromMillis(parseInt(rappel.dDate, 10)).toFormat('dd/LL/yyyy à HH:mm')}`}
          </AlertDate>
          <AlertComment>{rappel.sCommentaire}</AlertComment>
        </StyledAlert>
      ))
    ) : (
      <></>
    );
  }
}

Alert.propTypes = {
  tRappel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  tRappel: state.rappel.list
});

export default connect(mapStateToProps)(Alert);
