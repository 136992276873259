import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  height: 100%;
`;

export default function index({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
