import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { activateView } from '../../../../actions/view';
import { client } from '../../../../apolloClient';
import { filterRequests } from '../../../../actions/request';

const StyledBtnFilterRequests = styled.button`
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 120px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => (props.disabled ? props.theme.colors.grey : props.theme.colors.blue)};
  border: 0;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props =>
    props.disabled ? props.theme.colors.grey : props.theme.colors.blueDark} !important;
  }

  &:active,
  &:visited {
    color: ${props => props.theme.colors.white};
    background: ${props =>
    props.disabled ? props.theme.colors.grey : props.theme.colors.blueDark} !important;
  }
`;

function BtnFilterPatientRequests({ filterPatientRequests, getPatientRequestNumber, iPKUtilisateur, sCodeAnnuaire, tAntenne }) {
  const [patientRequestsNumber, setPatientRequestsNumber] = useState(null);

  useEffect(() => {
    getPatientRequestNumber(sCodeAnnuaire)
      .then(setPatientRequestsNumber)
      .catch(console.error);
  }, []);

  const onBtnFilterPatientRequestsClick = () =>
    filterPatientRequests(tAntenne, sCodeAnnuaire, iPKUtilisateur);

  return <StyledBtnFilterRequests
    disabled={patientRequestsNumber < 2}
    onClick={onBtnFilterPatientRequestsClick}
  >
    {
      Number.isInteger(patientRequestsNumber)
        ? <Icon name='file archive outline' />
        : <Icon loading name='cog' />
    }
  </StyledBtnFilterRequests>;
}

BtnFilterPatientRequests.propTypes = {
  activateView: PropTypes.func.isRequired,
  filterPatientRequests: PropTypes.func.isRequired,
  getPatientRequestNumber: PropTypes.func.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  sCodeAnnuaire: PropTypes.string.isRequired,
  tAntenne: PropTypes.arrayOf(PropTypes.any).isRequired,
  tRequest: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  tAntenne: state.user.data.tAntenne,
  iPKUtilisateur: state.user.data.iPKUtilisateur,
  tRequest: state.request.list
});

const mapDispatchToProps = dispatch => ({
  filterPatientRequests: (tAntenne, sCodeAnnuaire, iPKUtilisateur) => {
    dispatch(activateView(-1, iPKUtilisateur, tAntenne, [], []));
    dispatch(
      filterRequests(
        {
          tAntenne,
          tNature: [],
          tPrestation: [],
          sCodeAnnuaire,
          iDelaiMin: '',
          iDelaiMax: '',
          tEtablissement: [],
          tCouleur: [],
          bVisiteAssociation: null,
          bEtablissement: null,
          bProgramme: 0,
          bInactif: null,
          bVue: null
        },
        {
          col: 'dLimite',
          dir: 'ASC'
        },
        -1,
        iPKUtilisateur,
        false
      )
    );
  },
  getPatientRequestNumber: async sCodeAnnuaire => {
    const response = await client.query({
      query: gql`{
        compteDemandes(sFilters: "${JSON.stringify(JSON.stringify({ sCodeAnnuaire })).slice(1, -1)}") {
          iResultat
        }
      }`
    });
    return response.data.compteDemandes.iResultat;
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BtnFilterPatientRequests);
