import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Form } from 'semantic-ui-react';
import { DatePickerInput } from 'rc-datepicker';
import { DateTime } from 'luxon';
import '../../../../../../../../../assets/css/datepicker.css';
import 'moment/locale/fr';

import BtnProgram from '../../../../../../Btn/Program';
import BtnResetProgram from '../../../../../../Btn/ResetProgram';
import BtnCancelProgramm from '../../../../../../Btn/CancelProgram';
import BtnDisabled from '../../../../../../Btn/Disabled';

import InfoEdit from '../Info/Edit';
import Label from '../Info/Label';
import Detail from '../Info/Detail';
import Actions from '../Info/Actions';
import Value from '../Info/Value';
import Delai from './Delai';

const Info = styled.div`
  display: flex;
  margin: 0 0 10px;
  width: 100%;
  padding: 0 0 0 15px;
`;

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

const DateSouhaitee = styled.div`
  padding-top: 5px;
  color: ${props => (props.diff > 0 ? props.theme.colors.purple : props.theme.colors.greenDark)};
`;

class Programmation extends Component {
  constructor(props) {
    super(props);

    const { iPKDemande } = this.props;
    let { dDateProgrammation } = this.props;

    if (dDateProgrammation) {
      dDateProgrammation = DateTime.fromMillis(parseInt(dDateProgrammation, 10));
    }

    this.state = {
      edited: false,
      initialDate: { [iPKDemande]: dDateProgrammation },
      dDateProgrammation: { [iPKDemande]: dDateProgrammation }
    };
  }

  componentDidUpdate(prevProps) {
    const { dDateProgrammation } = this.props;

    if (prevProps.dDateProgrammation !== dDateProgrammation) {
      this.setState({
        dDateProgrammation: dDateProgrammation
          ? DateTime.fromMillis(parseInt(dDateProgrammation, 10))
          : null
      });
    }
  }

  onProgrammationEditBtnClick = evt => {
    evt.preventDefault();

    this.setState({ edited: true });
  };

  onDateChange = (iPKDemande, date, iPosition) => {
    const newDate = DateTime.fromJSDate(date).startOf('day');

    if (newDate.isValid) {
      if (iPosition === 0) {
        const linkedRequests = this.getLinkedRequests().filter(d => d.iPKDemande !== iPKDemande);
        const newdDateProgrammation = { [iPKDemande]: newDate };

        linkedRequests.forEach(r => {
          newdDateProgrammation[r.iPKDemande] = newDate.plus({ days: r.iDelai });
        });

        this.setState({ dDateProgrammation: newdDateProgrammation, edited: true });
      } else {
        this.setState(prevState => ({
          dDateProgrammation: { ...prevState.dDateProgrammation, [iPKDemande]: newDate },
          edited: true
        }));
      }
    }
  };

  onBtnCancelProgrammClick = evt => {
    evt.preventDefault();

    const { initialDate } = this.state;

    this.setState({ dDateProgrammation: initialDate, edited: false });
  };

  onBtnResetProgrammClick = evt => {
    evt.preventDefault();

    const { iPKDemande } = this.props;

    this.setState(prevState => ({
      dDateProgrammation: { ...prevState.dDateProgrammation, [iPKDemande]: null },
      edited: true
    }));
  };

  getLinkedRequests = () => {
    const { requests, iPKDemande } = this.props;

    return requests
      .filter(
        r =>
          r.iFKParentDemande === iPKDemande ||
          (r.iPKDemande === iPKDemande && r.dDateProgrammation === null)
      )
      .sort((a, b) => a.iDelai - b.iDelai);
  };

  getLinkedRequestsNb = () => {
    return Math.max(this.getLinkedRequests().length, 1);
  };

  render() {
    const { edited, dDateProgrammation } = this.state;
    const { iPKDemande, label, dDispoPatient, dLimite, dSouhaitRealisation, iDelai, requests } = this.props;

    const DateDispoPatient = DateTime.fromMillis(parseInt(dDispoPatient, 10));
    const DateLimite = DateTime.fromMillis(parseInt(dLimite, 10));
    // const DateToday = DateTime.local();
    const linkedRequests = this.getLinkedRequests();
    const [request] = requests.filter(r => r.iPKDemande === iPKDemande);

    return edited ? (
      <InfoEdit>
        <Form>
          {linkedRequests.length > 0 ? (
            linkedRequests.map(
              (r, index) =>
                r.dDateProgrammation === null && (
                  <Form.Field key={r.iPKDemande}>
                    <FormLabel htmlFor="programmation">
                      Visite {r.sNature} <Delai iDelai={r.iDelai} /> :
                    </FormLabel>

                    <DatePickerInput
                      onChange={date => {
                        this.onDateChange(r.iPKDemande, date, index);
                      }}
                      value={
                        dDateProgrammation[r.iPKDemande]
                          ? dDateProgrammation[r.iPKDemande].toISODate()
                          : null
                      }
                      locale="fr"
                      id={r.iPKDemande}
                      format="DD/MM/YYYY"
                      displayFormat="DD/MM/YYYY"
                      // minDate={
                      //   DateDispoPatient.diff(DateToday, ['days']).toObject().days > 0
                      //     ? r.iFKParentDemande === 0
                      //       ? DateDispoPatient.toISODate()
                      //       : DateDispoPatient.plus({ days: r.iDelai }).toISODate()
                      //     : DateToday.toISODate()
                      // }
                    />
                  </Form.Field>
                )
            )
          ) : (
            <Form.Field>
              <FormLabel htmlFor="programmation">
                Date de programmation :<Mandatory>*</Mandatory>
              </FormLabel>

              <DatePickerInput
                onChange={date => {
                  this.onDateChange(iPKDemande, date);
                }}
                value={
                  dDateProgrammation[iPKDemande] ? dDateProgrammation[iPKDemande].toISODate() : null
                }
                placeholder={
                  dSouhaitRealisation
                    ? DateTime.fromMillis(parseInt(dSouhaitRealisation, 10)).toFormat('dd/LL/yyyy')
                    : DateTime.fromMillis(parseInt(dDispoPatient, 10))
                        .plus({ days: iDelai })
                        .toFormat('dd/LL/yyyy')
                }
                locale="fr"
                id={iPKDemande}
                displayFormat="DD/MM/YYYY"
                // minDate={
                //   DateDispoPatient.diff(DateToday, ['days']).toObject().days > 0
                //     ? DateDispoPatient.toISODate()
                //     : DateToday.toISODate()
                // }
              />
              {dSouhaitRealisation && (
                <DateSouhaitee
                  diff={
                    DateLimite
                      ? DateLimite.endOf('day')
                          .diff(
                            DateTime.fromMillis(parseInt(dSouhaitRealisation, 10)).endOf('day'),
                            'seconds'
                          )
                          .toObject().seconds
                      : 0
                  }
                >
                  Date souhaitée :{' '}
                  {DateTime.fromMillis(parseInt(dSouhaitRealisation, 10)).toFormat('dd/LL/yyyy')}
                </DateSouhaitee>
              )}
            </Form.Field>
          )}
        </Form>

        <Actions>
          <BtnCancelProgramm onClick={this.onBtnCancelProgrammClick} />
          {/* {dDateProgrammation[iPKDemande] ? (
            dDateProgrammation[iPKDemande]
              .startOf('day')
              .diff(DateDispoPatient.startOf('day'), ['days'])
              .toObject().days >= 0 && <BtnProgram oDate={dDateProgrammation} />
          ) : (
            <BtnDisabled label="Programmer le RDV" />
          )} */}
          {dDateProgrammation[iPKDemande] ? (
            <BtnProgram oDate={dDateProgrammation} sNature={request.sNature} sPrestation={request.sPrestation}/>
          ) : (
            <BtnDisabled label="Programmer le RDV" />
          )}

          {this.props.dDateProgrammation && (
            <BtnResetProgram onClick={this.onBtnResetProgrammClick} />
          )}
        </Actions>
      </InfoEdit>
    ) : (
      <Info>
        <Label wide>{label}&nbsp;:</Label>
        <Detail wide onClick={this.onProgrammationEditBtnClick}>
          <Value
            value={
              dDateProgrammation[iPKDemande]
                ? dDateProgrammation[iPKDemande].toFormat('dd/LL/yyyy')
                : `à programmer (${this.getLinkedRequestsNb()})`
            }
          />
        </Detail>
      </Info>
    );
  }
}

Programmation.propTypes = {
  iPKDemande: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  dDispoPatient: PropTypes.string.isRequired,
  dLimite: PropTypes.string.isRequired,
  dDateProgrammation: PropTypes.string,
  dSouhaitRealisation: PropTypes.string,
  iDelai: PropTypes.number.isRequired,
  requests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

Programmation.defaultProps = {
  dDateProgrammation: null,
  dSouhaitRealisation: null
};

const mapStateToProps = state => ({
  requests: state.request.list
});

export default connect(mapStateToProps)(Programmation);
