import React from 'react';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Annulation from './Zone/Annulation';
import BtnAddRappel from '../../../Btn/AddRappel';
import BtnCloseRequest from '../../../Btn/CloseRequest';
import BtnFilterPatientRequests from '../../../Btn/FilterPatientRequests';
import BtnRestauration from '../../../Btn/RestoreRequest';
import BtnSendEmail from '../../../Btn/SendEmail';
import BtnToggleViewed from '../../../Btn/ToggleViewed';
import InfoZone from './Zone';

const RequestDetailInfo = styled.div``;

function Infos({ onBtnToggleAddRappelClick, onBtnToggleViewedClick, refs, request, tPrecision }) {
  return (
    <RequestDetailInfo>
      <InfoZone
        request={request}
        anchor={refs[0]}
        title=""
        data={{
          type: 'actions',
          actions: [
            <BtnCloseRequest />,
            <BtnAddRappel onClick={onBtnToggleAddRappelClick} />,
            <BtnFilterPatientRequests sCodeAnnuaire={request.sCodeAnnuaire} />,
            <BtnSendEmail request={request} />,
            <BtnToggleViewed onClick={onBtnToggleViewedClick} viewed={request.bVue} />
          ]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[1]}
        title="Demande"
        data={{
          type: 'text',
          value: [
            { type: 'text', label: 'Prestation', value: request.sPrestation },
            {
              type: ['DI', ...tPrecision].includes(request.sNature) ? 'nature' : 'text',
              label: 'Nature',
              value: request.sNature
            },
            { type: 'text', label: 'Demandeur', value: request.sDemandeur },
            { type: 'couleur', label: 'Couleur', value: request }
          ]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[2]}
        title="Commentaire is@dom"
        data={{
          type: 'text',
          value: [{ type: 'longtext', value: request.sMotif }]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[3]}
        title="Commentaire"
        data={{
          type: 'widget',
          value: [
            {
              type: 'comment',
              value: {
                dDate: request.sCommentaireNom ? request.sCommentaireDate : null,
                sAuteur: request.sCommentaireNom
                  ? `${request.sCommentairePrenom} ${request.sCommentaireNom}`
                  : null
              }
            }
          ]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[4]}
        title="Contact"
        data={{
          type: 'phone',
          value: [
            { type: 'text', label: 'Téléphone fixe', value: request.sTelFixe },
            { type: 'text', label: 'Téléphone portable', value: request.sTelMobile }
          ]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[5]}
        title="Planification"
        data={{
          type: 'widget',
          value: [
            {
              type: 'text',
              label: 'Date de la demande',
              value: DateTime.fromMillis(parseInt(request.dDate, 10)).toFormat('dd/LL/yyyy')
            },
            {
              type: 'antenne',
              label: 'Antenne',
              value: request.sAntenne
            },

            { type: 'delai', label: 'Délai', value: request },
            {
              type: 'programmation',
              label: 'Date programmation',
              value: request.dDateProgrammation
                ? DateTime.fromMillis(parseInt(request.dDateProgrammation, 10)).toFormat('dd/LL/yyyy')
                : '-'
            },
            {
              type: 'visite-association',
              label: `Visite à l'association`,
              value: request.bVisiteAssociation
            }
          ]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[6]}
        title="Patient"
        data={{
          type: 'text',
          value: [
            {
              type: 'sCodeAnnuaire',
              label: 'N° Patient',
              value: request
            },
            { type: 'text', label: 'Civilité', value: request.sCodeCivilite },
            { type: 'text', label: 'Nom', value: request.sNom },
            { type: 'text', label: 'Prénom', value: request.sPrenom }
          ]
        }}
      />

      <InfoZone
        request={request}
        anchor={refs[7]}
        title="Coordonnées"
        data={{
          type: 'address',
          value: [
            { type: 'address', label: 'Adresse', value: request.sAdresse },
            { type: 'text', label: 'Code postal', value: request.sCodePostal },
            { type: 'text', label: 'Ville', value: request.sVille }
          ]
        }}
      />

      {request.bInactif === 0 ? <Annulation request={request} /> : <BtnRestauration />}
    </RequestDetailInfo>
  );
}

Infos.propTypes = {
  onBtnToggleAddRappelClick: PropTypes.func.isRequired,
  onBtnToggleViewedClick: PropTypes.func.isRequired,
  refs: PropTypes.arrayOf(PropTypes.any).isRequired,
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  tPrecision: PropTypes.arrayOf(PropTypes.string).isRequired
};

const mapStateToProps = state => ({
  tPrecision: state.config.tPrecision
    .filter(p => p.sPKTypeEvt === 'DI')
    .map(p => p.sCodePrecisionEvt)
});

export default connect(mapStateToProps)(Infos);
