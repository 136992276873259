import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RequestTableHeaderRow from '../Row/Header';
import RequestsTableColHeader from './Col';

const RequestTableHeader = styled.th`
  padding: 0;
  background: ${props => props.theme.colors.backgroundLight};
  border-bottom: 1px solid ${props => props.theme.colors.inputBorderLight};
`;

const Headers = ({ view }) => {
  return (
    <RequestTableHeaderRow>
      <RequestTableHeader />
      <RequestTableHeader>
        <RequestsTableColHeader name="iFKAnnuaire">N° Patient</RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="sNom">
          Nom
          <br />
          Prénom
        </RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="sCodePostal">
          Ville
          <br />
          Code postal
        </RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="sNature">
          Nature de
          <br />
          la demande
        </RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="sDemandeur">Demandeur</RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="sPrestation">Prestation</RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="dDate">
          Date de
          <br />
          la demande
        </RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="iDelai">&Eacute;chéance</RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="dDateProgrammation">
          {view === 0 ? (
            <span>
              Date
              <br />
              Programmation
            </span>
          ) : (
            <span>
              Prochain <br />
              rendez-vous
            </span>
          )}
        </RequestsTableColHeader>
      </RequestTableHeader>
      <RequestTableHeader>
        <RequestsTableColHeader name="bVisiteAssociation">
          Visite à<br />
          l&apos;association
        </RequestsTableColHeader>
      </RequestTableHeader>
    </RequestTableHeaderRow>
  );
};

Headers.propTypes = {
  view: PropTypes.number.isRequired
};

export default Headers;
