import { all, takeLatest } from 'redux-saga/effects';
import { signUserIn, signUserOut, updateUser } from './sagas/user';
import { showLoading, hideLoading } from './sagas/loadings';
import { setOnlineMode, setOfflineMode } from './sagas/network';
import {
  loadRequests,
  countRequests,
  filterRequests,
  orderRequests,
  lockRequest,
  unlockRequest,
  setCommentaire,
  setAntenne,
  setCouleur,
  setProgrammationDate,
  resetProgrammationDate,
  setDelai,
  loadDelais,
  setMotif,
  setNature,
  loadCommentaires,
  deleteRequest,
  restoreRequest,
  setViewed,
  setVisite
} from './sagas/request';
import { loadConfig } from './sagas/config';
import {
  activateView,
  addView,
  deactivateView,
  deleteView,
  editView,
  listViews
} from './sagas/view';
import { listRappels, listUserRappels, addRappel, deleteRappel } from './sagas/rappel';

import {
  NETWORK_ONLINE_REQUEST,
  NETWORK_OFFLINE_REQUEST,
  LOADING_ACTIVATE_REQUEST,
  LOADING_DEACTIVATE_REQUEST,
  CONFIG_LOAD_REQUEST,
  USER_SIGNIN_REQUEST,
  USER_SIGNOUT_REQUEST,
  USER_UPDATE_REQUEST,
  VIEW_LIST_REQUEST,
  VIEW_ADD_REQUEST,
  VIEW_DELETE_REQUEST,
  VIEW_EDIT_REQUEST,
  VIEW_ACTIVATE_REQUEST,
  VIEW_DEACTIVATE_REQUEST,
  REQUEST_LOAD_REQUEST,
  REQUEST_FILTER_REQUEST,
  REQUEST_ORDER_REQUEST,
  REQUEST_LOCK_REQUEST,
  REQUEST_UNLOCK_REQUEST,
  REQUEST_SET_PROGRAMMATION_REQUEST,
  REQUEST_RESET_PROGRAMMATION_REQUEST,
  REQUEST_SET_COMMENTAIRE_REQUEST,
  REQUEST_SET_ANTENNE_REQUEST,
  REQUEST_SET_COULEUR_REQUEST,
  REQUEST_SET_DELAI_REQUEST,
  REQUEST_SET_MOTIF_REQUEST,
  REQUEST_SET_NATURE_REQUEST,
  REQUEST_SET_VIEWED_REQUEST,
  REQUEST_LOAD_DELAIS_REQUEST,
  REQUEST_LOAD_COMMENTAIRES_REQUEST,
  REQUEST_DELETE_REQUEST,
  REQUEST_RESTORE_REQUEST,
  REQUEST_COUNT_REQUEST,
  RAPPEL_LIST_REQUEST,
  RAPPEL_LIST_USER_REQUEST,
  RAPPEL_ADD_REQUEST,
  RAPPEL_DELETE_REQUEST,
  REQUEST_SET_VISITE_REQUEST
} from './constants/ActionTypes';

export default function* rootSaga() {
  yield all([
    takeLatest(NETWORK_ONLINE_REQUEST, setOnlineMode),
    takeLatest(NETWORK_OFFLINE_REQUEST, setOfflineMode),
    takeLatest(LOADING_ACTIVATE_REQUEST, showLoading),
    takeLatest(LOADING_DEACTIVATE_REQUEST, hideLoading),
    takeLatest(CONFIG_LOAD_REQUEST, loadConfig),
    takeLatest(USER_SIGNIN_REQUEST, signUserIn),
    takeLatest(USER_SIGNOUT_REQUEST, signUserOut),
    takeLatest(USER_UPDATE_REQUEST, updateUser),
    takeLatest(VIEW_LIST_REQUEST, listViews),
    takeLatest(VIEW_ADD_REQUEST, addView),
    takeLatest(VIEW_DELETE_REQUEST, deleteView),
    takeLatest(VIEW_EDIT_REQUEST, editView),
    takeLatest(VIEW_ACTIVATE_REQUEST, activateView),
    takeLatest(VIEW_DEACTIVATE_REQUEST, deactivateView),
    takeLatest(REQUEST_LOAD_REQUEST, loadRequests),
    takeLatest(REQUEST_COUNT_REQUEST, countRequests),
    takeLatest(REQUEST_FILTER_REQUEST, filterRequests),
    takeLatest(REQUEST_ORDER_REQUEST, orderRequests),
    takeLatest(REQUEST_LOCK_REQUEST, lockRequest),
    takeLatest(REQUEST_UNLOCK_REQUEST, unlockRequest),
    takeLatest(REQUEST_SET_COMMENTAIRE_REQUEST, setCommentaire),
    takeLatest(REQUEST_SET_ANTENNE_REQUEST, setAntenne),
    takeLatest(REQUEST_SET_COULEUR_REQUEST, setCouleur),
    takeLatest(REQUEST_SET_PROGRAMMATION_REQUEST, setProgrammationDate),
    takeLatest(REQUEST_RESET_PROGRAMMATION_REQUEST, resetProgrammationDate),
    takeLatest(REQUEST_SET_DELAI_REQUEST, setDelai),
    takeLatest(REQUEST_LOAD_DELAIS_REQUEST, loadDelais),
    takeLatest(REQUEST_SET_MOTIF_REQUEST, setMotif),
    takeLatest(REQUEST_SET_NATURE_REQUEST, setNature),
    takeLatest(REQUEST_LOAD_COMMENTAIRES_REQUEST, loadCommentaires),
    takeLatest(REQUEST_DELETE_REQUEST, deleteRequest),
    takeLatest(REQUEST_RESTORE_REQUEST, restoreRequest),
    takeLatest(REQUEST_SET_VIEWED_REQUEST, setViewed),
    takeLatest(RAPPEL_LIST_REQUEST, listRappels),
    takeLatest(RAPPEL_LIST_USER_REQUEST, listUserRappels),
    takeLatest(RAPPEL_ADD_REQUEST, addRappel),
    takeLatest(RAPPEL_DELETE_REQUEST, deleteRappel),
    takeLatest(REQUEST_SET_VISITE_REQUEST, setVisite)
  ]);
}
