import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { deleteRequest } from '../../../../actions/request';

const StyledBtnDeleteRequest = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.purpleDark};
  }
`;

class BtnDeleteRequest extends Component {
  onClick = evt => {
    const { dispatch, iPKDemande, sCommentaire, iPKUtilisateur, onClick } = this.props;

    evt.preventDefault();

    onClick(evt);

    dispatch(deleteRequest(iPKDemande, sCommentaire, iPKUtilisateur));
  };

  render() {
    return (
      <StyledBtnDeleteRequest onClick={this.onClick}>Annuler la demande</StyledBtnDeleteRequest>
    );
  }
}

BtnDeleteRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKDemande: PropTypes.number.isRequired,
  sCommentaire: PropTypes.string.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  iPKDemande: state.request.locked.iFKDemande,
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(BtnDeleteRequest);
