import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { fetchApollo, mutateApollo } from '../apolloClient';

import {
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS,
  REQUEST_LOAD_REQUEST,
  REQUEST_LOAD_SUCCESS,
  REQUEST_LOAD_FAILURE,
  REQUEST_FILTER_SUCCESS,
  REQUEST_FILTER_FAILURE,
  REQUEST_ORDER_SUCCESS,
  REQUEST_ORDER_FAILURE,
  REQUEST_LOCK_SUCCESS,
  REQUEST_LOCK_FAILURE,
  REQUEST_UNLOCK_SUCCESS,
  REQUEST_UNLOCK_FAILURE,
  REQUEST_UNLOCK_REQUEST,
  REQUEST_SET_PROGRAMMATION_SUCCESS,
  REQUEST_SET_PROGRAMMATION_FAILURE,
  REQUEST_RESET_PROGRAMMATION_SUCCESS,
  REQUEST_RESET_PROGRAMMATION_FAILURE,
  REQUEST_SET_COMMENTAIRE_SUCCESS,
  REQUEST_SET_COMMENTAIRE_FAILURE,
  REQUEST_SET_ANTENNE_SUCCESS,
  REQUEST_SET_ANTENNE_FAILURE,
  REQUEST_SET_COULEUR_SUCCESS,
  REQUEST_SET_COULEUR_FAILURE,
  REQUEST_SET_DELAI_SUCCESS,
  REQUEST_SET_DELAI_FAILURE,
  REQUEST_SET_MOTIF_SUCCESS,
  REQUEST_SET_MOTIF_FAILURE,
  REQUEST_SET_NATURE_SUCCESS,
  REQUEST_SET_NATURE_FAILURE,
  REQUEST_LOAD_DELAIS_SUCCESS,
  REQUEST_LOAD_DELAIS_FAILURE,
  REQUEST_LOAD_COMMENTAIRES_SUCCESS,
  REQUEST_LOAD_COMMENTAIRES_FAILURE,
  REQUEST_DELETE_SUCCESS,
  REQUEST_DELETE_FAILURE,
  REQUEST_RESTORE_SUCCESS,
  REQUEST_RESTORE_FAILURE,
  REQUEST_COUNT_SUCCESS,
  REQUEST_COUNT_FAILURE,
  REQUEST_SET_VIEWED_SUCCESS,
  REQUEST_SET_VIEWED_FAILURE,
  REQUEST_SET_DEALT_SUCCESS,
  REQUEST_SET_DEALT_FAILURE,
  REQUEST_SET_VISITE_SUCCESS,
  REQUEST_SET_VISITE_FAILURE,
  VIEW_LIST_REQUEST
} from '../constants/ActionTypes';

export function* loadRequests({ payload: { oFilters, oOrder } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'refresh' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_LOAD_REQUEST Offline');
  } else {
    try {
      const LOAD_REQUEST = gql`{
        listeDemandes(sFilters: "${JSON.stringify(JSON.stringify(oFilters)).slice(
        1,
        -1
      )}", sOrder: "${JSON.stringify(JSON.stringify(oOrder)).slice(1, -1)}") {
          iPKDemande
          iFKParentDemande
          iFKAntenne
          sAntenne
          iFKEtablissement
          iFKAnnuaire
          iDelai
          iDelaiOrigine
          bModifie
          dLimite
          dDispoPatient
          dSouhaitRealisation
          sNature
          sPrestation
          sDemandeur
          sMotif
          dDate
          dDateProgrammation
          bVisiteAssociation
          sCodeAnnuaire
          sCodeCivilite
          sNom
          sPrenom
          sAdresse
          sCodePostal
          sVille
          sTelFixe
          sTelMobile
          sEtablissement
          bEtablissement
          bInactif
          bVue
          bAttente
          bTraitee
          sCouleur
          dHospitalisation
          sEtablissementHospitalisation
          tCommentaire {
            iPKCommentaire
            iFKDemande
            iFKUtilisateur
            sUtilisateur
            sMessage
            dDate
          }
          tRendezVous {
            dRdv
            sTypeRdvLibelle
            sTypeRdvCode
            sCodeIntervenant
            sIntervenantComplet
          }
        }
      }`;
      const response = yield call(fetchApollo, LOAD_REQUEST);
      const requests = response.data.listeDemandes;

      if (requests !== null) {
        yield put({ type: REQUEST_LOAD_SUCCESS, payload: requests });
      } else {
        yield put({ type: REQUEST_LOAD_FAILURE, payload: 'Erreur de chargement des demandes' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des demandes';
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_LOAD_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'refresh' } });
    }
  }
}

export function* countRequests({ payload: { id, oFilters } }) {
  if (!navigator.onLine) {
    alert('TODO: REQUEST_COUNT_REQUEST Offline');
  } else {
    try {
      const LOAD_REQUEST = gql`{
        compteDemandes(sFilters: "${JSON.stringify(JSON.stringify(oFilters)).slice(1, -1)}") {
          iResultat
        }
      }`;
      const response = yield call(fetchApollo, LOAD_REQUEST);
      const requests = response.data.compteDemandes;

      if (requests !== null) {
        yield put({
          type: REQUEST_COUNT_SUCCESS,
          payload: { iPKVue: id, iCount: requests.iResultat }
        });
      } else {
        yield put({ type: REQUEST_COUNT_FAILURE, payload: 'Erreur de chargement des demandes' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de chargement des demandes';
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_COUNT_FAILURE, payload: message });
    }
  }
}

export function* filterRequests({
  payload: { oFilters, oOrder, iPKUtilisateur, iLocked, unLock }
}) {
  if (!navigator.onLine) {
    alert('TODO: REQUEST_FILTER_REQUEST Offline');
  } else if (Object.keys(oFilters).length > 0) {
    yield put({ type: REQUEST_FILTER_SUCCESS, payload: oFilters });
    yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
    if (unLock) {
      yield put({ type: REQUEST_UNLOCK_REQUEST, payload: { iPKUtilisateur } });
    }
    // yield put({ type: VIEW_DEACTIVATE_REQUEST, payload: {} });
  } else {
    yield put({ type: REQUEST_FILTER_FAILURE, payload: 'Les filtres sont vides.' });
  }
}

export function* orderRequests({ payload: { oFilters, oOrder, iPKUtilisateur } }) {
  if (!navigator.onLine) {
    alert('TODO: REQUEST_ORDER_REQUEST Offline');
  } else if (Object.keys(oFilters).length > 0) {
    yield put({ type: REQUEST_ORDER_SUCCESS, payload: oOrder });
    yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
    yield put({ type: REQUEST_UNLOCK_REQUEST, payload: { iPKUtilisateur } });
  } else {
    yield put({ type: REQUEST_ORDER_FAILURE, payload: 'Pas de parametres de classement.' });
  }
}

export function* lockRequest({ payload: { iPKDemande, iPKUtilisateur, oFilters, oOrder } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'lock' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_LOCK_REQUEST Offline');
  } else {
    try {
      const LOCK_REQUEST = gql`
        mutation {
          verrouilleDemande(iFKDemande: ${iPKDemande}, iFKUtilisateur: ${iPKUtilisateur}) {
            iPKVerrouillage
            iFKDemande
            iFKUtilisateur
            sUtilisateur
            dDate
            demande {
              iPKDemande
              iFKParentDemande
              iFKAntenne
              sAntenne
              iFKEtablissement
              iFKAnnuaire
              sNature
              sPrestation
              sDemandeur
              sMotif
              dDate
              iDelai
              iDelaiOrigine
              dLimite
              dDispoPatient
              dSouhaitRealisation
              dDateProgrammation
              bModifie
              bVisiteAssociation
              sCodeAnnuaire
              sCodeCivilite
              sNom
              sPrenom
              sAdresse
              sCodePostal
              sVille
              sEtablissement
              bEtablissement
              sTelFixe
              sTelMobile
              bInactif
              bVue
              bAttente
              bTraitee
              sCouleur
              dHospitalisation
              tCommentaire {
                iPKCommentaire
                iFKDemande
                iFKUtilisateur
                sUtilisateur
                sMessage
                dDate
              }
              tRendezVous {
                dRdv
                sTypeRdvLibelle
                sTypeRdvCode
                sCodeIntervenant
                sIntervenantComplet
              }
            }
        }
      }`;
      const response = yield call(mutateApollo, LOCK_REQUEST);
      const verrouillage = response.data.verrouilleDemande;

      if (verrouillage !== null) {
        if (verrouillage.iPKVerrouillage !== -1) {
          yield put({ type: REQUEST_LOCK_SUCCESS, payload: verrouillage });
          // yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
          yield put({ type: VIEW_LIST_REQUEST, payload: { iPKUtilisateur } });
        } else {
          yield put({ type: REQUEST_LOCK_FAILURE, payload: verrouillage });
        }
      } else {
        yield put({ type: REQUEST_LOCK_FAILURE, payload: 'Erreur du verrouillage de la demande' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur du verrouillage de la demande';
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_LOCK_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'lock' } });
    }
  }
}

export function* unlockRequest({ payload: { iPKUtilisateur } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'lock' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_UNLOCK_REQUEST Offline');
  } else {
    try {
      const UNLOCK_REQUEST = gql`
        mutation {
          deverrouilleDemande(iFKUtilisateur: ${iPKUtilisateur}) {
            iResultat
        }
      }`;
      const response = yield call(mutateApollo, UNLOCK_REQUEST);
      const resultat = response.data.deverrouilleDemande;

      if (resultat.iResultat === 1) {
        yield put({ type: REQUEST_UNLOCK_SUCCESS, payload: { iPKUtilisateur } });
      } else {
        yield put({
          type: REQUEST_UNLOCK_FAILURE,
          payload: 'Erreur du déverrouillage de la demande'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur du déverrouillage de la demande';
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_UNLOCK_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'lock' } });
    }
  }
}

export function* setProgrammationDate({ payload: { iPKUtilisateur, iPKDemande, dDate, sNature, sPrestation } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_PROGRAMMATION_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setProgrammationDate(iFKUtilisateur: ${iPKUtilisateur}, iFKDemande: ${iPKDemande}, dDate: "${dDate}", sNature: "${sNature}", sPrestation: "${sPrestation}") {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setProgrammationDate;

      if (request) {
        // yield put({ type: REQUEST_UNLOCK_REQUEST, payload: { iPKDemande } });
        yield put({ type: REQUEST_SET_PROGRAMMATION_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_SET_PROGRAMMATION_FAILURE,
          payload: 'Erreur de définition de la date de programmation de la demande'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de définition de la date de programmation de la demande';
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_PROGRAMMATION_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* resetProgrammationDate({ payload: { iPKUtilisateur, iPKDemande } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_RESET_PROGRAMMATION_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          resetProgrammationDate(iFKUtilisateur: ${iPKUtilisateur}, iFKDemande: ${iPKDemande}) {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.resetProgrammationDate;

      if (request) {
        // yield put({ type: REQUEST_UNLOCK_REQUEST, payload: { iPKDemande } });
        yield put({ type: REQUEST_RESET_PROGRAMMATION_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_RESET_PROGRAMMATION_FAILURE,
          payload: 'Erreur de remise à zéro de la date de programmation de la demande'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur de remise à zéro de la date de programmation de la demande';
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_RESET_PROGRAMMATION_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setCommentaire({
  payload: { iPKDemande, iPKUtilisateur, iFKAnnuaire, sCommentaire }
}) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_COMMENTAIRE_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setCommentaire(iFKDemande: ${iPKDemande}, iFKUtilisateur: ${iPKUtilisateur}, iFKAnnuaire: ${iFKAnnuaire}, sMessage: ${JSON.stringify(
        sCommentaire
      )}) {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setCommentaire;

      if (request) {
        yield put({ type: REQUEST_SET_COMMENTAIRE_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_SET_COMMENTAIRE_FAILURE,
          payload: `Erreur de définition du commentaire de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition du commentaire de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_COMMENTAIRE_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setAntenne({ payload: { iPKDemande, iFKAntenne, iPKUtilisateur } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_ANTENNE_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setAntenne(iFKDemande: ${iPKDemande}, iFKAntenne: ${iFKAntenne}) {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setAntenne;

      if (request) {
        yield put({ type: REQUEST_SET_ANTENNE_SUCCESS, payload: request });
        yield put({ type: VIEW_LIST_REQUEST, payload: { iPKUtilisateur } });
      } else {
        yield put({
          type: REQUEST_SET_ANTENNE_FAILURE,
          payload: `Erreur de définition de l'antenne de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition de l'antenne de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_ANTENNE_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setCouleur({ payload: { iPKDemande, sCouleur } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_COULEUR_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setCouleur(iFKDemande: ${iPKDemande}, sCouleur: "${sCouleur}") {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setCouleur;

      if (request) {
        yield put({ type: REQUEST_SET_COULEUR_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_SET_COULEUR_FAILURE,
          payload: `Erreur de définition de la couleur de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition de la couleur de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_COULEUR_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setDelai({
  payload: {
    iPKUtilisateur,
    iPKDemande,
    dDispoPatient,
    dLimite,
    sCommentaire,
    iFKAnnuaire,
    sUtilisateur,
    oFilters,
    oOrder
  }
}) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_DELAI_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setDelai(iFKUtilisateur: ${iPKUtilisateur}, iFKDemande: ${iPKDemande}, dDispoPatient: "${dDispoPatient}", dLimite: "${dLimite}", sCommentaire: "${sCommentaire}", iFKAnnuaire: ${iFKAnnuaire}, sUtilisateur: "${sUtilisateur}") {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setDelai;

      if (request) {
        yield put({ type: REQUEST_SET_DELAI_SUCCESS, payload: request });
        yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
      } else {
        yield put({
          type: REQUEST_SET_DELAI_FAILURE,
          payload: `Erreur de définition du délai de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition du délai de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_DELAI_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setMotif({ payload: { iPKDemande, sMotif } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_MOTIF_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`mutation {
        setMotif(iPKDemande: ${iPKDemande}, sMotif: "${sMotif}") {
          iPKDemande
          iFKParentDemande
          iFKAntenne
          sAntenne
          iFKEtablissement
          iFKAnnuaire
          iDelai
          iDelaiOrigine
          bModifie
          dLimite
          dDispoPatient
          dSouhaitRealisation
          sNature
          sPrestation
          sDemandeur
          sMotif
          dDate
          dDateProgrammation
          bVisiteAssociation
          sCodeAnnuaire
          sCodeCivilite
          sNom
          sPrenom
          sAdresse
          sCodePostal
          sVille
          sTelFixe
          sTelMobile
          sEtablissement
          bEtablissement
          bInactif
          bVue
          bAttente
          bTraitee
          sCouleur
          dHospitalisation
          tCommentaire {
            iPKCommentaire
            iFKDemande
            iFKUtilisateur
            sUtilisateur
            sMessage
            dDate
          }
          tRendezVous {
            dRdv
            sTypeRdvLibelle
            sTypeRdvCode
            sCodeIntervenant
            sIntervenantComplet
          }
        }
      }`;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setMotif;

      if (request) {
        yield put({ type: REQUEST_SET_MOTIF_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_SET_MOTIF_FAILURE,
          payload: `Erreur de définition du motif de la demande`
        });
      }
    } catch (error) {
      const statusMessages = {
        401: 'Erreur de définition du motif de la demande',
        404: 'Page not found',
        500: 'Internal Server Error'
      };
      const payload = statusMessages[error.status] ?? error.message;
      yield put({ type: REQUEST_SET_MOTIF_FAILURE, payload });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setNature({ payload: { iPKDemande, sNature } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_NATURE_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setNature(iPKDemande: ${iPKDemande}, sNature: "${sNature}") {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setNature;

      if (request) {
        yield put({ type: REQUEST_SET_NATURE_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_SET_NATURE_FAILURE,
          payload: `Erreur de définition de la nature de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition de la nature de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_NATURE_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setViewed({ payload: { iPKDemande, bVue, oFilters, oOrder } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_VIEWED_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setViewed(iFKDemande: ${iPKDemande}, bVue: ${bVue}, sFilters: "${JSON.stringify(
        JSON.stringify(oFilters)
      ).slice(1, -1)}", sOrder: "${JSON.stringify(JSON.stringify(oOrder)).slice(1, -1)}") {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setViewed;

      if (request) {
        yield put({ type: REQUEST_SET_VIEWED_SUCCESS, payload: { request, bVue } });
        // yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
      } else {
        yield put({
          type: REQUEST_SET_VIEWED_FAILURE,
          payload: `Erreur de définition du statu vue "oui/non" de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition du statu vue "oui/non" de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_VIEWED_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setDealt({ payload: { iPKDemande, bTraitee, oFilters, oOrder } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_DEALT_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setViewed(iFKDemande: ${iPKDemande}, bTraitee: ${bTraitee}, sFilters: "${JSON.stringify(
        JSON.stringify(oFilters)
      ).slice(1, -1)}", sOrder: "${JSON.stringify(JSON.stringify(oOrder)).slice(1, -1)}") {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setViewed;

      if (request) {
        yield put({ type: REQUEST_SET_DEALT_SUCCESS, payload: { request, bTraitee } });
        // yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
      } else {
        yield put({
          type: REQUEST_SET_DEALT_FAILURE,
          payload: `Erreur de définition du statu vue "oui/non" de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition du statu vue "oui/non" de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_DEALT_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* loadDelais({ payload: { iPKDemande } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_LOAD_DELAIS_REQUEST Offline');
  } else {
    try {
      const LOAD_DELAIS = gql`{
        listeDelais(iFKDemande: ${iPKDemande}) {
          iPKDelai
          iFKDemande
          iFKUtilisateur
          sUtilisateur
          dDispoPatient
          dLimite
          bModifie
          sCommentaire
          dDate
        }
      }`;
      const response = yield call(fetchApollo, LOAD_DELAIS);
      const delais = response.data.listeDelais;

      if (delais) {
        yield put({
          type: REQUEST_LOAD_DELAIS_SUCCESS,
          payload: {
            iPKDemande,
            historique: delais
          }
        });
      } else {
        yield put({
          type: REQUEST_LOAD_DELAIS_FAILURE,
          payload: `Erreur de récupération des délais la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de récupération des délais de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_LOAD_DELAIS_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* loadCommentaires({ payload: { iPKDemande } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_LOAD_COMMENTAIRES_REQUEST Offline');
  } else {
    try {
      const LOAD_COMMENTAIRES = gql`{
        listeCommentaires(iFKDemande: ${iPKDemande}) {
          iPKCommentaire
          iFKDemande
          iFKUtilisateur
          sUtilisateur
          sMessage
          dDate
        }
      }`;
      const response = yield call(fetchApollo, LOAD_COMMENTAIRES);
      const commentaires = response.data.listeCommentaires;

      if (commentaires) {
        yield put({
          type: REQUEST_LOAD_COMMENTAIRES_SUCCESS,
          payload: {
            iPKDemande,
            historique: commentaires
          }
        });
      } else {
        yield put({
          type: REQUEST_LOAD_COMMENTAIRES_FAILURE,
          payload: `Erreur de récupération des commentaires la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de récupération des commentaires de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_LOAD_COMMENTAIRES_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* deleteRequest({ payload: { iPKDemande, sCommentaire, iPKUtilisateur } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_DELETE_REQUEST Offline');
  } else {
    try {
      const DELETE_REQUEST = gql`mutation {
        supprimeDemande(iFKDemande: ${iPKDemande}, iFKUtilisateur: ${iPKUtilisateur}, sCommentaire: "${sCommentaire}") {
          iResultat
      }
    }`;
      const response = yield call(mutateApollo, DELETE_REQUEST);
      const resultat = response.data.supprimeDemande;

      if (resultat.iResultat === 1) {
        // yield put({ type: REQUEST_UNLOCK_REQUEST, payload: { iPKUtilisateur } });
        // yield put({ type: REQUEST_LOAD_REQUEST, payload: { oFilters, oOrder } });
        yield put({ type: REQUEST_DELETE_SUCCESS, payload: { iPKDemande } });
      } else {
        yield put({
          type: REQUEST_DELETE_FAILURE,
          payload: 'Erreur de suppression de la demande'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de suppression de la demande`;
          break;
        default:
          break;
      }
      yield put({ type: REQUEST_DELETE_FAILURE, payload: message });
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* restoreRequest({ payload: { iPKUtilisateur, iPKDemande } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_RESTORE_REQUEST Offline');
  } else {
    try {
      const RESTORE_REQUEST = gql`mutation {
        restaureDemande(iFKUtilisateur: ${iPKUtilisateur}, iFKDemande: ${iPKDemande}) {
          iResultat
      }
    }`;
      const response = yield call(mutateApollo, RESTORE_REQUEST);
      const resultat = response.data.restaureDemande;

      if (resultat.iResultat === 1) {
        yield put({ type: REQUEST_RESTORE_SUCCESS, payload: { iPKDemande } });
      } else {
        yield put({
          type: REQUEST_RESTORE_FAILURE,
          payload: 'Erreur de restauration de la demande'
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de restauration de la demande`;
          break;
        default:
          break;
      }
      yield put({ type: REQUEST_RESTORE_FAILURE, payload: message });
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}

export function* setVisite({ payload: { iPKDemande, bVisiteAssociation } }) {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'request' } });

  if (!navigator.onLine) {
    alert('TODO: REQUEST_SET_VISITE_REQUEST Offline');
  } else {
    try {
      const UPDATE_REQUEST = gql`
        mutation {
          setVisiteAssociation(iFKDemande: ${iPKDemande}, bVisiteAssociation: ${bVisiteAssociation}) {
            iPKDemande
            iFKParentDemande
            iFKAntenne
            sAntenne
            iFKEtablissement
            iFKAnnuaire
            iDelai
            iDelaiOrigine
            bModifie
            dLimite
            dDispoPatient
            dSouhaitRealisation
            sNature
            sPrestation
            sDemandeur
            sMotif
            dDate
            dDateProgrammation
            bVisiteAssociation
            sCodeAnnuaire
            sCodeCivilite
            sNom
            sPrenom
            sAdresse
            sCodePostal
            sVille
            sTelFixe
            sTelMobile
            sEtablissement
            bEtablissement
            bInactif
            bVue
            bAttente
            bTraitee
            sCouleur
            dHospitalisation
            tCommentaire {
              iPKCommentaire
              iFKDemande
              iFKUtilisateur
              sUtilisateur
              sMessage
              dDate
            }
            tRendezVous {
              dRdv
              sTypeRdvLibelle
              sTypeRdvCode
              sCodeIntervenant
              sIntervenantComplet
            }
          }
       }
      `;
      const response = yield call(mutateApollo, UPDATE_REQUEST);
      const request = response.data.setVisiteAssociation;

      if (request) {
        yield put({ type: REQUEST_SET_VISITE_SUCCESS, payload: request });
      } else {
        yield put({
          type: REQUEST_SET_VISITE_FAILURE,
          payload: `Erreur de définition du type de visite de la demande`
        });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = `Erreur de définition du type de visite de la demande`;
          break;
        default:
          break;
      }

      yield put({ type: REQUEST_SET_VISITE_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'request' } });
    }
  }
}
