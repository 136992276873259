import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const StyledBtnUserSignOut = styled.button`
  padding: 0 20px;
  width: 100%;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }

  &:disabled {
    cursor: default;
  }
`;

const BtnUserSignOut = ({ className, onClick }) => {
  return (
    <StyledBtnUserSignOut className={className} onClick={onClick}>
      <Icon name="power off" /> Déconnexion
    </StyledBtnUserSignOut>
  );
};

BtnUserSignOut.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

BtnUserSignOut.defaultProps = {
  className: ''
};

export default BtnUserSignOut;
