import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
  padding: 0 20px;
  height: 40px;
  color: ${props => props.theme.colors.textBlack};
  background: ${props => props.theme.colors.grey};
  border: 0;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.greyADOM};
  }
`;

const Cancel = ({ className, onClick }) => {
  return (
    <Btn className={className} onClick={onClick}>
      Annuler
    </Btn>
  );
};

Cancel.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

Cancel.defaultProps = {
  className: ''
};

export default Cancel;
