import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form, Select } from 'semantic-ui-react';

import BtnSaveCouleur from '../../../../../Btn/SaveCouleur';
import BtnCancelSaveCouleur from '../../../../../Btn/CancelSaveCouleur';
import BtnDisabled from '../../../../../Btn/Disabled';

import InfoEdit from './Info/Edit';
import Label from './Info/Label';
import Detail from './Info/Detail';
import Actions from './Info/Actions';
import Value from './Info/Value';

const Info = styled.div`
  display: flex;
  margin: 0 0 10px;
  width: 100%;
  padding: 0 0 0 15px;
`;

const FormLabel = styled.label`
  color: ${props => (props.error ? `${props.theme.colors.purple} !important` : 'inherit')};
`;

const Mandatory = styled.sup`
  position: relative;
  top: 2px;
  font-size: 16px;
  color: ${props => `${props.theme.colors.purple} !important`};
`;

class Couleur extends Component {
  constructor(props) {
    super(props);

    const { sCouleur } = this.props;

    this.tCouleurOptions = [];

    [
      {
        code: 'rgba(255, 255, 255, 0)',
        text: 'Aucune couleur'
      },
      {
        code: 'rgb(57, 178, 198)',
        text: 'Bleu'
      },
      {
        code: 'rgb(248, 179, 52)',
        text: 'Orange'
      },
      {
        code: 'rgb(62, 167, 28)',
        text: 'Vert'
      },
      {
        code: 'rgb(88, 88, 88)',
        text: 'Gris'
      }
    ].forEach((couleur, index) => {
      this.tCouleurOptions.push({
        key: index,
        text: couleur.text,
        value: couleur.code,
        image: {
          src: `data:image/svg+xml;charset=utf-8;base64,${Buffer.from(
            `<svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="24" height="24" fill="${couleur.code}"/></svg>`
          ).toString('base64')}`
        }
      });
    });

    this.state = {
      sCouleur,
      sCouleurInitial: sCouleur,
      edited: false
    };
  }

  componentDidUpdate(prevProps) {
    const { sCouleur } = this.props;

    if (prevProps.sCouleur !== sCouleur) {
      this.setState({ sCouleur, sCouleurInitial: sCouleur });
    }
  }

  onCouleurEditBtnClick = evt => {
    evt.preventDefault();
    this.setState({ edited: true });
  };

  onCouleurFieldChange = (evt, data) => {
    evt.preventDefault();

    const { value } = data;

    const { sCouleurInitial } = this.state;

    this.setState({ sCouleur: value, edited: sCouleurInitial !== value });
  };

  onBtnCancelSaveCouleurClick = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ sCouleur: prevState.sCouleurInitial, edited: false }));
  };

  onBtnSaveCouleurClick = () => {
    this.setState(prevState => ({ edited: false, sCouleur: prevState.sCouleurInitial }));
  };

  render() {
    const { edited, sCouleur, sCouleurInitial } = this.state;
    const { iPKDemande, label } = this.props;

    return edited ? (
      <InfoEdit>
        <Form onSubmit={this.onCouleurFieldChange}>
          <Form.Field
            id="sCouleur"
            label={
              <FormLabel htmlFor="sCouleur">
                Nouvelle couleur :<Mandatory>*</Mandatory>
              </FormLabel>
            }
            control={Select}
            options={this.tCouleurOptions}
            onChange={this.onCouleurFieldChange}
            value={sCouleur}
            placeholder="Aucune couleur"
          />
        </Form>

        <Actions>
          <BtnCancelSaveCouleur onClick={this.onBtnCancelSaveCouleurClick} />
          {sCouleur !== sCouleurInitial ? (
            <BtnSaveCouleur
              onClick={this.onBtnSaveCouleurClick}
              iPKDemande={iPKDemande}
              sCouleur={sCouleur}
            />
          ) : (
            <BtnDisabled label="Modifier" />
          )}
        </Actions>
      </InfoEdit>
    ) : (
      <Info>
        <Label wide>{label}&nbsp;:</Label>
        <Detail wide onClick={this.onCouleurEditBtnClick}>
          <Value
            color
            value={this.tCouleurOptions.filter(opt => opt.value === sCouleur)[0].value}
          />
        </Detail>
      </Info>
    );
  }
}

Couleur.propTypes = {
  iPKDemande: PropTypes.number.isRequired,
  sCouleur: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default Couleur;
