import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { addRappel } from '../../../../actions/rappel';

const StyledBtnSaveRappel = styled.button`
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => (props.disabled ? props.theme.colors.grey : props.theme.colors.blue)};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
    background: ${props =>
      props.disabled ? props.theme.colors.grey : props.theme.colors.blueDark};
  }
`;

class BtnSaveRappel extends Component {
  onClick = evt => {
    const { onClick, dispatch, iPKUtilisateur, iFKDemande, dDate, sCommentaire } = this.props;
    dispatch(addRappel(iPKUtilisateur, iFKDemande, dDate, sCommentaire));

    onClick(evt);
  };

  render() {
    const { disabled } = this.props;
    return (
      <StyledBtnSaveRappel disabled={disabled} onClick={this.onClick}>
        Ajouter
      </StyledBtnSaveRappel>
    );
  }
}

BtnSaveRappel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  iPKUtilisateur: PropTypes.number.isRequired,
  iFKDemande: PropTypes.number.isRequired,
  dDate: PropTypes.string.isRequired,
  sCommentaire: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  iPKUtilisateur: state.user.data.iPKUtilisateur
});

export default connect(mapStateToProps)(BtnSaveRappel);
