import { call, put } from 'redux-saga/effects';
import { gql } from 'apollo-boost';

import {
  CONFIG_LOAD_FAILURE,
  CONFIG_LOAD_SUCCESS,
  LOADING_ACTIVATE_SUCCESS,
  LOADING_DEACTIVATE_SUCCESS
} from '../constants/ActionTypes';
import { fetchApollo } from '../apolloClient';

export const tmp = () => {};

export function* loadConfig() {
  yield put({ type: LOADING_ACTIVATE_SUCCESS, payload: { target: 'config' } });

  if (!navigator.onLine) {
    alert('TODO: CONFIG_LOAD_REQUEST Offline');
  } else {
    try {
      const LOAD_CONFIG = gql`
        {
          loadConfig {
            oParametres {
              refreshDelay
              serverURL
              unlockDelay
            }
            tAntenne {
              iFKAnnuaire
              sNom
            }
            tEtablissement {
              iPKEtablissement
              iFKAntenne
              sNom
            }
            tNature {
              sNature
            }
            tPrecision {
              sCodePrecisionEvt
              sPKTypeEvt
            }
            tPrestation {
              sPrestation
            }
          }
        }
      `;
      const response = yield call(fetchApollo, LOAD_CONFIG);
      const config = response.data.loadConfig;
      if (config !== null) {
        yield put({ type: CONFIG_LOAD_SUCCESS, payload: config });
      } else {
        yield put({ type: CONFIG_LOAD_FAILURE, payload: 'Erreur lors du chargement de la configuration' });
      }
    } catch (error) {
      let message = { error };

      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Page not found';
          break;
        case 401:
          message = 'Erreur lors du chargement de la configuration';
          break;
        default:
          break;
      }

      yield put({ type: CONFIG_LOAD_FAILURE, payload: message });
    } finally {
      yield put({ type: LOADING_DEACTIVATE_SUCCESS, payload: { target: 'config' } });
    }
  }
}
