import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Statistic } from 'semantic-ui-react';

const StyledRappelStats = styled.div`
  float: right;
`;

const RappelStats = ({ tRappel }) => {
  return (
    <StyledRappelStats>
      <Statistic label="Rappels" value={tRappel.length} />
    </StyledRappelStats>
  );
};

RappelStats.propTypes = {
  tRappel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

const mapStateToProps = state => ({
  tRappel: state.rappel.list
});

export default connect(mapStateToProps)(RappelStats);
