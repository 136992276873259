import styled from 'styled-components';

const Label = styled.div`
  flex: 1;
  font-family: 'Futura';
  max-width: 140px;
  min-height: ${props => (props.wide ? '36px' : '24px')};
  line-height: ${props => (props.wide ? '36px' : '24px')};
`;

export default Label;
