import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal, Header, Form, Input, TextArea } from 'semantic-ui-react';
import { DatePickerInput } from 'rc-datepicker';
import { DateTime } from 'luxon';
import '../../../../assets/css/datepicker.css';
import 'moment/locale/fr';

import BtnCancel from '../Btn/Cancel';
import BtnSave from '../Btn/SaveRappel';

const FormCommands = styled.div`
  & > button {
    margin-right: 15px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`;

class AjoutRappel extends Component {
  state = {
    dDate: null,
    sHour: '',
    sCommentaire: '',
    edited: false,
    errors: {}
  };

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (open !== prevProps.open && !open) {
      this.setState({
        dDate: null,
        sHour: '',
        sCommentaire: '',
        edited: false,
        errors: {}
      });
    }
  }

  onDateChange = date => {
    const { sHour } = this.state;
    let { errors } = this.state;
    const newDate = DateTime.fromJSDate(date);

    if (newDate.isValid) {
      delete errors.dDate;
      this.setState({ dDate: newDate, errors, edited: this.isValidRappel(newDate, sHour, errors) });
    } else {
      errors = { ...errors, dDate: 'Date invalide' };
      this.setState({ errors, edited: false });
    }
  };

  onHourChange = (evt, data) => {
    let { value } = data;
    const { dDate } = this.state;
    let { errors } = this.state;

    if (value.match(/^([\d:]{0,5})$/g)) {
      delete errors.sHour;

      if (data.value.length >= 2 && data.value[1] === ':') {
        value = `0${value}`;
      }
      if (data.value.length === 4 && data.value.indexOf(':') === -1) {
        value = `${data.value[0]}${data.value[1]}:${data.value[2]}${data.value[3]}`;
      }

      if (value.length === 2) {
        if (parseInt(value, 10) > 23) {
          errors = { ...errors, sHour: 'Valeur invalide' };
        }
      }

      if (value.length === 4 && value[2] === ':') {
        if (parseInt(value[3], 10) > 5) {
          errors = { ...errors, sHour: 'Valeur invalide' };
        }
      }

      if (value.length === 5 && value[2] === ':') {
        const chunks = value.split(':');
        if (parseInt(chunks[0], 10) > 23 || parseInt(chunks[1], 10) > 59) {
          errors = { ...errors, sHour: 'Valeur invalide' };
        }
      }

      this.setState({ sHour: value, errors, edited: this.isValidRappel(dDate, value, errors) });
    } else {
      this.setState({ edited: false });
    }
  };

  onHourFocus = evt => {
    evt.target.select();
  };

  onHourBlur = evt => {
    const el = evt.target;
    let { sHour, errors } = this.state;
    const { dDate } = this.state;

    delete errors.sHour;

    if (el.value.length === 1) {
      sHour = `0${el.value}:00`;
    } else if (el.value.length === 2) {
      sHour = `${el.value}:00`;
    } else if (el.value.length === 3 && el.value[2] === ':') {
      sHour = `${el.value}00`;
    } else if (el.value.length === 3 && el.value.indexOf(':') === -1) {
      sHour = `0${el.value[0]}:${el.value[1]}${el.value[2]}`;
    } else if (el.value.length === 4 && el.value[2] === ':') {
      sHour = `${el.value}0`;
    } else if (el.value.length === 4 && el.value.indexOf(':') === -1) {
      sHour = `${el.value[0]}${el.value[1]}:${el.value[2]}${el.value[3]}`;
    } else if (el.value.length < 5 || el.value[2] !== ':') {
      errors = { ...errors, sHour: 'Format incorrect' };
    }

    if (sHour.length === 5 && sHour[2] === ':') {
      const chunks = sHour.split(':');
      if (parseInt(chunks[0], 10) > 23 || parseInt(chunks[1], 10) > 59) {
        errors = { ...errors, sHour: 'Valeur invalide' };
      }
    }

    this.setState({ sHour, errors, edited: this.isValidRappel(dDate, sHour, errors) });
  };

  onCommentaireChange = (evt, data) => {
    const { value } = data;

    this.setState({ sCommentaire: value });
  };

  isValidRappel = (dDate, sHour, errors) => {
    return (
      dDate &&
      dDate.isValid &&
      sHour.length === 5 &&
      sHour[2] === ':' &&
      Object.keys(errors).length === 0
    );
  };

  render() {
    const { open, onBtnToggleAddRappelClick, request } = this.props;
    const { dDate, sHour, sCommentaire, edited, errors } = this.state;
    const tHour = sHour.split(':');

    return (
      <Modal open={open} onClose={onBtnToggleAddRappelClick} dimmer="inverted" size="tiny">
        <Header icon="bell outline" content="Ajouter un rappel" />
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={6}>
                <label htmlFor="dDate">Date de rappel</label>

                <DatePickerInput
                  onChange={this.onDateChange}
                  placeholder="JJ/MM/AAAA"
                  locale="fr"
                  id="dDate"
                  format="DD/MM/YYYY"
                  displayFormat="DD/MM/YYYY"
                  minDate={DateTime.local().toISODate()}
                  value={dDate ? dDate.toISODate() : null}
                />
              </Form.Field>
              <Form.Field
                width={3}
                label="Heure"
                control={Input}
                value={sHour}
                onChange={this.onHourChange}
                onBlur={this.onHourBlur}
                onFocus={this.onHourFocus}
                placeholder="HH:MM"
                error={!!errors.sHour}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                width={16}
                label="Commentaire"
                control={TextArea}
                onChange={this.onCommentaireChange}
                value={sCommentaire}
                placeholder="Saisissez un commentaire"
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <FormCommands>
            <BtnCancel onClick={onBtnToggleAddRappelClick} />
            <BtnSave
              disabled={!edited}
              iFKDemande={request ? request.iPKDemande : -1}
              dDate={
                this.isValidRappel(dDate, sHour, [])
                  ? dDate
                      .set({
                        hour: parseInt(tHour[0], 10),
                        minute: parseInt(tHour[1], 10),
                        second: 0,
                        millisecond: 0
                      })
                      .toISO()
                  : null
              }
              sCommentaire={sCommentaire}
              onClick={onBtnToggleAddRappelClick}
            />
          </FormCommands>
        </Modal.Actions>
      </Modal>
    );
  }
}

AjoutRappel.propTypes = {
  open: PropTypes.bool.isRequired,
  onBtnToggleAddRappelClick: PropTypes.func.isRequired,
  request: PropTypes.objectOf(PropTypes.any)
};

AjoutRappel.defaultProps = {
  request: null
};

export default AjoutRappel;
