import React from 'react';

import { bool, func } from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Button = styled.button`
  position: absolute;
  top: 20px;
  right: 220px;
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  transition: background ease-in-out 0.25s;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }
`;

export default function BtnToggleViewed({ onClick, viewed }) {
  return <Button onClick={onClick}>
    <Icon name={viewed ? 'eye' : 'eye slash'} />
  </Button>;
}

BtnToggleViewed.propTypes = {
  onClick: func.isRequired,
  viewed: bool.isRequired
};
