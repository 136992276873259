import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuItem = styled.li`
  list-style: none;
`;

export default function Item({ children }) {
  return <MenuItem>{children}</MenuItem>;
}

Item.propTypes = {
  children: PropTypes.node.isRequired
};
