import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const DelaiEditBtn = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 5px;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  cursor: pointer;
  color: ${props => props.theme.colors.blue};
`;

const StyledValue = styled.div`
  position: relative;
  padding: 6px 5px 0;
  min-height: 36px;
  line-height: 24px;
  border: 1px solid ${props => props.theme.colors.inputBorderLight};
  border-radius: 4px;

  &:hover {
    border: 1px solid ${props => props.theme.colors.inputBorderDark};
  }
`;

const Value = ({ value, color, onClick, className }) => (
  <StyledValue className={className} onClick={onClick}>
    {color ? (
      <svg width="24" height="24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="24" height="24" fill={value} />
      </svg>
    ) : (
      value
    )}
    <DelaiEditBtn name="edit outline" />
  </StyledValue>
);

Value.propTypes = {
  className: PropTypes.string,
  color: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  onClick: PropTypes.func
};

Value.defaultProps = {
  className: '',
  color: false,
  onClick: () => {}
};

export default Value;
