import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledDate = styled.div``;

const Date = ({ children }) => <StyledDate>{children}</StyledDate>;

Date.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default Date;
