import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

const StyledInfoZoneAnchor = styled.div`
  position: relative;
  left: -5px;
  font-family: 'Futura';
  font-weight: bold;
`;

const InfoZoneAnchor = ({ anchor, label }) => {
  return (
    <StyledInfoZoneAnchor ref={anchor}>
      <Icon name="angle right" />
      {label}
    </StyledInfoZoneAnchor>
  );
};

InfoZoneAnchor.propTypes = {
  anchor: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired
};

export default InfoZoneAnchor;
