import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PaneToggleButton from '../../Btn/Toggle';

import Filters from './Filters';
import CreateView from './CreateView';

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  min-height: 100%;
  background: ${props => props.theme.colors.backgroundLight};
`;

const PaneTitle = styled.h2`
  margin: 0;
  padding: 20px;
  font-family: 'Futura';
  font-size: 16px;
`;

class FilterPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: null
    };
  }

  render() {
    const { opened, onBtnToggleFilterPaneClick } = this.props;
    const { filters } = this.state;

    return (
      <Wrapper>
        <PaneToggleButton opened={opened} onClick={onBtnToggleFilterPaneClick} />
        {opened && (
          <>
            <PaneTitle>Filtrez la liste des demandes</PaneTitle>
            <Filters />
            <CreateView filters={filters} />
          </>
        )}
      </Wrapper>
    );
  }
}

FilterPane.propTypes = {
  opened: PropTypes.bool.isRequired,
  onBtnToggleFilterPaneClick: PropTypes.func.isRequired
};

export default FilterPane;
