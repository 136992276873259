import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getBackgroundColor, getHoverBackgroundColor } from '../../../../../../utils';

const RequestTableRow = styled.tr`
  position: relative;
  color: ${props => (props.bVue ? props.theme.colors.textBlack : props.theme.colors.blueAGIR)};
  font-weight: ${props => (props.bVue ? 'normal' : 'bold')};
  background: ${props =>
    getBackgroundColor(
      props.index,
      props.selected,
      props.echeance,
      props.bAttente,
      props.theme.colors
    )};
  transition: all 0.25s ease-in-out;

  border-left: ${props => (props.sCouleur ? `5px solid ${props.sCouleur}` : 'none')};

  border-right: ${props =>
    props.bTraitee === 1 ? `5px solid ${props.theme.colors.blueAGIR}` : 'none'};

  &:first-child td {
    border-top: 0;
  }

  &:hover {
    color: ${props => props.theme.colors.textBlack} !important;
    background: ${props =>
      getHoverBackgroundColor(
        props.index,
        props.selected,
        props.echeance,
        props.bAttente,
        props.theme.colors
      )};
  }
  &:hover i {
    color: ${props => props.theme.colors.textBlack} !important;
  }
`;

const Row = React.forwardRef(
  ({ index, selected, echeance, bAttente, bVue, bTraitee, sCouleur, onClick, children }, ref) => (
    <RequestTableRow
      tag="tr"
      index={index}
      selected={selected}
      onClick={onClick}
      echeance={echeance}
      bAttente={bAttente}
      bVue={bVue}
      bTraitee={bTraitee}
      sCouleur={sCouleur}
      ref={ref}
    >
      {children}
    </RequestTableRow>
  )
);

Row.propTypes = {
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  echeance: PropTypes.number.isRequired,
  bAttente: PropTypes.bool.isRequired,
  bVue: PropTypes.number.isRequired,
  bTraitee: PropTypes.number.isRequired,
  sCouleur: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default Row;
