import styled from 'styled-components';

const Detail = styled.div`
  flex: 1;
  width: 100%;
  margin: 0;
  margin-inline-start: 0px;
  padding: 0 0 0 10px;
  min-height: ${props => (props.wide ? '36px' : '24px')};
  line-height: ${props => (props.wide ? '36px' : '24px')};
`;

export default Detail;
