import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import CodeAnnuaire from '../../../../RequestsTable/Col/CodeAnnuaire';
import Info from './Info';
import Label from './Info/Label';
import Detail from './Info/Detail';
import Couleur from './Couleur';
import Antenne from './Antenne';
import Nature from './Nature';
import Delai from './Delai';
import Programmation from './Programmation';
import VisiteAssociation from './VisiteAssociation';
import Phone from './Phone';
import Address from './Address';
import LongText from './LongText';
import Comment from './Comment';

const StyledInfoZoneContent = styled.div`
  margin: 0;
`;

const Actions = styled.div`
  height: 60px;
`;

const InfoZoneContent = ({ request, data }) => (
  <StyledInfoZoneContent>
    {data.type === 'phone' &&
      data.value.map(val => {
        return <Phone key={uuid()} label={val.label} number={val.value} />;
      })}

    {data.type === 'actions' && (
      <Actions>
        {data.actions.map(btn => {
          return <div key={uuid()}>{btn}</div>;
        })}
      </Actions>
    )}

    {data.type === 'widget' &&
      data.value.map(val => {
        if (val.type === 'comment') {
          return (
            <Comment
              key={uuid()}
              label={val.label}
              iPKDemande={request.iPKDemande}
              tCommentaire={request.tCommentaire}
              dDate={val.value.dDate}
              sAuteur={val.value.sAuteur}
            />
          );
        }

        if (val.type === 'antenne') {
          return (
            <Antenne
              key={uuid()}
              iPKDemande={request.iPKDemande}
              iFKAntenne={request.iFKAntenne}
              label={val.label}
            />
          );
        }

        if (val.type === 'delai') {
          return (
            <Delai
              key={uuid()}
              iPKDemande={request.iPKDemande}
              label={val.label}
              request={request}
            />
          );
        }

        if (val.type === 'programmation') {
          return (
            <Programmation
              key={uuid()}
              iPKDemande={request.iPKDemande}
              dDateProgrammation={request.dDateProgrammation}
              dSouhaitRealisation={request.dSouhaitRealisation}
              dDispoPatient={request.dDispoPatient}
              dLimite={request.dLimite}
              iDelai={request.iDelai}
              label={val.label}
            />
          );
        }

        if (val.type === 'visite-association') {
          return (
            <VisiteAssociation
              key={uuid()}
              iPKDemande={request.iPKDemande}
              bVisiteAssociation={request.bVisiteAssociation}
              label={val.label}
            />
          );
        }
      })}

    {data.type === 'address' &&
      data.value.map(val => {
        if (val.type === 'address') {
          return (
            <Info key={uuid()}>
              <Label>{val.label}&nbsp;:</Label>
              <Detail>
                <Address address={val.value} />
              </Detail>
            </Info>
          );
        }

        return (
          <Info key={uuid()}>
            <Label>{val.label}&nbsp;:</Label>
            <Detail>{val.value}</Detail>
          </Info>
        );
      })}

    {data.type === 'text' &&
      data.value.map(val => {
        if (val.type === 'sCodeAnnuaire') {
          return (
            <Info key={uuid()} margin="0 0 10px">
              <Label>{val.label}&nbsp;:</Label>
              <Detail>
                {val.value.sCodeAnnuaire}
                <CodeAnnuaire iPKAnnuaire={val.value.iFKAnnuaire} />
              </Detail>
            </Info>
          );
        }

        if (val.type === 'longtext') {
          return <LongText key={uuid()} label={val.label} text={val.value} />;
        }

        if (val.type === 'couleur') {
          return (
            <Couleur
              key={uuid()}
              label={val.label}
              iPKDemande={val.value.iPKDemande}
              sCouleur={val.value.sCouleur}
            />
          );
        }

        if (val.type === 'nature') {
          return (
            <Nature
              key={uuid()}
              iPKDemande={request.iPKDemande}
              sNature={request.sNature}
              label={val.label}
            />
          );
        }

        return (
          <Info key={uuid()}>
            <Label>{val.label}&nbsp;:</Label>
            <Detail>{val.value ? val.value : '-'}</Detail>
          </Info>
        );
      })}
  </StyledInfoZoneContent>
);

InfoZoneContent.propTypes = {
  request: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array])
  ).isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired
};

export default InfoZoneContent;
