import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

const StyledEtablissement = styled.div`
  position: relative;
  padding-left: 20px;
`;

const EtablissementIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
`;

export default function Etablissement({ sEtablissement, sAdresse, sVille, sCodePostal }) {
  return (
    <StyledEtablissement>
      <EtablissementIcon name="hospital outline" />
      {sEtablissement}
      <br />
      {sAdresse.replace(sEtablissement, '')}
      <br />
      {`${sCodePostal} ${sVille}`}
    </StyledEtablissement>
  );
}

Etablissement.propTypes = {
  sEtablissement: PropTypes.string,
  sAdresse: PropTypes.string.isRequired,
  sVille: PropTypes.string.isRequired,
  sCodePostal: PropTypes.string.isRequired
};

Etablissement.defaultProps = {
  sEtablissement: ''
};
