import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PatientPrenom = styled.div`
  font-size: 12px;
`;

export default function Prenom({ sPrenom }) {
  return <PatientPrenom>{sPrenom}</PatientPrenom>;
}

Prenom.propTypes = {
  sPrenom: PropTypes.string.isRequired
};
