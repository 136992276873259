import { CONFIG_LOAD_SUCCESS, CONFIG_LOAD_FAILURE } from '../constants/ActionTypes';

const initialConfigState = {
  error: null,
  oParametres: {
    refreshDelay: 10,
    serverURL: '',
    unlockDelay: 15
  },
  tAntenne: [],
  tEtablissement: [],
  tNature: [],
  tPrecision: [],
  tPrestation: []
};

export default (state = initialConfigState, { type, payload }) => {
  switch (type) {
    case CONFIG_LOAD_SUCCESS: {
      return {
        ...state,
        error: null,
        oParametres: payload.oParametres,
        tAntenne: payload.tAntenne,
        tEtablissement: payload.tEtablissement,
        tNature: payload.tNature,
        tPrecision: payload.tPrecision,
        tPrestation: payload.tPrestation
      };
    }
    case CONFIG_LOAD_FAILURE: {
      return { error: payload };
    }
    default:
      return state;
  }
};
