import {
  RAPPEL_LIST_SUCCESS,
  RAPPEL_LIST_FAILURE,
  RAPPEL_LIST_USER_SUCCESS,
  RAPPEL_LIST_USER_FAILURE,
  RAPPEL_ADD_SUCCESS,
  RAPPEL_ADD_FAILURE,
  RAPPEL_DELETE_SUCCESS,
  RAPPEL_DELETE_FAILURE
} from '../constants/ActionTypes';

const initialRappelState = {
  list: [],
  all: [],
  error: null
};

export default (state = initialRappelState, { type, payload }) => {
  switch (type) {
    case RAPPEL_LIST_SUCCESS: {
      return {
        ...state,
        all: payload.tRappel,
        list: payload.tRappel.filter(r => r.iFKUtilisateur === payload.iPKUtilisateur),
        error: null
      };
    }
    case RAPPEL_LIST_FAILURE: {
      return { ...state, error: payload };
    }

    case RAPPEL_LIST_USER_SUCCESS: {
      return { ...state, list: payload, error: null };
    }
    case RAPPEL_LIST_USER_FAILURE: {
      return { ...state, list: [], error: payload };
    }

    case RAPPEL_ADD_SUCCESS: {
      return {
        ...state,
        all: [...state.list, payload],
        list: [...state.list, payload],
        error: null
      };
    }
    case RAPPEL_ADD_FAILURE: {
      return { ...state, error: payload };
    }

    case RAPPEL_DELETE_SUCCESS: {
      return {
        ...state,
        all: [...state.all.filter(rappel => rappel.iPKRappel !== payload.iPKRappel)],
        list: [...state.list.filter(rappel => rappel.iPKRappel !== payload.iPKRappel)],
        error: null
      };
    }
    case RAPPEL_DELETE_FAILURE: {
      return { ...state, error: payload };
    }

    default:
      return state;
  }
};
