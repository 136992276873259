import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { signUserOut } from '../../../../../actions/user';
import { unlockRequest } from '../../../../../actions/request';

import UserProfile from './Profile';
import BtnUserSignOut from '../../Btn/UserSignOut';

const StyledUser = styled.div`
  float: right;
`;

const UserName = styled.span`
  display: inline-block;
  margin: 0 0 0 10px;
  height: 40px;
  line-height: 40px;
  color: ${props => props.theme.colors.white};
`;

const UserAction = styled.div`
  float: right;
  display: block;
  margin: 0 20px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
`;

const BtnUserProfile = styled.button`
  padding: 0 10px;
  width: auto;
  height: 40px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blue};
  border: 0;
  cursor: pointer;
  text-align: center;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${props => props.theme.colors.blueDark};
  }

  & i {
    margin: 0 !important;
  }
`;

class User extends Component {
  state = {
    showProfile: false
  };

  signUserOut = () => {
    const { dispatch, user } = this.props;
    dispatch(unlockRequest(user.iPKUtilisateur));
    dispatch(signUserOut());
  };

  toggleUserProfile = evt => {
    evt.preventDefault();

    this.setState(prevState => ({ showProfile: !prevState.showProfile }));
  };

  render() {
    const { user } = this.props;
    const { showProfile } = this.state;

    return (
      <StyledUser>
        <UserAction>
          <BtnUserSignOut onClick={this.signUserOut} />
        </UserAction>
        <BtnUserProfile onClick={this.toggleUserProfile}>
          <Icon name="user" size="small" />
          <UserName>{`${user.sPrenom} ${user.sNom[0]}.`}</UserName>
        </BtnUserProfile>
        {showProfile && <UserProfile onClose={this.toggleUserProfile} />}
      </StyledUser>
    );
  }
}

User.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  user: state.user.data,
  signOutError: state.user.error
});

export default connect(mapStateToProps)(User);
